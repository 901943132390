/**
 * @prettier
 * @flow
 */

import { MessageContent } from 'semantic-ui-react';

/** COMPONENT BASED ON: https://react.semantic-ui.com/collections/message */
component Component(
	/** Detail as children. */
	children: React.Node,
	/** Test ID for testing */
	testID?: string = 'MessageContent'
) {
	return <MessageContent data-testid={testID}>{children}</MessageContent>;
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
