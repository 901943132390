/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Sidebar, Divider, Button, Header, Segment, Label } from 'liana-ui/components/';
import { isNumber } from 'lodash';
import { Size, Spacing, HAlign, Width } from 'liana-ui/types';

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/sidebar/ */
component Component(
	/** Content as children. */
	children: React.Node,
	/** Controls whether or not the sidebar is visible on the page. */
	visible: boolean = false,
	/** Clear label props */
	clearLabelProps?: React.PropsOf<Label>,
	/** Modified filters in panel. */
	modifiedFilters?: number,
	/** Boolean that indicates if the form was modified */
	isFormModified: boolean,
	/** A panel or modal can have different widths. */
	width?: Width,
	/** Test ID for testing. */
	testID: string = 'FilterSidebar',
	/** Called when all filters are cleared at once. */
	onClear?: () => void,
	/** Called before a sidebar begins to animate out. */
	onHide?: () => void,
	/** Called on apply filters and clear filters. */
	onSubmit?: () => void
) {
	const [isBottomScrolled, setIsBottomScrolled] = useState(false);
	const [filtersFooterState, setFiltersFooterState] = useState({ active: false, slideIn: false, slideOut: false });
	const handleHide = () => {
		if (typeof onHide === 'function') {
			onHide();
		}
	};
	const handleOnClearButtonClick = () => {
		// Slides out the filters footer when the clear button is clicked
		// This is done for better performance when the form is cleared
		if (!isBottomScrolled) {
			setFiltersFooterState({ active: false, slideIn: false, slideOut: true });
		}
		// Triggers the onClear callback
		if (typeof onClear === 'function') {
			onClear();
		}
	};

	let clearLabel = {
		...clearLabelProps,
		onClick: handleOnClearButtonClick
	};

	const handleClick = () => {
		if (typeof onSubmit === 'function') {
			onSubmit();
		}
		handleHide();
	};

	useEffect(() => {
		// Slides in or out the filters footer depeding on the form state
		if (!isBottomScrolled) {
			if (isFormModified && (!filtersFooterState.active || filtersFooterState.slideOut)) {
				// If the form is modified and the filters footer is not active or slided out, slide it in
				setFiltersFooterState({ active: true, slideIn: true, slideOut: false });
			} else if (!isFormModified && (filtersFooterState.active || filtersFooterState.slideIn)) {
				// If the form is not modified and the filters footer is active or slided in, slide it out
				setFiltersFooterState({ active: false, slideIn: false, slideOut: true });
			}
		}
	}, [isFormModified]);

	useEffect(() => {
		const sidebar = document.querySelector('.sidebar.overlay');
		if (sidebar) {
			// Updates the state if the sidebar is scrolled to/from the bottom
			const setIsScrolledToBottom = () => {
				if (Math.abs(sidebar.scrollHeight - sidebar.scrollTop - sidebar.clientHeight) < 1) {
					if (!isBottomScrolled) {
						setIsBottomScrolled(true);
					}
				} else {
					if (isBottomScrolled) {
						setIsBottomScrolled(false);
					}
				}
			};

			// Triggers initial isBottomScrolled state setup on useeffect mount
			setIsScrolledToBottom();

			const resizeListener = window.addEventListener('resize', setIsScrolledToBottom);
			const scrollListener = sidebar.addEventListener('scroll', setIsScrolledToBottom);

			return () => {
				window.removeEventListener('resize', resizeListener);
				// $FlowIssue - Flow assigns wrong removeEventListener type
				sidebar.removeEventListener('scroll', scrollListener);
			};
		}
	}, [visible, isBottomScrolled]);

	return (
		<Sidebar visible={visible} padded={false} width={width} onHide={handleHide} testID={testID}>
			<Segment basic removeMargins={Spacing.Bottom}>
				<Header
					text={<FormattedMessage id='component.filter-sidebar.filters' />}
					size={Size.Large}
					dividing
					labels={[clearLabel]}
					floatedContent={<Button icon='close' size={Size.Mini} fitted circular onClick={handleHide} />}
				/>
				{children}
			</Segment>
			<div
				className={classNames('sidebar-footer', {
					sticky: filtersFooterState.active,
					shadows: filtersFooterState.active && !isBottomScrolled,
					'show-animated': filtersFooterState.slideIn,
					'hide-animated': filtersFooterState.slideOut
				})}
			>
				<Segment
					basic
					textAlign={HAlign.Center}
					removeMargins={Spacing.All}
					removePaddings={[Spacing.Top, Spacing.Horizontal]}
				>
					<Divider removeMargins={Spacing.Top} />
					<Button
						text={
							<FormattedMessage
								id={`component.filter-sidebar.${isNumber(modifiedFilters) ? 'applyFilters' : 'close'}`}
								values={{ amount: modifiedFilters }}
							/>
						}
						fitted
						size={Size.Small}
						color='primary'
						off={!isFormModified}
						onClick={handleClick}
					/>
				</Segment>
			</div>
		</Sidebar>
	);
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
