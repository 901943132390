/**
 * @prettier
 * @flow
 */

import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb } from 'semantic-ui-react';
import { ContextMenu } from 'liana-ui/components/';
import { Size, Float } from 'liana-ui/types';

/** COMPONENT BASED ON: https://react.semantic-ui.com/collections/breadcrumb/ */
component Component(
	/** 
	 	Section text 
	 	PROPS[IntlComponent=/localization/] 
	*/
	text: React.Node = '',
	/** Render as an `a` tag instead of a `div` and triggers React router. */
	link?: string,
	/** A breadcrumb section can have a navigation menu. */
	contextMenu?: React.PropsOf<ContextMenu>,
	/** Test ID for testing */
	testID?: string
) {
	const navigate = useNavigate();

	// Translate tranlation keys
	let textElement = null;
	// $FlowFixMe - You shouldn't read props this way
	if (React.isValidElement(text) && text.props?.id) {
		textElement = <FormattedMessage id={text.props.id} values={text.props.values} />;
	} else if (typeof text === 'string' && text.length > 0) {
		textElement = text;
	}

	let handleClick = link
		? (event: SyntheticEvent<>) => {
				event.preventDefault();
				navigate(link);
			}
		: null;

	let section = (
		<>
			<Breadcrumb.Section
				href={link ? link : undefined}
				className='overflow-ellipsis'
				onClick={handleClick}
				data-testid={testID}
			>
				<span className='content'>{textElement}</span>
			</Breadcrumb.Section>
			{contextMenu?.options && (
				<Breadcrumb.Section className='menu-section'>
					<ContextMenu
						{...contextMenu}
						popup={false}
						fitted
						direction={Float.Left}
						size={Size.ExtraMini}
						circular={false}
						icon='fa-chevron-down'
					/>
				</Breadcrumb.Section>
			)}
		</>
	);
	return section;
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
