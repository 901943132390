/**
 * @prettier
 * @flow
 */

/** TODO: THIS COMPONENT IS INCOMPLETE AND IN PROTOTYPE USAGE! DO NOT USE IT IN PRODUCTION! */

/** COMPONENT BASED ON: https://react.semantic-ui.com/collections/breadcrumb/ */
component Component(
	/** Array of extra components to place in the trail bar */
	tools?: Array<React.Node> = [],
	/** Test ID for testing. */
	testID: string = 'TrailTools'
) {
	// Generate other custom elements alongside the trail
	const getTools = () => {
		// Place tool components on right side of trail bar in corrrect wrappers
		let elements = [];
		if (Array.isArray(tools) && tools.length > 0) {
			for (let i = 0; i < tools.length; i++) {
				if (React.isValidElement(tools[i])) {
					elements.push(<div className='actions-wrapper-item'>{tools[i]}</div>);
				}
			}
		}
		return elements;
	};

	return Array.isArray(tools) && tools.length > 0 ? (
		<div data-testid={testID} className='actions-wrapper'>
			{getTools()}
		</div>
	) : null;
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
