/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import ContentEditable from '../content-editable/ContentEditable';
import type { Tooltip } from 'liana-ui/definitions/component/Types';

import 'jquery-ui/ui/widget';
import 'jquery-ui/ui/widgets/mouse';
import 'jquery-ui/ui/widgets/slider';
import 'jquery-ui-touch-punch';

// prettier-ignore
type Props = {
	/** A number slider must have an input name */
	name: string,
	/** Initial value for input. */
	defaultValue?: number,
	/** A number slider can have additional classes. */
	classes?: string,
	/** A number slider can have maximum value. */
	max?: number,
	/** A number slider can have minimum value. */
	min?: number,
	/** A number slider can have steps between slider value points. */
	step?: number,
	/** A number slider can have different unit. VALUES['%', '€', '$', '...'] */
	unit?: string,
	/** A number slider can have minimum and maximum values visible. */
	isRangeVisible?: boolean,
	/** A number slider can be disabled. */
	isDisabled?: boolean,
	/* A number slider can have label. */
	label?: string,
	/** A number slider can have additional data attributes. */
	dataAttributes?: { [string]: string },
	/**
		A number input can have a tooltip.
		VALUES[tooltip={{'data-content': string, 'data-variation': string, 'data-delay': number}}]
	*/
	tooltip?: Tooltip,
	/** Function called when slider drag is started. */
	onStart?: (
		value,
		name
	) => {
		value: number,
		name: string
	},
	/** Function called when slider drag is stopped. */
	onStop?: (
		value,
		name
	) => {
		value: number,
		name: string
	},
	/** Function called when slider is dragged. */
	onSlide?: (
		value,
		name
	) => {
		value: number,
		name: string
	},
	/** Function called when input is changed. */
	onInput?: (
		value,
		name
	) => {
		value: number,
		name: string
	}
};

/** COMPONENT BASED ON: https://jqueryui.com/slider/ */
export default class NumberSlider extends React.PureComponent<Props> {
	_hiddenInput: { current: React.ElementRef<'input'> | null };
	_wrapper: { current: React.ElementRef<'div'> | null };
	_contentEditable: React.ElementRef<'div'> | null;
	$slider: JQuery;
	$inputWrapper: JQuery;
	$handle: JQuery;

	constructor(props: Props) {
		super(props);
		this._hiddenInput = React.createRef();
		this._wrapper = React.createRef();
		this.$slider = null;
	}

	static defaultProps = {
		min: 0,
		max: 100,
		step: 1,
		defaultValue: 50,
		isRangeVisible: true,
		isDisabled: false,
		label: '',
		onSlide: () => {}, // noop
		onStart: () => {}, // noop
		onStop: () => {}, // noop
		onInput: () => {} // noop
	};

	componentDidMount() {
		this.$inputWrapper = $(this._contentEditable);

		this.$slider.slider({
			value: this.props.defaultValue,
			animate: 'fast',
			min: this.props.min,
			max: this.props.max,
			step: this.props.step,
			start: (e, ui) => {
				this.$inputWrapper.addClass('focus');
				this.props.onStart(ui.value, this.props.name);
				$(this._contentEditable).trigger('change');
			},
			stop: (e, ui) => {
				this.$inputWrapper.removeClass('focus');
				this.props.onStop(ui.value, this.props.name);
			},
			slide: (e, ui) => {
				if (this._contentEditable) {
					this._contentEditable.innerHTML = ui.value; // Only when content has no children!
				}
				this.value = ui.value;
				this.props.onSlide(ui.value, this.props.name);
			}
		});
	}

	_validateValue = (val: mixed) => {
		let value = typeof val !== 'number' ? parseInt(val, 10) : val;
		if (isNaN(value)) {
			value = this.props.defaultValue;
		}
		if (value <= this.props.min) {
			value = this.props.min;
		} else if (value >= this.props.max) {
			value = this.props.max;
		}
		return value;
	};

	_setInputFocus = () => this.$inputWrapper.addClass('focus');

	_setInputBlur = () => {
		this.$inputWrapper.removeClass('focus');
		let val = this._validateValue(this.$inputWrapper.text());
		this.props.onStop(val, this.props.name);
		this._updateSlider(val);
		this.value = val;
		$(this._contentEditable).trigger('change');
	};

	_onInput = (event: SyntheticInputEvent<>) => {
		let val = this._validateValue(event.target.value);
		this.props.onInput(val, this.props.name);
		this._updateSlider(val);
		this.value = val;
	};

	_updateSlider = (val: number) => {
		this.$slider.slider('value', val);
	};
	_getRangeMin() {
		let elem;
		if (this.props.isRangeVisible) {
			elem = (
				<div className='legacy-number-slider-amount-min'>
					{this.props.min}
					{this.props.unit}
				</div>
			);
		}
		return elem;
	}
	_getRangeMax() {
		let elem;
		if (this.props.isRangeVisible) {
			elem = (
				<div className='legacy-number-slider-amount-max'>
					{this.props.max}
					{this.props.unit}
				</div>
			);
		}
		return elem;
	}
	_getLabel() {
		let elem;
		if (this.props.label) {
			elem = <span className='legacy-number-slider-label'>{this.props.label}:</span>;
		}
		return elem;
	}

	// Getter: hidden input value
	get value() {
		return this._hiddenInput.current ? parseInt(this._hiddenInput.current.value, 10) : 0;
	}

	// Setter: hidden input value
	set value(val: number) {
		// https://stackoverflow.com/questions/54768976/flowtype-cannot-assign-x-to-y-because-property-y-is-missing-in-null
		let str = String(this._validateValue(val));
		if (this._hiddenInput.current) {
			this._hiddenInput.current.value = str;
			$(this._hiddenInput.current).trigger('change');
		}
	}

	render() {
		let rangeMinElem = this._getRangeMin();
		let rangeMaxElem = this._getRangeMax();
		let label = this._getLabel();

		let classes = classNames(
			{
				'legacy-number-slider-wrapper': true,
				'popup-open': this.props.tooltip ? true : false,
				disabled: this.props.isDisabled,
				'hide-range-values': this.props.isRangeVisible ? false : true
			},
			this.props.classes
		);

		let numberSliderClasses = classNames({
			'legacy-number-slider-input': true,
			hidden: this.props.isRangeVisible ? false : true
		});

		return (
			<div
				ref={this._wrapper}
				className={classes}
				{...this.props.tooltip}
				data-testid={this.props.testID || NumberSlider.name}
			>
				<ContentEditable
					classes={numberSliderClasses}
					onBlur={this._setInputBlur}
					onFocus={this._setInputFocus}
					html={this.props.defaultValue}
					onInput={this._onInput}
					ref={(ref) => {
						this._contentEditable = ref ? ref.ref : null;
					}}
				/>
				<div className='legacy-number-slider-container'>
					{label}
					{rangeMinElem}
					<div className='legacy-number-slider' ref={(ref) => (this.$slider = $(ref))} />
					{rangeMaxElem}
				</div>

				<input
					ref={this._hiddenInput}
					className='legacy-number-slider-hidden-input'
					type='hidden'
					defaultValue={this.props.defaultValue}
					name={this.props.name}
				/>
			</div>
		);
	}
}
