/**
 * @prettier
 * @flow
 */

import { useState, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';
import { Checkbox, Button, Icon, Popup, Search, Segment, Text, IconGroup, Image } from 'liana-ui/components/';
import { Validate } from 'liana-ui/definitions';
import { Device, Size, Float, Spacing } from 'liana-ui/types';

type Option = {
	text?: React.Node,
	header?: React.Node,
	value?: string,
	icon?: string | React.PropsOf<Icon>,
	icons?: Array<string> | Array<React.PropsOf<Icon>>,
	divider?: boolean,
	checkbox?: React.PropsOf<Checkbox>,
	link?: string,
	image?: string | React.PropsOf<Image>,
	disabled?: boolean,
	onClick?: (event: SyntheticEvent<>, data: any) => void
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/dropdown/#types-pointing */
component Component(
	/**
		A context menu must have option.
		PROPS[React.Node=/language/localisation/, CheckboxProps=/components/forms/checkbox/, IconProps=/components/labels/icons/icon/]
	*/
	options?: Array<Option>,
	/**
		A context menu button can have different icon.
		PROPS[IconProps=/components/labels/icons/icon/]
	*/
	icon?: string | React.PropsOf<Icon> = 'fa-ellipsis',
	/**
		A context menu button can have different icon group.
		PROPS[IconGroupProps=/components/labels/icons/icon-group/]
	*/
	iconGroup?: Array<React.PropsOf<Icon>>,
	/**
		A button can have text.
		PROPS[React.Node=/localization/]
	*/
	text?: React.Node,
	/** A context menu can have primary color. */
	color?: 'primary',
	/**  A context menu can be aligned to the left or right of its container. */
	floated?: Float,
	/** A content menu can have no empty space around it. */
	fitted?: boolean = false,
	/** A context menu button can be pronounced by having no borders. */
	basic?: boolean,
	/** A content menu can show it is currently unnecessary to be interacted with. */
	off?: boolean = false,
	/** A context can show it is currently unable to be interacted with. */
	disabled?: boolean = false,
	/** A context can be circular. */
	circular?: boolean = true,
	/** A context menu button can have selected highlight color. */
	selected?: boolean,
	/** A content menu can stay open on menu item clicks to allow selecting multiple items. */
	keepOpen?: boolean = false,
	/** A context menu can be formatted so that its menu is pointing. */
	pointing?: 'top' | 'bottom' = 'top',
	/** A content menu can be forced to open to the left or to the right. */
	direction?: Float,
	/** A button can have different sizes. */
	size?: Size,
	/** Smallest device that component will be displayed with. */
	minDevice?: Device,
	/** Largest device that component will be displayed with. */
	maxDevice?: Device,
	/** Hide content on touch devices */
	hideTouch?: boolean = false,
	/**
		Popup text or, react-intl coomponent or object of properties for Popup component.
		PROPS[React.Node=/language/localisation/, PopupProps=/components/modals/popup/]
	*/
	popup?: React.Node | React.PropsOf<Popup> = <FormattedMessage id='component.context-menu.actions' />,
	/* TODO: Undocumented prop */
	search?: React.PropsOf<Search>,
	/** Test ID for testing */
	testID: string = 'ContextMenu',
	/** Function called on menu open. */
	onOpen?: (event: SyntheticEvent<>) => void,
	/** Function called on menu close. */
	onClose?: (event: SyntheticEvent<>) => void
) {
	// Variables and refs
	const intl = useIntl();
	let dropdownRef = useRef<any | null>(null);
	let [iDirection, setDirection] = useState<'left' | 'right' | void>();
	let [open, setOpen] = useState(false);

	const handleOpen = (event: SyntheticMouseEvent<>) => {
		// Open menu
		setOpen(true);

		// Fix non existent SUI-R dropdown direction position (https://github.com/Semantic-Org/Semantic-UI-React/issues/3771)
		if (window && window.innerWidth && event && event.clientX) {
			let windowWidth = window.innerWidth;
			let mousePosition = event.clientX;
			if (mousePosition > windowWidth / 2) {
				setDirection('left');
			}
		}

		// Trigger onClick callback funtion
		if (typeof onOpen === 'function') {
			onOpen(event);
		}
	};

	const handleClose = (event: SyntheticMouseEvent<>) => {
		// Wait for dropdown tp close
		setTimeout(() => setOpen(false), 100);

		// Trigger onClick callback funtion
		if (typeof onClose === 'function') {
			onClose(event);
		}
	};

	const getOptions = () => {
		let ret = [];
		if (options && options.length > 0) {
			options.forEach((option, i) => {
				if (option) {
					if (option.divider === true) {
						ret.push(<Dropdown.Divider key={`divider-${i}`} />);
					} else if (option.header) {
						ret.push(
							<Dropdown.Header
								content={
									React.isValidElement(option.header)
										? option.header
										: intl.formatMessage({ id: option.header })
								}
								key={`header-${i}`}
							/>
						);
					} else if (option.checkbox) {
						ret.push(
							<Dropdown.Item className='checkbox' key={`checkbox-${i}`}>
								<Checkbox {...option.checkbox} size={size || Size.Small} />
							</Dropdown.Item>
						);
					} else if (option.text) {
						// Get link type
						let linkType = Validate.linkType(option.link);
						ret.push(
							<Dropdown.Item
								key={`item-${i}`}
								as={linkType === 'internal' ? NavLink : linkType === 'external' ? 'a' : undefined}
								to={linkType === 'internal' ? option.link : undefined}
								href={linkType === 'external' ? option.link : undefined}
								target={linkType === 'external' ? '_blank' : undefined}
								rel={linkType === 'external' ? 'noopener noreferrer' : undefined}
								text={
									React.isValidElement(option.text)
										? option.text
										: intl.formatMessage({ id: option.text })
								}
								icon={
									Array.isArray(option.icons) ? (
										<IconGroup
											icons={
												typeof option.icons[0] === 'string' &&
												typeof option.icons[1] === 'string'
													? [{ name: option.icons[0] }, { name: option.icons[1] }]
													: option.icons
											}
										/>
									) : typeof option.icon === 'string' ? (
										<Icon name={option.icon} />
									) : option.icon ? (
										<Icon {...option.icon} />
									) : undefined
								}
								image={
									option.image ? (
										typeof option.image === 'string' ? (
											<Image src={option.image} />
										) : (
											<Image {...option.image} />
										)
									) : undefined
								}
								disabled={option.disabled}
								onClick={option.onClick}
							/>
						);
					}
				}
			});
		}
		return ret;
	};
	let trigger = (
		<Button
			className='context-menu'
			icon={!iconGroup ? (text ? 'fa-chevron-down' : icon) : undefined}
			iconGroup={iconGroup}
			iconPosition={text ? Float.Right : undefined}
			focused={open}
			text={text}
			circular={circular}
			color={color}
			basic={basic}
			disabled={disabled}
			fitted={fitted}
			floated={floated}
			off={off}
			selected={selected}
			size={size}
			minDevice={minDevice}
			maxDevice={maxDevice}
			hideTouch={hideTouch}
			popup={!open || off ? popup : undefined}
		/>
	);

	return (
		<Dropdown
			key='context-menu'
			ref={dropdownRef}
			trigger={trigger}
			pointing={pointing}
			icon={false}
			disabled={disabled}
			direction={direction || iDirection}
			size={size}
			open={open}
			testID={testID}
			onOpen={handleOpen}
			onClose={handleClose}
		>
			{!off && !disabled ? (
				<Dropdown.Menu
					onClick={
						keepOpen
							? (event) => {
									event.stopPropagation();
								}
							: undefined
					}
				>
					{search ? (
						<>
							<Segment basic compressed='very' removeMargins={Spacing.All}>
								<Search {...search} fluid size={Size.Small} clearable={false} />
							</Segment>
							<Dropdown.Divider className='remove-margins' />
							<div className='menu small scrolling search-results'>{getOptions()}</div>
							{options?.length === 0 ? (
								<Segment off size={Size.Small} basic compressed removeMargins={Spacing.All}>
									<Text color='grey'>
										<FormattedMessage id='component.search-input.noResultsFound' />
									</Text>
								</Segment>
							) : null}
						</>
					) : (
						getOptions()
					)}
				</Dropdown.Menu>
			) : null}
		</Dropdown>
	);
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
