/**
 * @prettier
 * @flow
 */
import { ListIcon } from 'semantic-ui-react';
import { Icon } from 'liana-ui/components/';
import { VAlign } from 'liana-ui/types';

component Component(
	...props: {
		...React.PropsOf<Icon>,
		verticalAlign: VAlign
	}
) {
	return <ListIcon {...props} />;
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
