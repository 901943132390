/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Label, Popup } from 'liana-ui/components/';
import InputNumber from 'rc-input-number';

/** COMPONENT BASED ON: https://input-number.vercel.app */
component Component(
	/** A number input must have name. */
	name: string,
	/** Initial value for number input. Use for uncontrolled components only. */
	defaultValue?: number,
	/** Current value for number input. Use for controlled components only. */
	value?: number,
	/** A number input can have a placeholder text. */
	placeholder?: string,
	/** A number input can have minimum value. */
	min?: number,
	/** A number input can have maximum value. */
	max?: number,
	/** A number input can have different legal number intervals. */
	step?: number = 1,
	/** A number input can display a measure of quantity (for ex. currency). */
	unit?: number,
	/** A number input can have precision of decimals. */
	precision?: number = 0,
	/** An number input can take on the size of its container. */
	fluid: boolean = false,
	/** A number input can be locked. */
	locked: boolean = false,
	/** A number input can be disabled. */
	disabled: boolean = false,
	/** A number input can be different size. */
	size?: 'small' | 'large',
	/**
		Popup text or, react-intl coomponent or object of properties for Popup component.
		PROPS[React.Node=/language/localisation/, PopupProps=/components/modals/popup/]
	*/
	popup?: React.Node | React.PropsOf<Popup>,
	/** Test ID for testing */
	testID: string = 'NumberInput',
	/** Function called when input number is changed. */
	onChange?: (data: { name: string, value: number }) => void
) {
	const intl = useIntl();
	const inputWrapperRef = useRef<HTMLDivElement | null>(null);
	let sample = intl.formatNumber(1.45); // Get language decimal separator since ECMA-402 doesn't have an API for this

	let [internalValue, setInternalValue] = useState(value || defaultValue);
	let currentValue = value === undefined ? internalValue : value;

	let handleChange = (val: number) => {
		setInternalValue(val);
		// Trigger Form onChange on value change
		if (inputWrapperRef.current) {
			let input = inputWrapperRef.current.querySelector('input');
			if (input) {
				input.dispatchEvent(new Event('input', { bubbles: true }));
				input.dispatchEvent(new Event('change', { bubbles: true }));
			}
		}
		if (typeof onChange === 'function') {
			onChange({
				name: name,
				value: val
			});
		}
	};

	let classes = classNames(
		'ui input number-input-wrapper',
		{
			fluid: fluid,
			locked: locked,
			disabled: disabled,
			'left labeled': unit
		},
		size
	);

	let input = (
		<div data-testid={testID} className={classes} ref={inputWrapperRef}>
			{unit ? <Label text={unit} /> : null}
			<InputNumber
				name={name}
				placeholder={placeholder}
				value={currentValue}
				min={min}
				max={max}
				step={step}
				precision={precision}
				decimalSeparator={sample.charAt(1)}
				disabled={disabled}
				readOnly={locked}
				tabIndex={locked ? -1 : undefined}
				onChange={handleChange}
			/>
		</div>
	);

	// $FlowIssue - React statics; Attach popup
	return Popup.attach(popup, input);
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
