/**
 * @prettier
 * @flow
 */

import { FormattedDate, FormattedTime } from 'react-intl';
import { Text } from 'liana-ui/components/';
import { Size } from 'liana-ui/types';

/** COMPONENT BASED ON: https://formatjs.io/docs/react-intl */
component Component(
	/** Date/time value to display. */
	value: string,
	/** Time zone name. Default is users timezone. */
	timezone?: string,
	/** Test ID used for testing. */
	testID?: string
) {
	let component = value ? (
		<span className='nowrap' data-testid={testID}>
			<FormattedDate
				value={value}
				timeZone={timezone ? timezone : undefined}
				weekday='short'
				day='numeric'
				month='short'
				year='numeric'
			/>
			<br />
			<Text size={Size.Small} color='grey'>
				<FormattedTime value={value} timeZone={timezone ? timezone : undefined} />
			</Text>
		</span>
	) : null;

	return component;
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
