/**
 * @prettier
 * @flow
 */

import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Statistic } from 'semantic-ui-react';
import { Visibility, Count } from 'liana-ui/components';
import { FormattedNumber } from 'react-intl';
import { Size } from 'liana-ui/types';

import StatisticTrend from './StatisticTrend';
import StatisticGroup from './StatisticGroup';
import StatisticValue from './src/StatisticValue';
import StatisticLabel from './src/StatisticLabel';

/** COMPONENT BASED ON: https://react.semantic-ui.com/views/statistic */
component Component(
	/** A statistic must have main value. Only numbers will be animated. */
	value: number | React.Node,
	/** A statistic main value can be a link. */
	link?: string,
	/** A statistic can have a label to describe main value. */
	label?: React.Node,
	/** A statistic can have a trend. PROPS[StatisticTrend=/components/statistics/trend] */
	trend?: React.PropsOf<StatisticTrend>,
	/** A statistic can have additional custom content. */
	extraContent?: React.Node,
	/** A static can have different notations and formatting. */
	notation?: 'price' | 'percentage' | 'compact',
	/**
		A price notation statistic can have a currency symbol.
		VALUES['EUR', 'USD', ...]
	*/
	currency?: string,
	/** A statistic can take the width of its container. */
	fluid: boolean = false,
	/** A statistic can be different size. */
	size?: Size,
	/** A statistic can animate only when it becomes visible on screen. */
	lazyLoad: boolean = true,
	/** Animation duration in seconds. */
	duration?: number,
	/** Function called when count animation is complete. */
	onComplete?: () => void,
	/** Test ID for testing */
	testID: string = 'Statistic'
) {
	let [visible, setVisible] = useState(!lazyLoad);
	let number = value;

	/* istanbul ignore next */
	const makeVisible = () => setVisible(true);

	// Handle various formatting cases
	switch (notation) {
		case 'price':
			number = <FormattedNumber value={number} style='currency' currency={currency} />;
			break;
		case 'compact':
			number = <FormattedNumber value={number} notation='compact' compactDisplay='short' />;
			break;
		case 'percentage': {
			let opts = { value: number, style: 'percent' };
			number = Number.isInteger(number) ? (
				<FormattedNumber {...opts} />
			) : (
				<FormattedNumber {...opts} minimumFractionDigits={2} maximumFractionDigits={2} />
			);
			break;
		}
	}

	let statistic = (
		<Statistic className={fluid ? 'fluid' : undefined} size={size} data-testid={testID}>
			<StatisticValue
				as={link ? Link : undefined}
				to={link ? link : ''}
				content={
					typeof value == 'number' ? (
						<>
							<Count onComplete={onComplete} end={number} duration={duration} visible={visible} />
							<>{trend ? <StatisticTrend {...trend} label /> : null}</>
						</>
					) : (
						<>
							{value}
							{trend ? <StatisticTrend {...trend} label /> : null}
						</>
					)
				}
			/>
			<StatisticLabel content={label} />
			<div className='statistic-extra-content'>{extraContent}</div>
		</Statistic>
	);

	if (lazyLoad) {
		statistic = (
			<Visibility as={React.Fragment} onTopVisible={makeVisible}>
				{statistic}
			</Visibility>
		);
	}

	return statistic;
}

const MemoComponent = (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
// $FlowIssue - Static subcomponents
MemoComponent.Trend = StatisticTrend;
// $FlowIssue - Static subcomponents
MemoComponent.Group = StatisticGroup;

export default MemoComponent;
