/** @prettier */

import { merge } from 'lodash';
import { fetchIntl, fetchAll } from './Polyfill';
import { Fetch } from 'liana-ui/definitions';

// Polyfill for old React-Intl messages syntax
const flatten = (nestedMessages, prefix = '') => {
	return Object.keys(nestedMessages).reduce((messages, key) => {
		let value = nestedMessages[key],
			prefixedKey = prefix ? `${prefix}.${key}` : key;
		if (typeof value === 'string') {
			messages[prefixedKey] = value;
		} else {
			Object.assign(messages, flatten(value, prefixedKey));
		}
		return messages;
	}, {});
};

const loadIntl = (language: 'en', locales = {}) => {
	fetchIntl(); // Intl polyfill
	if (locales.hasOwnProperty(language)) {
		try {
			require(`intl/locale-data/jsonp/${locales[language]}`);
		} catch (err) {
			console.warn(`Intl language "${language} (${locales[language]})" is not defined.`); // eslint-disable-line
		}
	} else {
		console.warn(`loadIntl: Language "${language}" is not supported.`); // eslint-disable-line
	}
};

const loadPolyfills = (language: 'en', locales = {}) => {
	if (locales.hasOwnProperty(language)) {
		fetchAll(language); // Convenience: Load all language polyfills
	} else {
		console.warn(`loadPolyfills: Language "${language}" is not supported.`); // eslint-disable-line
	}
};

const loadLocalData = (language: 'en', locales = {}, path) => {
	// https://formatjs.io/docs/react-intl/components#intlprovider
	let data = { messages: {}, formats: [] };
	if (locales.hasOwnProperty(language)) {
		// LianaUI core translations
		if (typeof path === 'string') {
			try {
				let core = require(`liana-ui/locale/${language}`);
				data.messages = language === 'en' ? core : merge({}, require('liana-ui/locale/en'), core); // Merge english defaults
			} catch (err) {
				console.warn(`LianaUI core localization for "${language}" not found.`); // eslint-disable-line
			}
		}
		// Main app translations source
		try {
			if (language !== 'en') {
				data.messages = merge(data.messages, require('locale/en').messages); // Merge english defaults
			}
			let main = require(`locale/${language}`);
			data.formats = main.formats || [];
			data.messages = merge(data.messages, main.messages);
		} catch (err) {
			console.warn('Primary localizations not found.'); // eslint-disable-line
		}
	} else {
		console.warn(`loadLocalData: Language "${language}" is not supported.`); // eslint-disable-line
	}
	return data;
};

const loadRemoteData = async (language: 'en', locales = {}, url) => {
	// https://formatjs.io/docs/react-intl/components#intlprovider
	let data = { messages: {}, formats: [] };
	if (locales.hasOwnProperty(language)) {
		try {
			// LianaUI core translations
			let host = new URL(url);
			host = `${host.protocol}//${host.host}`;
			if (language !== 'en') {
				data.messages = await Fetch.get(`${host}/lianaui/locale/en.json`);
			}
			let coreTranslations = await Fetch.get(`${host}/lianaui/locale/${language}.json`);
			data.messages = merge(data.messages, coreTranslations); // Merge english defaults
		} catch (err) {
			console.log(err);
			console.warn(`Remote data for "${language}" not found.`); // eslint-disable-line
		}

		// Main app translations source
		try {
			if (language !== 'en') {
				let appDefaultTranslations = await Fetch.get(`${url}/en.json`);
				data.messages = merge(data.messages, appDefaultTranslations); // Merge english defaults
			}
			let appTranslations = await Fetch.get(`${url}/${language}.json`);
			data.formats = appTranslations.formats || [];
			data.messages = merge(data.messages, appTranslations.messages);
		} catch (err) {
			console.warn('Primary localizations not found.'); // eslint-disable-line
		}
	} else {
		console.warn(`loadRemoteData: Language "${language}" is not supported.`); // eslint-disable-line
	}
	return data;
};

export { flatten, loadIntl, loadPolyfills, loadLocalData, loadRemoteData };

export default loadLocalData;
