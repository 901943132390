/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { Responsive, Popup } from 'liana-ui/components/';
import { Table } from 'semantic-ui-react';
import { Device, VAlign, HAlign } from 'liana-ui/types';

/** COMPONENT BASED ON: https://react.semantic-ui.com/collections/table/ */
component Component(
	/** TODO: Undocumented prop */
	sticky?: any,
	/** TODO: Undocumented prop */
	className?: string,
	/** Cell content. */
	content?: string | React.Node,
	/** Represents colspan of cell. */
	colSpan?: number,
	/** A cell can be collapsing so that it only uses as much space as required. */
	collapsing: boolean = false,
	/** A cell can appear sorted. */
	sorted?: boolean,
	/** Represents width of cell. Possible values: 1 - 12 */
	width?: string | number,
	/** A table cell can adjust its text alignment. */
	textAlign?: HAlign,
	/** A table cell can adjust its text alignment. */
	verticalAlign?: VAlign,
	/** TODO: Undocumented prop */
	hideTouch?: boolean,
	/** Smallest device that cell will be displayed with. */
	minDevice?: Device,
	/** Largest device that cell will be displayed with. */
	maxDevice?: Device,
	/** Popup text or object of properties for Popup */
	popup?: React.Node | React.PropsOf<Popup>,
	/** Test ID for testing. */
	testID: string = 'TableFooterCell'
) {
	// Function to generate LianaUI Button
	const createCell = () => {
		// Assign classes
		const classes = classNames(
			{
				sorted: sorted
			},
			sticky,
			className
		);

		// Define cell
		let cell = (
			<Table.HeaderCell
				data-testid={testID}
				className={classes}
				collapsing={collapsing}
				colSpan={colSpan}
				width={width}
				textAlign={textAlign}
				verticalAlign={verticalAlign}
			>
				{content}
			</Table.HeaderCell>
		);

		// $FlowIssue - Attach popup; React statics
		return Popup.attach(popup, cell);
	};

	// Display reponsively
	let component =
		minDevice || maxDevice || hideTouch ? (
			<Responsive minDevice={minDevice} maxDevice={maxDevice} hideTouch={hideTouch}>
				{createCell()}
			</Responsive>
		) : (
			createCell()
		);

	return component;
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
