/**
 * @prettier
 * @flow
 */

import { FormattedMessage } from 'react-intl';
import { Modal, TransitionablePortal } from 'semantic-ui-react';
import ModalHeader from './src/ModalHeader';
import ModalContent from './src/ModalContent';
import ModalActions from './src/ModalActions';
import { Loader, Label } from 'liana-ui/components/';
import { Size } from 'liana-ui/types/';

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/modal/ */
component Component(
	/** 
	 	A modal can have a header text. 
	 	PROPS[React.Node=/localization/]
	*/
	header: React.Node,
	/** A modal must have content as children. */
	content: React.Node,
	/**
		A modal header can have labels.
		PROPS[LabelProps=/components/labels/labels/label/]
	*/
	headerLabels?: Array<React.PropsOf<Label>>,
	/** A modal can have actions at the bottom of the modal. Usually buttons. */
	actions?: React.Node | Array<React.Node>,
	/** A modal can have special content types. */
	type?: 'form' | 'iframe',
	/** A modal can be open. */
	open?: boolean = false,
	/** A modal can be loading when contnt is not fully ready to be displayed yet. */
	loading?: boolean = false,
	/** A modal can be vertically centered in the viewport. Should be set false (attaches to top) for modals that can dynamically change content height while open. */
	centered?: boolean = true,
	/** A modal can center its contents text alignment. */
	textAlign?: 'center',
	/**
		A modal can have different transitions animations.
		DATA[https://react.semantic-ui.com/modules/transition/]
	*/
	transition?: string = 'scale',
	/** A modal can overlay the whole screen. */
	overlay?: boolean = false,
	/** A modal can have an close icon. */
	closeIcon?: boolean = true,
	/** A modal can close on dimmer click */
	closeOnDimmerClick?: boolean = true,
	/** A modal can be different size. */
	size?: Size,
	/** You can manually specify the modal parent container */
	mountNode?: HTMLElement | null,
	/** Test ID for testing */
	testID: string = 'Modal',
	/** Called on modal close. */
	onClose?: (event: SyntheticEvent<>) => void
) {
	const focusModal = () => {
		let modals = document.querySelectorAll('.ui.page.modals.visible.active').length,
			modal = document.querySelector('.ui.page.modals.visible.active .modal.visible.active');
		if (modals > 1) {
			modal = document.querySelector('.ui.page.modals.visible.active:last-child .modal.visible.active');
		}
		if (modal) {
			modal.focus();
		}
	};

	// Define Modal
	let modal = (
		<Modal
			open
			tabIndex='-1'
			mountNode={mountNode}
			basic={overlay}
			centered={centered}
			header={header}
			size={size}
			dimmer={{ inverted: overlay, className: overlay ? 'overlay' : undefined }}
			closeIcon={!overlay && closeIcon ? { name: 'close' } : false}
			closeOnEscape={!overlay && closeIcon ? true : false}
			closeOnDimmerClick={closeOnDimmerClick}
			data-testid={testID}
			onClose={(event: SyntheticEvent<>) => {
				if (typeof onClose === 'function') {
					onClose(event);
				}
			}}
		>
			{!overlay && header ? (
				<ModalHeader>
					{header}

					{
						/** $FlowIssue - Flow can't detect statics */
						headerLabels ? <Label.Group as='span' labels={headerLabels} /> : null
					}
				</ModalHeader>
			) : null}
			{content ? (
				<ModalContent
					loading={loading && type === 'iframe'}
					removePaddings={Boolean(type)}
					textAlign={textAlign}
					className={!actions ? 'no-actions' : undefined}
				>
					{content}
				</ModalContent>
			) : null}
			{actions ? <ModalActions>{actions}</ModalActions> : null}
		</Modal>
	);

	return (
		<>
			{loading && type !== 'iframe' ? (
				<Loader text={<FormattedMessage id='component.loader.loading' />} active={loading} dimmer='page' />
			) : null}
			<TransitionablePortal
				mountNode={mountNode}
				open={open && (!loading || type === 'iframe')}
				transition={{ animation: transition, duration: 350 }}
				closeOnDocumentClick={false}
				closeOnEscape={!overlay && closeIcon ? true : false}
				onClose={(event: SyntheticEvent<>) => {
					setTimeout(() => focusModal(), 350);
					let body = document.querySelector('body');
					if (body) {
						body.classList.remove(`modal-${transition.split(' ').join('-')}`);
						if (document.querySelectorAll('.ui.modal.active').length < 2) {
							body.style.marginRight = '';
							body.classList.remove('dimmable', 'dimmed');
						}
					}
					if (typeof onClose === 'function') {
						onClose(event);
					}
				}}
				onOpen={() => {
					focusModal();
					let body = document.querySelector('body');
					if (body) {
						body.classList.add(`modal-${transition.split(' ').join('-')}`);
						if (body.scrollHeight > window.innerHeight) {
							body.style.marginRight = '17px';
							body.classList.add('dimmable', 'dimmed');
						}
					}
				}}
			>
				{modal}
			</TransitionablePortal>
		</>
	);
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
