/**
 * @prettier
 * @flow
 */

import ReactDOMServer from 'react-dom/server';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import type { Tooltip } from 'liana-ui/definitions/component/Types';
import Time from 'liana-ui/definitions/time/Time';
import Button from '../button/Button';
import Icon from '../icon/Icon';

import 'jquery-ui/ui/widget';
import 'jquery-ui/ui/widgets/mouse';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui-touch-punch';

// prettier-ignore
type Props = {
	intl: Intl,
	/** A date input must have an input name */
	name: string,
	/** A date input can have an id */
	id?: string,
	/** A date input can have additional classes. VALUES['disabled' | 'locked' | 'error'] */
	classes?: string,
	/**
		A date input can have a placeholder text.
	*/
	placeholder?: string,
	/**  Uncontrolled default value. Use for uncontrolled components only. VALUES[YYYY-MM-DD] */
	defaultValue?: string,
	/** Controlled value. Use for controlled components only. VALUES[YYYY-MM-DD | '']*/
	value?: string,
	/** A date input can have timezone offset. Timezone offset in seconds for date input. Default is this.context.user.get('timezone_offset') or this.context.user.timezone.offset */
	timezoneOffset?: number,
	/** A date input can be clearable */
	isResettable?: boolean,
	/** A date input can hide 'Today' and 'Done' buttons from the picker. */
	hideButtonPanel?: boolean,
	/** A date input can set a minimum allowed date. DATA[https://api.jqueryui.com/datepicker/#option-minDate] */
	minDate?: string,
	/** A date input can set a maximum alloed date. DATA[https://api.jqueryui.com/datepicker/#option-maxDate] */
	maxDate?: string,
	/** A date input can disable specific dates from selection. */
	restrictedDates?: Array<string>,
	/** A date input can disable specific days of week from selection */
	restrictedDaysOfWeek?: Array<string>,
	/** A date input picker can be initially positioned differently realive to field. */
	pickerPosition?: 'left bottom' | 'right bottom' | 'right top' | 'left top',
	/** A date input can have additional data attributes. */
	dataAttributes?: { [string]: string },
	/**
		A number input can have a tooltip.
		VALUES[tooltip={{'data-content': string, 'data-variation': string, 'data-delay': number}}]
	*/
	tooltip?: Tooltip,
	/** Extra settings for jQuery UI datepicker. DATA[http://api.jqueryui.com/datepicker/] */
	settings?: {},
	/** Function called when input date is focused. */
	onFocus?: (
		date: string,
		name: string,
		id: string
	) => mixed,
	/** Function called when input date is changed. */
	onChange?: (
		date: string,
		name: string,
		id: string
	) => mixed,
	/** Function called when user selects date from picker. */
	onSelect?: (
		startDate: string,
		endDate: string,
		name: string,
		id: string
	) => mixed,
	/** Function called when picker is closed. */
	onClose?: (
		date: string,
		name: string,
		id: string
	) => mixed,
	/** Function called when input is reseted. Returns: name, id */
	onReset?: (
		name: string,
		id: string
	) => mixed
};

/** COMPONENT BASED ON: https://jqueryui.com/datepicker/ */
class DateInput extends React.Component<Props> {
	timezoneOffset: string;
	hideButtonPanel: boolean;
	lastValue: string;
	weekDays: Array<string>;
	$wrapper: JQuery;
	$reset: JQuery;
	$display: JQuery;
	$input: JQuery;

	setWrapperRef: (?HTMLDivElement) => {};
	setDisplayRef: (?HTMLInputElement) => {};
	setInputRef: (?HTMLInputElement) => {};

	constructor(props: Props) {
		super(props);
		this.timezoneOffset = '';
		this.hideButtonPanel = false;
		this.lastValue = '';
		this.weekDays = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

		// Use callback refs not inline functions as refs to avoid null Refresh
		// https://reactjs.org/docs/refs-and-the-dom.html#callback-refs
		this.setWrapperRef = (element) => (this.$wrapper = $(element));
		this.setDisplayRef = (element) => (this.$display = $(element));
		this.setInputRef = (element) => (this.$input = $(element));
	}

	static defaultProps = {
		isResettable: true,
		hideButtonPanel: false,
		minDate: null,
		maxDate: null,
		restrictedDates: [],
		restrictedDaysOfWeek: [],
		pickerPosition: 'left bottom',
		settings: {
			showOn: 'both',
			dateFormat: 'yy-mm-dd',
			changeMonth: true,
			changeYear: true,
			yearRange: '-100:+100',
			duration: 75
		},
		onFocus: () => {},
		onChange: () => {},
		onSelect: () => {},
		onClose: () => {},
		onReset: () => {}
	};

	static contextTypes = {
		user: () => {}
	};

	componentDidMount() {
		this._initComponent();
	}

	componentDidUpdate(prevProps) {
		this.timezoneOffset = this.props.timezoneOffset; // Refresh timezone offset
		this._refreshComponent();
	}

	shouldComponentUpdate(nextProps) {
		// Let jQuery datepicker always set the value when it is changed with React value property
		if (
			(nextProps.value || nextProps.value === '' || nextProps.value === null) &&
			nextProps.value !== this.getDate()
		) {
			this.setDate(nextProps.value);
		} else if (!nextProps.value && nextProps.defaultValue && nextProps.defaultValue !== this.getDate()) {
			this.setDate(nextProps.defaultValue);
		}

		// If jQuery datepicker needs re-rendering
		return (
			nextProps.timezoneOffset !== this.props.timezoneOffset ||
			nextProps.minDate !== this.props.minDate ||
			nextProps.maxDate !== this.props.maxDate ||
			nextProps.restrictedDates !== this.props.restrictedDates ||
			nextProps.restrictedDaysOfWeek !== this.props.restrictedDaysOfWeek
		);
	}

	componentWillUnmount() {
		// http://stackoverflow.com/questions/16269034/jquery-2-0-jquery-ui-datepicker-bug-uncaught-typeerror-cannot-read-property/19039473
		// One way that I've found to fix this problem, assuming you want to reset the datepicker is: $(".hasDatepicker").removeClass("hasDatepicker");
		this.$input.removeClass('hasDatepicker');
		this.$input.datepicker('destroy');
		this.$input = null;
		window.removeEventListener('resize', this._positionPicker);
	}

	_getLanguage = () => {
		// Get user language and fetch translations
		let lang = this.props.intl.locale;

		if (lang !== 'en' && !(lang in $.datepicker.regional)) {
			require(`jquery-ui/ui/i18n/datepicker-${lang}.js`);
		} else if (lang === 'en') {
			lang = '';
		}

		$.datepicker.setDefaults($.datepicker.regional[lang]);
		return $.datepicker.regional[lang];
	};

	_initComponent = () => {
		this.lastValue = this.props.value || this.props.defaultValue;

		// Uses either this.context.user.get('timezone_offset') (Immutable) or this.context.user.timezone.offset (Non immutable) as default
		if (!this.props.timezoneOffset && this.context.user) {
			this.timezoneOffset =
				typeof this.context.user.get === 'function' && this.context.user.get('timezone_offset')
					? this.context.user.get('timezone_offset')
					: this.context.user.timezone && this.context.user.timezone.offset
					? this.context.user.timezone.offset
					: undefined;
		} else {
			this.timezoneOffset = this.props.timezoneOffset;
		}

		this.$input.datepicker(this._getOptions()); // Init jQuery DatePicker
		this._setDefaultVisual(); // Set default visual date
		window.addEventListener('resize', this._positionPicker); // Close picker on screen resize to handle positionin correctly
	};

	_refreshComponent() {
		// Refresh jQuery datepicker
		this.$input.datepicker('destroy').datepicker(this._getOptions());

		this.$input.datepicker('refresh');
	}

	_getOptions = () => {
		// Disable button panel if restricted dates to prevent selecting disabled today.
		// TODO: let user click Today but do nothing if it is disabled
		if (
			this.props.hideButtonPanel === true ||
			this.props.restrictedDates.length > 0 ||
			this.props.restrictedDaysOfWeek.length > 0
		) {
			this.hideButtonPanel = true;
			this.$wrapper.removeClass('buttons-visible');
		}

		let options = Object.assign(
			{},
			DateInput.defaultProps.settings,
			this._getLanguage(),
			this.props.settings,
			this._getFunctions(),
			{
				// Note: These forcibly overwrite props.settings
				defaultDate: Time.get(this.timezoneOffset, 'now', 'date'),
				showButtonPanel: this.hideButtonPanel === true ? false : true,
				minDate: this._getMinDate(),
				maxDate: this._getMaxDate(),
				disabled:
					String(this.props.classes).indexOf('locked') !== -1 ||
					String(this.props.classes).indexOf('disabled') !== -1
			}
		);

		return options;
	};

	_getMinDate = () => this._getDateBound('minDate');

	_getMaxDate = () => this._getDateBound('maxDate');

	_getDateBound = (bound: 'maxDate' | 'minDate') => {
		let date = null;
		if (typeof this.props[bound] === 'string') {
			date = this.props[bound];
			if (!this._isValidDate(date)) {
				date =
					date.charAt(0) === '-' || date.charAt(0) === '+'
						? Time.get(this.timezoneOffset, date, 'date')
						: Time.get(this.timezoneOffset, `+${date}d`, 'date');
			}
		}
		return date;
	};

	_getFunctions = () => {
		// Listen for any change to hidden input to trigger onChange callback
		this.$input.on(
			'change',
			function () {
				let value = this.getDate();
				if (value !== this.lastValue) {
					this.lastValue = value;
					this.$display.val(this._getVisualDate(value));
					this._toggleResetButton();
					this.props.onChange(value, this.props.name, this.props.id);
				}
			}.bind(this)
		);

		let functions = {
			beforeShow: (input, $instance) => {
				let $inputWrapper = $(input).parent(),
					$inputPicker = $('#ui-datepicker-div');

				$inputWrapper.addClass('datepicker-visible');
				if ($inputPicker) {
					$inputWrapper.append($inputPicker);
					this._positionPicker();
				}

				// Override $.datepicker._gotoToday function to mach instance timezoneoffset
				this._setGotoToday();

				// Trigger onFocus Callback. Not really "focus" though.
				this.props.onFocus(this.getDate(), this.props.name, this.props.id);
			},
			beforeShowDay: (date, $instance) => {},
			onSelect: (date, $instance) => {
				this.$input.trigger('change');
				this.props.onSelect(this.getDate(), this.props.name, this.props.id);
			},
			onClose: (date, $instance) => {
				this.props.onClose(this.getDate(), this.props.name, this.props.id);
			}
		};

		// Restrict selectable days by days of week or certain days
		if (this.props.restrictedDaysOfWeek || this.props.restrictedDates) {
			functions.beforeShowDay = (date, $instance) => {
				let day = date.getDay(),
					now = this.$input.datepicker('getDate');

				return this.props.restrictedDaysOfWeek || this.props.restrictedDates
					? [this._isDaySelectable(day, date), '']
					: [true, now === date ? 'ui-datepicker-current-day' : '']; // Add class if a day is selected
			};
		}
		return functions;
	};

	_setGotoToday() {
		// http://stackoverflow.com/questions/1073410/today-button-in-jquery-datepicker-doesnt-work
		// A really dirty hack to make this works in both DateInput and TimeInput. How can we override $.datepicker._gotoToday on single component level?
		$.datepicker._gotoToday = function (id) {
			// Handle _gotoToday for DateInputs
			let offset = this.timezoneOffset;
			if ($(id).parents('.date-input').length > 0) {
				this.$display.val(
					this.props.intl.formatDate(Time.get(offset, 'now', 'isoUTC'), {
						timeZone: 'UTC',
						year: 'numeric',
						month: 'short',
						day: 'numeric'
					})
				);
				$(id).datepicker('setDate', Time.get(offset, 'now', 'date'));
				$(id).trigger('change');
				// Handle _gotoToday for TimeInputs
				if ($(id).parents('.time-input').length > 0) {
					$(id).datetimepicker('setDate', Time.get(offset, 'now'));
				}
			}
		}.bind(this);
	}

	_setDefaultVisual = () => {
		let value = this.props.value && this.props.value !== '' ? this.props.value : this.props.defaultValue;
		if (value) {
			this.$display.val(this._getVisualDate(value));
		}
	};

	_positionPicker = () => {
		let $picker = $('#ui-datepicker-div'),
			pickerHeight = this.hideButtonPanel === false ? 340 : 285,
			position = this.props.pickerPosition,
			winTop = $(window).scrollTop(),
			winBot = $(window).scrollTop() + $(window).height(),
			docBot = $(document).height(),
			wrapTop = this.$wrapper.offset().top,
			wrapBot = wrapTop + this.$wrapper.height();

		if (position.indexOf('bottom') > -1 && (winBot || docBot) <= wrapBot + pickerHeight) {
			position = position.replace('bottom', 'top');
		}
		if (position.indexOf('top') > -1 && winTop >= wrapTop - pickerHeight) {
			position = position.replace('top', 'bottom');
		}
		$picker.removeClass('left right top bottom').addClass(position);
	};

	_sortWeekdays = (weekDays, firstDay): Array<string> => {
		let beginDates = weekDays.slice(0, firstDay);
		weekDays.splice(0, firstDay);
		return weekDays.concat(beginDates);
	};

	_isDaySelectable = (day: number, date: string): boolean => {
		date = $.datepicker.formatDate(this.$input.datepicker('option', 'dateFormat'), date);
		return (this.props.restrictedDaysOfWeek && this.props.restrictedDaysOfWeek.indexOf(this.weekDays[day]) > -1) ||
			(this.props.restrictedDates && this.props.restrictedDates.indexOf(date) > -1)
			? false
			: true;
	};

	_isValidDate = (date: string | Date) => date.toString().match(/(\d{4})-(\d{2})-(\d{2})/);

	_getVisualDate = (dateString: string): string => {
		return dateString
			? this.props.intl.formatDate(dateString, {
					timeZone: 'UTC',
					year: 'numeric',
					month: 'short',
					day: 'numeric'
			  })
			: '';
	};

	_toggleResetButton = () => {
		if (this.props.isResettable === true && this.$reset) {
			let value = this.$input.val();
			if (value.length > 0) {
				this.$reset.parent().addClass('reset-visible');
			} else {
				this.$reset.parent().removeClass('reset-visible');
			}
		}
	};

	/** Open picker */
	open = () => this.$input.datepicker('show');

	/** Close picker */
	close = () => this.$input.datepicker('hide');

	/** Get date. Returns: selected date */
	getDate = () => (this.$input ? this.$input.val() : '');

	/** Set date. Params: date, Returns: selected date */
	setDate = (date: string) => {
		date = date === null ? '' : date;
		let oldDate = this.getDate();
		this.$input.val(date);
		if (date !== oldDate) {
			this.$input.trigger('change');
		}
		return this.getDate();
	};

	/** Reset date. Returns: selected date */
	resetDate = () => {
		this.setDate('');
		this.props.onReset(this.props.name, this.props.id);
		return this.getDate();
	};

	/** Change current timezone offset. Params: timezone offset, Returns: current timezone offset */
	setTimezoneOffset = (timezoneOffset): string => {
		if (timezoneOffset === 'string') {
			this.timezoneOffset = timezoneOffset;
			this._refreshComponent();
		}
		return this.timezoneOffset;
	};

	render() {
		let classes = classNames('ui input left icon fluid date-input', this.props.classes, {
			'reset-input': this.props.isResettable,
			'reset-visible': this.props.isResettable && (this.props.value || this.props.defaultValue),
			'buttons-visible': !this.props.hideButtonPanel,
			'popup-open': this.props.tooltip
		});

		return (
			<div
				ref={this.setWrapperRef}
				className={classes}
				{...this.props.tooltip}
				data-testid={this.props.testID || DateInput.name}
			>
				{this.props.isResettable ? (
					<Button
						ref={(ref) => (this.$reset = ref ? $(ref.ref) : null)}
						classes='circular icon extramini reset-button'
						onClick={this.resetDate}
					>
						<Icon classes='fa-remove fa-solid' />
					</Button>
				) : null}
				<input
					ref={this.setDisplayRef}
					readOnly
					type='text'
					id={this.props.name + '-display'}
					placeholder={this.props.placeholder}
					onClick={this.open}
				/>
				<input
					ref={this.setInputRef}
					readOnly
					type='hidden'
					id={this.props.id}
					name={this.props.name}
					defaultValue={this.props.defaultValue}
					value={this.props.value}
					{...this.props.dataAttributes}
				/>
				<Icon classes='fa-calendar' />
			</div>
		);
	}
}

let Wrapper = injectIntl(DateInput, { forwardRef: true });
Wrapper.displayName = 'DateInput';
export default Wrapper;
