/**
 * @prettier
 * @flow
 */
import { useState, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLazyQuery } from '@apollo/client';
import Form from 'liana-ui/legacy/components/form/Form';
import { Search, SidePanel } from 'liana-ui/components';
import Data from './SidePanel.json';
import { GlobalSearch } from 'graphql/Search.graphql';
import MeContext from 'context/MeContext';

const Component = () => {
	const meCtx = useContext(MeContext);
	const [searchLoading, setSearchLoading] = useState(false);
	const [searchResults, setSearchResults] = useState();

	let sidePanelData = JSON.parse(JSON.stringify(Data));
	if (meCtx.organizationRole === 'ADMIN' || meCtx.organizationRole === 'STAFF') {
		sidePanelData.splice(7, 0, {
			icon: 'fa-thin fa-users',
			link: '/organization/users',
			text: 'uicommon.users'
		});
	}

	const handleSearchChange = (event, data) => {
		if (!data.value) {
			setSearchResults(null);
		}
		setSearchLoading(true);
	};
	const handleSearchChangeDelay = (event, data) => {
		const searchWord = data.value;
		if (searchWord && searchWord.length >= data.minCharacters) {
			startSideSearch({
				variables: {
					orgId: meCtx.selectedOrganization,
					solutionFilter: {
						or: [{ name: { contains: searchWord } }, { additionalName: { contains: searchWord } }]
					},
					accountFilter: { or: [{ name: { contains: searchWord } }, { email: { contains: searchWord } }] }
				}
			});
		}
	};
	const getTools = () => {
		return (
			<Form>
				<Form.Field>
					<Search
						name='side-search'
						maxResults={5}
						minCharacters={3}
						submitUrl='/search'
						showMoreLink='/search'
						loading={searchLoading}
						results={searchResults}
						onSearchChange={handleSearchChange}
						onSearchChangeDelay={handleSearchChangeDelay}
					/>
				</Form.Field>
			</Form>
		);
	};

	const [startSideSearch] = useLazyQuery(GlobalSearch, {
		onCompleted: (data) => {
			const solutions = data.organization?.solutions?.nodes;
			const accounts = data.organization?.accounts?.nodes;
			let results = [];
			if (solutions) {
				solutions.map((sol) => {
					const plan = sol.plan ? ` ${sol.plan}` : '';
					const additionalName = sol.additionalName ? ` (${sol.additionalName})` : '';
					results.push({
						title: sol.name + plan + additionalName,
						description: <FormattedMessage id='general.solutions' />,
						link: `/solutions/${sol.id}`
					});
				});
			}
			if (accounts) {
				accounts.map((acc) => {
					results.push({
						title: acc.name || acc.email,
						description: <FormattedMessage id='general.users' />,
						link: `/organization/users/${acc.id}`
					});
				});
			}
			setSearchResults(results);
			setSearchLoading(false);
		},
		onError: () => {
			setSearchResults([]);
			setSearchLoading(false);
		}
	});

	return <SidePanel tools={getTools()} navigation={sidePanelData} />;
};

Component.displayName = 'SidePanel';
export default Component;
