/**
 * @prettier
 * @flow
 */

let startY, startX, scrollLeft, scrollTop, isDown;
export default class TableUtils {
	constructor() {}

	static mouseIsDown = (event: SyntheticMouseEvent<>, container: HTMLElement) => {
		isDown = true;
		startY = event.pageY - container.offsetTop;
		startX = event.pageX - container.offsetLeft;
		scrollLeft = container.scrollLeft;
		scrollTop = container.scrollTop;
	};

	static mouseUp = () => {
		isDown = false;
	};

	static mouseLeave = () => {
		isDown = false;
	};

	static mouseMove = (event: SyntheticMouseEvent<>, container: HTMLElement) => {
		if (isDown) {
			event.preventDefault();
			// Move vertcally
			const y = event.pageY - container.offsetTop;
			const walkY = y - startY;
			container.scrollTop = scrollTop - walkY;

			// Move Horizontally
			const x = event.pageX - container.offsetLeft;
			const walkX = x - startX;
			container.scrollLeft = scrollLeft - walkX;
		}
	};

	static enableHorizontalDrag = (container: HTMLElement) => {
		// $FlowIssue[incompatible-call] - Events vs. SyntheticEvents
		container.addEventListener('mousedown', (event: SyntheticMouseEvent<>) => this.mouseIsDown(event, container));
		container.addEventListener('mouseup', () => this.mouseUp());
		container.addEventListener('mouseleave', () => this.mouseLeave());
		// $FlowIssue[incompatible-call] - Events vs. SyntheticEvents
		container.addEventListener('mousemove', (event: SyntheticMouseEvent<>) => this.mouseMove(event, container));
	};

	static disableHorizontalDrag = (container: HTMLElement) => {
		// $FlowIssue[incompatible-call] - Events vs. SyntheticEvents
		container.removeEventListener('mousedown', (event: SyntheticMouseEvent<>) =>
			this.mouseIsDown(event, container)
		);
		container.removeEventListener('mouseup', () => this.mouseUp());
		container.removeEventListener('mouseleave', () => this.mouseLeave());
		// $FlowIssue[incompatible-call] - Events vs. SyntheticEvents
		container.removeEventListener('mousemove', (event: SyntheticMouseEvent<>) => this.mouseMove(event, container));
	};
}
