/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';
import { Checkbox, Icon, Divider, IconGroup } from 'liana-ui/components/';
import { Validate } from 'liana-ui/definitions/';
import { Size } from 'liana-ui/types';

type Option = {
	text: React.Node,
	header: React.Node,
	value: string,
	icon: string | React.PropsOf<Icon>,
	icons: Array<string> | Array<React.PropsOf<Icon>>,
	divider: boolean,
	checkbox: React.PropsOf<Checkbox>,
	link: string,
	disabled: boolean,
	onClick: (event?: SyntheticEvent<>, data: any) => void
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/dropdown/#types-pointing */
component Component(
	/**
		A menu must have options.
		PROPS[React.Node=/language/localisation/, CheckboxProps=/components/forms/checkbox/, IconProps=/components/labels/icons/icon/]
	*/
	options: Array<Option>,
	/** A menu can take the width of its container. */
	fluid: boolean = false,
	/** A menu can be compressed to have less padding. */
	compressed: boolean = false,
	/** A menu can have rounded items. */
	rounded: boolean = true,
	/** A menu can have different sizes. */
	size?: Size,
	/** Test ID used for testing. */
	testID: string = 'Menu'
) {
	const getOptions = () => {
		let ret = [];
		if (options && options.length > 0) {
			options.forEach((option, i) => {
				if (option) {
					if (option.divider === true) {
						ret.push(<Divider key={`divider-${i}`} />);
					} else if (option.header) {
						ret.push(<Menu.Item header content={option.header} key={`header-${i}`} />);
					} else if (option.checkbox) {
						// FIXME: onClick setTimeout hack
						ret.push(
							<Menu.Item
								className='checkbox'
								key={`checkbox-${i}`}
								onClick={() => setTimeout(() => option.onClick(), 100)}
							>
								<Checkbox
									{...option.checkbox}
									size={size || Size.Small}
									onClick={(event, data) => {
										if (typeof option.checkbox.onClick === 'function') {
											option.checkbox.onClick(event, data);
										}
									}}
								/>
							</Menu.Item>
						);
					} else if (option.text) {
						let linkType = Validate.linkType(option.link); // Get link type
						ret.push(
							<Menu.Item
								key={`item-${i}`}
								as={linkType === 'internal' ? Link : linkType === 'external' ? 'a' : undefined}
								to={linkType === 'internal' ? option.link : undefined}
								href={linkType === 'external' ? option.link : undefined}
								target={linkType === 'external' ? '_blank' : undefined}
								rel={linkType === 'external' ? 'noopener noreferrer' : undefined}
								content={<span className='text'>{option.text}</span>}
								icon={
									Array.isArray(option.icons) ? (
										<IconGroup
											icons={
												typeof option.icons[0] === 'string'
													? [{ name: option.icons[0] }, { name: option.icons[1] }]
													: option.icons
											}
										/>
									) : typeof option.icon === 'string' ? (
										<Icon name={option.icon} />
									) : option.icon ? (
										<Icon {...option.icon} />
									) : undefined
								}
								disabled={option.disabled}
								onClick={option.onClick}
							/>
						);
					}
				}
			});
			return ret;
		}
	};

	// Assign classes
	let classes = classNames({
		rounded: rounded,
		compressed: compressed
	});

	return (
		<Menu vertical size={size} fluid={fluid} className={classes} data-testid={testID}>
			{getOptions()}
		</Menu>
	);
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
