/**
 * @prettier
 * @flow
 */

import { Breadcrumb } from 'semantic-ui-react';

/** COMPONENT BASED ON: https://react.semantic-ui.com/collections/breadcrumb/ */
component Component(testID?: string) {
	return <Breadcrumb.Divider icon='fa-chevron-right' data-testid={testID} />;
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
