/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import FormActions from 'liana-ui/legacy/components/form-actions/FormActions';
import FormField from 'liana-ui/legacy/components/form-field/FormField';
import FormFieldRow from 'liana-ui/legacy/components/form-field-row/FormFieldRow';
import FormLabel from 'liana-ui/legacy/components/form-label/FormLabel';
import FormRequiredFields from 'liana-ui/legacy/components/form-required-fields/FormRequiredFields';
import FormSection from 'liana-ui/legacy/components/form-section/FormSection';

import 'fomantic-ui/definitions/behaviors/form';

// prettier-ignore
type Props = {
	/** Form name attribute */
	name?: string,
	/** Submit URL address */
	action?: string,
	/** HTTP method */
	method?: 'GET' | 'POST',
	/** Form encoding type */
	enctype?: 'application/x-www-form-urlencoded' | 'multipart/form-data' | 'text/plain',
	/** Content placed inside .ui.form element */
	children?: React.Node,
	/** Classes for .ui.form element */
	classes?: string,
	/** Extra settings for Fomantic UI form validation (https://fomantic-ui.com/behaviors/form.html#/settings) */
	settings: { data?: mixed },
	/** Callback when form is changed */
	onChange?: () => mixed,
	/** Callback when form is submitted */
	onSubmit?: (
		event: SyntheticEvent<>
	) => mixed
};

/** COMPONENT BASED ON: https://fomantic-ui.com/collections/form.html */
export default class Form extends React.PureComponent<Props> {
	$form: JQuery;
	constructor(props: Props) {
		super(props);
	}
	// Attach Subcomponents as a static property
	static Actions = FormActions;
	static Field = FormField;
	static FieldRow = FormFieldRow;
	static Label = FormLabel;
	static RequiredFields = FormRequiredFields;
	static Section = FormSection;

	static defaultProps = {
		enctype: 'application/x-www-form-urlencoded',
		onChange: () => {}, // noop
		onSubmit: (event: SyntheticEvent<>) => event.preventDefault(),
		settings: {
			keyboardShortcuts: false,
			on: 'submit',
			inline: true,
			transition: 'slide',
			duration: 150,
			className: {
				label: 'ui basic mini red pointing prompt label'
			}
		}
	};

	componentDidMount() {
		let settings = $.extend(true, {}, Form.defaultProps.settings, this.props.settings, {
			onFailure: function () {
				this.scrollToErrors();
				return false;
			}.bind(this)
		});
		if (typeof this.props.onChange === 'function') {
			this.$form.on('change', this.props.onChange);
		}
		if (typeof settings.data === 'object') {
			this.$form.data(settings.data);
		}

		this.$form.form(settings);
	}

	componentDidUpdate(nextProps: Props) {
		let settings = $.extend(true, {}, Form.defaultProps.settings, nextProps.settings, {
			onFailure: function () {
				this.scrollToErrors();
				return false;
			}.bind(this)
		});
		this.refresh(settings);
		this._cleanValidation();
	}

	componentWillUnmount() {
		if (this.props.settings && this.$form) {
			this.$form.off('change', this.props.onChange);
			this.$form.form('destroy');
			this.clearValidation();
		}
	}

	_handleSubmit = (event) => {
		event.preventDefault();
		this.props.onSubmit(event);
	};

	// This is a form data formating function because of some Fomantic UI oddities
	// 1. Convert checkboxes with same names to arrays
	// 2. Convert toggle "on" values to true
	_formatValues = (values: { [string]: Array<string> | string | boolean }) => {
		let name = '',
			val,
			fields,
			$form = this.$form;

		$form.find('input[type=checkbox]').each(function () {
			name = String($(this).attr('name'));
			if (values.hasOwnProperty(name)) {
				val = $(this).val();
				fields = $form.find('input[type=checkbox][name=' + name + ']');

				if (fields.length > 1) {
					if (!Array.isArray(values[name])) {
						values[name] = [];
					}
					if ($(this).is(':checked')) {
						// $FlowFixMe variable inference does not work here
						values[name].push(val);
					}
				} else {
					values[name] = $(this).is(':checked') ? true : false;
				}
			}
		});
		$form.find('input[type=radio]:checked').each(function () {
			name = String($(this).attr('name'));
			if (name) {
				values[name] = $(this).val();
			}
		});
		return values;
	};

	// FUI does not clear all errors correctly atm.
	_cleanValidation() {
		let $field = this.$form.find('input, textarea, select'),
			$group = this.$form.find('.field');

		$field.each(function () {
			var $fieldGroup = $(this).closest($group),
				$prompt = $fieldGroup.find('.prompt.label');

			if (!$fieldGroup.hasClass('error')) {
				$prompt.attr('style', '');
			}
		});
	}

	/** validate(): Validate the form */
	validate() {
		if (this.$form.form('validate form')) {
			return true;
		} else {
			this.scrollToErrors();
			return false;
		}
	}

	/** validateField(field_name: string): Validate a single form field. */
	validateField(field: string) {
		setTimeout(() => this.$form.form('validate field', field), 250);
	}

	/** reset(): Reset form. NOTICE! Works mostly on legacy FUI components. Please use new SUI-R components and controlled forms instead. */
	reset() {
		// Empty all TextareTags. Should probably be moved to TextareTags component that listen for the form reset.
		let $textareaTags = this.$form.find('textarea.tag-editor-hidden-src');
		$textareaTags.each(function (index, item) {
			let tags = $(item).tagEditor('getTags')[0].tags;
			for (let i = 0; i < tags.length; i++) {
				$(item).tagEditor('removeTag', tags[i], true);
			}
		});

		// Hide all form field reset buttons (DateInput, DateRange, TimeInput etc.). Should probably be moved to individual components that listen for the form reset.
		this.$form.find('.reset-visible').removeClass('reset-visible');

		// Reset input fields (https://stackoverflow.com/questions/23892547/what-is-the-best-way-to-trigger-onchange-event-in-react-js)
		let $input = this.$form.find('.ui.input input');
		$input.each(function () {
			let valueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
			valueSetter.call(this, $(this).closest('.ui.input').data('default') || '');
			this.dispatchEvent(new Event('input', { bubbles: true }));
		});

		// Reset input fields (https://stackoverflow.com/questions/23892547/what-is-the-best-way-to-trigger-onchange-event-in-react-js)
		let $search = this.$form.find('.ui.search input');
		$search.each(function () {
			let valueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
			valueSetter.call(this, $(this).closest('.ui.search').data('default') || '');
			this.dispatchEvent(new Event('input', { bubbles: true }));
		});

		// Reset textarea fields (https://stackoverflow.com/questions/23892547/what-is-the-best-way-to-trigger-onchange-event-in-react-js)
		let $textarea = this.$form.find('.textarea textarea');
		$textarea.each(function () {
			let valueSetter = Object.getOwnPropertyDescriptor(window.HTMLTextAreaElement.prototype, 'value').set;
			valueSetter.call(this, $(this).closest('.textarea').data('default') || '');
			this.dispatchEvent(new Event('input', { bubbles: true }));
		});

		// Reset checkbox/radio fields (https://stackoverflow.com/questions/23892547/what-is-the-best-way-to-trigger-onchange-event-in-react-js)
		let $checkbox = this.$form.find('.ui.checkbox input');
		$checkbox.each(function () {
			let valueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'checked').set;
			valueSetter.call(this, $(this).closest('.ui.checkbox').data('default') || false);
			this.dispatchEvent(new Event('input', { bubbles: true }));
		});

		// Reset dropdown fields (https://stackoverflow.com/questions/23892547/what-is-the-best-way-to-trigger-onchange-event-in-react-js)
		let $dropdown = this.$form.find('.dropdown-wrapper input');
		$dropdown.each(function () {
			let valueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
			valueSetter.call(this, $(this).closest('.dropdown-wrapper').find('.ui.dropdown').data('default') || '');
			this.dispatchEvent(new Event('input', { bubbles: true }));
		});

		// Reset all HTML fields
		return this.$form.form('reset');
	}

	/** clear(): Empty all the form fields. NOTICE! Works mostly on legacy FUI components. Please use new SUI-R components and controlled forms instead. */
	clear() {
		// Clear input/search fields (https://stackoverflow.com/questions/23892547/what-is-the-best-way-to-trigger-onchange-event-in-react-js)
		let $input = this.$form.find('.ui.input input');
		$input.each(function () {
			let valueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
			valueSetter.call(this, '');
			this.dispatchEvent(new Event('input', { bubbles: true }));
		});

		// Clear limited textarea fields (https://stackoverflow.com/questions/23892547/what-is-the-best-way-to-trigger-onchange-event-in-react-js)
		let $textarea = this.$form.find('.textarea textarea');
		$textarea.each(function () {
			let valueSetter = Object.getOwnPropertyDescriptor(window.HTMLTextAreaElement.prototype, 'value').set;
			valueSetter.call(this, '');
			this.dispatchEvent(new Event('input', { bubbles: true }));
		});

		// Clear checkbox fields (https://stackoverflow.com/questions/23892547/what-is-the-best-way-to-trigger-onchange-event-in-react-js)
		let $checkbox = this.$form.find('.ui.checkbox input');
		$checkbox.each(function () {
			let valueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'checked').set;
			valueSetter.call(this, false);
			this.dispatchEvent(new Event('input', { bubbles: true }));
		});

		// Clear dropdown fields (https://stackoverflow.com/questions/23892547/what-is-the-best-way-to-trigger-onchange-event-in-react-js)
		let $dropdown = this.$form.find('.dropdown-wrapper input');
		$dropdown.each(function () {
			let multiple = $(this).closest('.dropdown-wrapper').find('.ui.dropdown').hasClass('multiple');
			let valueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
			valueSetter.call(this, '');
			this.dispatchEvent(new Event('input', { bubbles: true }));
		});

		return this.$form.form('clear');
	}

	/** clearValidation(): Clear form validation */
	clearValidation() {
		this.$form.removeClass('error');
		let $field = this.$form.find('input, textarea, select'),
			$group = this.$form.find('.field');

		$field.each(function () {
			var $fieldGroup = $(this).closest($group),
				$prompt = $fieldGroup.find('.prompt.label');

			$prompt.remove();
			if ($fieldGroup.hasClass('error')) {
				$fieldGroup.removeClass('error');
			}
		});
	}

	/** value(id: string): Returns value of form element with id */
	value(id: string): mixed {
		// this.values that uses _formatValues is a Fomantic UI hack. May be removed when SUI can return form data in correctly
		return this.values([id])[id];
	}

	/** values(ids?: Array<string>): Returns object of form element values that match array of ids. If no IDS are passed will return all form fields */
	values(ids?: Array<string>) {
		let values = null;
		if (ids instanceof Array && ids.length > 0) {
			values = this.$form.form('get values', ids);
		} else {
			values = this.$form.form('get values');
		}
		// _formatValues is a Fomantic UI hack. May be removed when SUI can return form data in correctly
		return this._formatValues(values);
	}

	/** refresh(settings: any): Refresh form settings */
	refresh(settings: any) {
		let all_settings = $.extend(true, {}, Form.defaultProps.settings, this.props.settings, settings);
		return this.$form.form(all_settings);
	}

	/** showErrors(errors: Array<Object>, intl: Intl): Show error messages what we get from backend */
	showErrors(errors: Array<Object>, intl: Intl) {
		if (!Array.isArray(errors)) {
			errors = [];
		}
		for (let error of errors) {
			if (typeof error === 'object') {
				for (let field in error) {
					this.prompt(field, intl.formatMessage({ id: error[field] }));
				}
			}
		}
		this.scrollToErrors();
	}
	/** prompt(field: string, message: string): Trigger a prompt on a field */
	prompt(field: string, message: string) {
		if (this.$form) {
			this.$form.form('add prompt', field, message);
		}
	}

	/** scrollToErrors(): Scroll to show error if out of screen */
	scrollToErrors() {
		// Get context
		let isWindow = $('.ui.dimmer.modals.visible.active').length > 0 ? true : false;
		let $container = isWindow ? $('.ui.dimmer.modals') : $('html, body');
		var wintop, winbottom, errortop, errorbottom;

		// Get error
		let $error = $container.find('.ui.red.prompt:visible:first');
		if ($error.length === 0) {
			$error = $container.find('.ui.popup.red:visible:first');
		}

		if ($error.length > 0) {
			if (isWindow) {
				wintop = $container.scrollTop();
				winbottom = wintop + $container.height();
				errortop = wintop + ($error.offset().top - $(window).scrollTop());
				errorbottom = errortop + $error.height();
			} else {
				wintop = $(window).scrollTop();
				winbottom = wintop + $(window).height();
				errortop = $error.offset().top;
				errorbottom = errortop + $error.height();
			}

			if (wintop > errortop || winbottom < errorbottom + 100) {
				$container.animate({ scrollTop: errortop - 100 }, 350);
			}
		}
	}

	render() {
		let classes = classNames('ui form', this.props.classes);
		return (
			<form
				ref={(ref) => (this.$form = $(ref))}
				name={this.props.name}
				action={this.props.action}
				encType={this.props.enctype}
				method={this.props.method}
				className={classes}
				data-testid={this.props.testID || Form.name}
				onSubmit={this._handleSubmit}
			>
				{this.props.children}
			</form>
		);
	}
}
