/**
 * @prettier
 * @flow
 */
import { isEqual } from 'lodash';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import Input from 'liana-ui/legacy/components/input/Input';
import type { Tooltip } from 'liana-ui/definitions/component/Types';

import { Icon, Flag } from 'liana-ui/components/';

import 'fomantic-ui/definitions/modules/dropdown';

// prettier-ignore
type Props = {
	/* Automatic react-intl translation object. */
	intl: Intl,
	/** A prefix input can have and id. */
	id?: string | number,
	/** A prefix input must have a name. */
	name: string,
	/** A prefix can have different input type. */
	type: string,
	/** Dropdown default value with both prefix and text */
	defaultValue?: string,
	/** Value for input with both prefix and text */
	value?: string,
	/** Text input placeholder */
	placeholder?: string,
	/** Classes for .ui.input element */
	classes?: string,
	/** An prefix must have options. */
	prefixOptions: Array<string>,
	/** An prefix input options can be searched */
	search?: boolean,
	/**
		A number input can have a tooltip.
		VALUES[tooltip={{'data-content': string, 'data-variation': string, 'data-delay': number}}]
	*/
	tooltip?: Tooltip,
	/** Timeout for input events */
	delay?: number,
	/** Function called when value is changed. Returns: value, name, id */
	onChange?: (
		value,
		name,
		id
	) => {
		value: string,
		name: string,
		id: string
	}
};

/** COMPONENT BASED ON: https://fomantic-ui.com/elements/input.html#labeled */

class PrefillInput extends React.Component<Props> {
	lastValue: string;
	updateTimeout: ?TimeoutID;
	$dropdown: JQuery;
	$prefixInput: JQuery;
	$textInput: JQuery;
	$valueInput: JQuery;
	prefix: string;
	text: string;
	setDropdownRef: () => mixed;
	setPrefixInputRef: () => mixed;
	setTextInputRef: () => mixed;
	setValueInputRef: () => mixed;

	constructor(props: Props) {
		super(props);
		this.lastValue = '';
		this.lastPrefixOptions = [];
		this.$dropdown = null;
		this.$prefixInput = null;
		this.$textInput = null;
		this.$valueInput = null;
		this.prefix = '';
		this.text = '';

		// Use callback refs not inline functions as refs to avoid null Refresh
		// https://reactjs.org/docs/refs-and-the-dom.html#callback-refs
		this.setDropdownRef = (ref) => {
			this.$dropdown = $(ref);
		};
		this.setPrefixInputRef = (ref) => {
			this.$prefixInput = $(ref);
		};
		this.setTextInputRef = (ref) => {
			this.$textInput = $(ref);
		};
		this.setValueInputRef = (ref) => {
			this.$valueInput = $(ref);
		};
	}

	static defaultProps = {
		placeholder: '',
		delay: 0,
		prefixOptions: [],
		onChange: () => {}
	};

	componentDidMount() {
		this._initDropdown();
	}

	componentWillUnmount() {
		this.$dropdown.dropdown('destroy');
	}

	componentDidUpdate() {
		if (
			(this.props.value && this.props.value !== this.lastValue) ||
			!isEqual(this.lastPrefixOptions, this.props.prefixOptions)
		) {
			this._initDropdown();
		}
	}

	shouldComponentUpdate(nextProps) {
		if (nextProps.value && nextProps.value !== this.getValue()) {
			this.setValue(nextProps.value);
		}

		if (!isEqual(this.lastPrefixOptions, this.props.prefixOptions)) {
			this.lastPrefixOptions = this.props.prefixOptions;
			return true;
		}

		return false;
	}

	_initDropdown = () => {
		// Set default values
		this.lastValue = this.props.value || this.props.defaultValue;
		let values = this._getInputValues(this.lastValue);

		this.$prefixInput.val(values.prefix.trim());
		this.$textInput.val(values.text.trim());

		if (this.getTextValue()) {
			this.$valueInput.val(this.getPrefixValue() + this.getTextValue());
		} else {
			this.$valueInput.val('');
		}

		/** SUI Dropdown init */
		this.$dropdown.dropdown(this._getSettings());
		this.$dropdown.dropdown('set selected', this.getPrefixValue());
	};

	/** Get Dropdown settings */
	_getSettings = () => {
		let settings = Object.assign({}, this._getFunctions());
		return settings;
	};

	/** Dropdown change listener, SUI callback functions */
	_getFunctions = () => {
		// Listen for any change to text input; throttle input events
		this.$textInput.on('keyup', () => {
			if (this.props.delay === 0) {
				this._handleChange();
			} else {
				this.updateTimeout = setTimeout(() => this._handleChange(), this.props.delay);
			}
		});

		// Listen for any change to prefix hidden input
		this.$prefixInput.on('change', () => {
			this._handleChange();
		});

		return {};
	};

	/** Set input value and trigger onChange callback */
	_handleChange = () => {
		let text = this.getTextValue();
		let prefixOptions = this.props.prefixOptions;

		prefixOptions.forEach((option) => {
			if (typeof option === 'object') {
				option = new String(option.value);

				// 00 is the same as + for phone numbers
				if (option.indexOf('+') === 0 && text.indexOf('00') === 0) {
					text = text.replace('00', '+');
				}
			}

			if (text.indexOf(option) === 0) {
				this.setValue(text);
			}
		});

		if (this.getTextValue()) {
			this.$valueInput.val(this.getPrefixValue() + this.getTextValue());
		} else {
			this.$valueInput.val('');
		}

		let value = this.getValue();
		if (value !== this.lastValue) {
			this.lastValue = value;
			this.props.onChange(this.getValue(), this.props.name, this.props.id);
			this.$valueInput.trigger('change');
		}
	};

	/** Search string value for prefix + text parts */
	_getInputValues = (value: string) => {
		let parts = {
			prefix: '',
			text: ''
		};

		let prefixOptions = this.props.prefixOptions;

		if (prefixOptions.length > 0) {
			if (value) {
				prefixOptions.forEach((option) => {
					if (!option.divider) {
						let index;
						if (typeof option === 'object') {
							index = value.indexOf(option.value);
							if (index !== -1) {
								parts.prefix = value.substr(index, index + option.value.length);
								parts.text = value.split(parts.prefix)[1];
							}
						} else if (typeof option === 'string') {
							index = value.indexOf(option);
							if (index !== -1) {
								parts.prefix = value.substr(index, index + option.length);
								parts.text = value.split(parts.prefix)[1];
							}
						}
					}
				});
			}

			if (!parts.prefix) {
				let firstOption = '';
				if (typeof prefixOptions[0] === 'object') {
					firstOption = prefixOptions[0].value;
				} else if (typeof prefixOptions[0] === 'string') {
					firstOption = prefixOptions[0];
				}
				parts.prefix = firstOption;
			}
		}

		return parts;
	};

	/** Set prefix and text values based on string search results */
	_setInputValues = (value: string) => {
		let values = this._getInputValues(value);
		this.$prefixInput.val(values.prefix.trim());
		this.$textInput.val(values.text.trim());
		this.$valueInput.val(values.prefix.trim() + values.text.trim());
	};

	/** Get prefix options nodes */
	_getPrefixOptions = (values: Array<string>) => {
		let options = [],
			ctr = 0;
		if (values) {
			values.map((value) => {
				if (typeof value === 'object') {
					if (value.divider) {
						options.push(<div key={ctr} className='divider' />);
					} else {
						options.push(
							<div key={ctr} className='item' data-value={value.value}>
								{value.flag ? (
									<span className='hide-on-select'>
										<Flag name={value.flag} />
									</span>
								) : null}
								{value.text ? value.text : value.value}
							</div>
						);
					}
				} else if (typeof value === 'string') {
					options.push(
						<div key={ctr} className='item' data-value={value}>
							<span className='text'>{value}</span>
						</div>
					);
				}
				ctr++;
			});
		}
		return options;
	};

	/** Set value. Params: value (prefix and text), Returns: current value */
	setValue = (value: string) => {
		let values = this._getInputValues(value);
		this.$prefixInput.val(values.prefix.trim());
		this.$textInput.val(values.text.trim());
		this.$dropdown.dropdown('set selected', values.prefix.trim());
		this._handleChange();
		return this.getValue();
	};

	/** Get prefix value */
	getPrefixValue = () => {
		return this.$prefixInput.val();
	};

	/** Get text value */
	getTextValue = () => {
		return this.$textInput.val();
	};

	/** Get full input value with prefix and text */
	getValue = () => {
		return this.$valueInput.val();
	};

	render() {
		/** Prefill input classes */
		let inputClasses = classNames(
			'ui left labeled input',
			{
				'popup-open': this.props.tooltip
			},
			this.props.classes
		);
		/** Prefix dropdown classes */
		let dropdownClasses = classNames('ui dropdown selection label prefill-dropdown', {
			disabled: String(this.props.classes).indexOf('disabled') !== -1,
			locked: String(this.props.classes).indexOf('locked') !== -1
		});

		return (
			<div className={inputClasses} {...this.props.tooltip} data-testid={this.props.testID || PrefillInput.name}>
				<div className={dropdownClasses} ref={this.setDropdownRef}>
					<input ref={this.setPrefixInputRef} type='hidden' name={`${this.props.name}Prefix`} />
					<Icon name='chevron down' />
					<div className='default text'>{this.prefix}</div>
					<div className='menu'>
						{this.props.search ? (
							<>
								<Input
									classes='icon search'
									placeholder={this.props.intl.formatMessage({
										id: 'component.dropdown.search'
									})}
								>
									<Icon name='search' />
								</Input>
								<div className='scrolling menu'>{this._getPrefixOptions(this.props.prefixOptions)}</div>
							</>
						) : (
							this._getPrefixOptions(this.props.prefixOptions)
						)}
					</div>
				</div>
				<input
					ref={this.setTextInputRef}
					type={this.props.type ? this.props.type : 'text'}
					name={`${this.props.name}Input`}
					readOnly={String(this.props.classes).indexOf('locked') !== -1}
					disabled={String(this.props.classes).indexOf('disabled') !== -1}
					placeholder={this.props.placeholder}
				/>
				<input
					ref={this.setValueInputRef}
					type='hidden'
					id={this.props.id}
					name={this.props.name}
					defaultValue={this.props.defaultValue}
					value={this.props.value}
				/>
			</div>
		);
	}
}

let Wrapper = injectIntl(PrefillInput, { forwardRef: true });
Wrapper.displayName = 'PrefillInput';
export default Wrapper;
