/**
 * @prettier
 * @flow
 */

import { useNavigate } from 'react-router-dom';
import { Validate } from 'liana-ui/definitions';
import { Popup } from 'liana-ui/components/';

component Component(
	/** Detail as children. */
	children: React.Node,
	/** Props object for Popup */
	popup?: React.Node | React.PropsOf<Popup>,
	/** Item can be a link. Opens public links (http:// or https://) to new browser tab and internal links via routing */
	link?: string,
	/** Item link can have a target. Default _blank. */
	linkTarget?: string = '_blank',
	/** List link can be clicked. */
	onClick?: (event: SyntheticEvent<>) => void
) {
	const navigate = useNavigate();
	let linkType = Validate.linkType(link); // Check link type

	const handleClick = (event: SyntheticEvent<>) => {
		// Trigger onClick callback funtion
		if (typeof onClick === 'function') {
			onClick(event);
		}

		// Trigger internal link
		if (linkType && (linkType === 'internal' || linkType === 'anchor')) {
			event.preventDefault();
			if (linkType === 'internal') {
				navigate(link);
			}
			if (linkType === 'anchor') {
				Safely.scroll(link, () => {
					navigate(link);
				});
			}
		}
	};

	let element = (
		<a
			href={link || onClick ? (link ? link : '#') : undefined}
			target={linkType === 'external' ? linkTarget : undefined}
			rel={linkType === 'external' ? 'noopener noreferrer' : undefined}
			onClick={handleClick}
		>
			{children}
		</a>
	);

	// $FlowIssue - React statics; Attach popup
	return Popup.attach(popup, element);
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
