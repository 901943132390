/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import type { Tooltip } from 'liana-ui/definitions/component/Types';
import Label from '../label/Label';

type Props = {
	/** Input name */
	name?: string,
	/** Input value (controlled component) */
	value?: string | number,
	/** Initial input value */
	defaultValue?: string | number,
	/** Content placed inside .ui.input element */
	children?: React.Node,
	/** Classes for .ui.input element */
	classes?: string,
	/** Input variation (Deprecated! Just use SUI classes instead) */
	variation?: string,
	/** Classes for input element */
	inputClasses?: string,
	/** Type of input (text, number, email) */
	type?: string,
	/** Max character limit for input value */
	max: number,
	/** HTML data attrributes for input element */
	dataAttributes?: { [string]: string },
	/** Tooltip (data-content, data-position, data-delay) */
	tooltip?: Tooltip,
	/** Placeholder HTML attribute */
	placeholder?: string,
	/** Read-only HTML attribute */
	readOnly?: boolean,
	/** AutoFocus HTML attribute */
	autoFocus?: boolean,
	/** Autocomplete HTML attribute */
	autoComplete?: 'on' | 'off',
	/** onFocus event handler */
	onFocus: (event: SyntheticInputEvent<>) => mixed,
	/** onChange event handler */
	onChange?: (event: SyntheticEvent<>) => mixed
};

/** COMPONENT BASED ON: https://fomantic-ui.com/elements/input.html */
export default class Input extends React.PureComponent<Props> {
	_input: { current: React.ElementRef<'input'> | null };
	_label: ?HTMLSpanElement;
	_isLimitValue: boolean;

	constructor(props: Props) {
		super(props);
		this._input = React.createRef();
		this._isLimitValue = this._getIsLimitValueActive();
	}

	static defaultProps = {
		autoComplete: 'off',
		type: 'text',
		max: 0,
		onFocus: (event: SyntheticEvent<>) => {}, //noop
		onChange: (event: SyntheticEvent<>) => {} //noop
	};

	componentDidMount() {
		this._isLimitValue = this._getIsLimitValueActive();
		this._limitInputValue(true);
	}

	componentDidUpdate() {
		this._isLimitValue = this._getIsLimitValueActive();
		this._limitInputValue(true);
	}

	/** ES6 setter: value */
	set value(value: string) {
		if (this._input.current) {
			this._input.current.value = value;
		}
		if (this._getIsLimitValueActive()) {
			// eslint-disable-line
			this._limitInputValue();
		}
	}

	/** ES6 getter: value */
	get value() {
		return this._input.current ? this._input.current.value : '';
	}

	/** ES6 getter: reference */
	get ref() {
		return this._input.current;
	}

	_getIsLimitValueActive = () => {
		return typeof this.props.max === 'number' && this.props.max > 0 && this.props.value === undefined;
	};

	/** Private function: Limit & set value to input */
	_limitInputValue = (updateLimitLabel: boolean = false) => {
		let value;
		if (this._isLimitValue) {
			// Limit value string length
			value = this.value;
			// $FlowFixMe this.props.max assumed valid here
			if (typeof value === 'string' && value.length > this.props.max) {
				this.value = value.substring(0, this.props.max);
			}
			// Update label
			if (updateLimitLabel === true) {
				this.updateLimitLabel();
			}
		}
	};

	/** Set label text */
	setLabelText(text: string) {
		if (text && typeof text === 'string' && this._label) {
			this._label.textContent = text;
		}
	}

	/** Add/remove classes to/from label */
	setLabelClass(classes: Array<string>, operation: string) {
		if (Array.isArray(classes) && classes.length && (operation === 'add' || operation === 'remove')) {
			// Filter the non string and empty values from array
			classes = classes.filter((item) => typeof item === 'string' && item.length > 0);
			// Add class names
			if (this._label && operation === 'add') {
				this._label.classList.add(...classes);
			} else if (this._label && operation === 'remove') {
				// Remove class names
				this._label.classList.remove(...classes);
			}
		}
	}

	/** Update limit label counter text and classes */
	updateLimitLabel = () => {
		let value = '',
			valueLength = 0;
		if (this.props.max > 0) {
			value = this.value;
			if (typeof value === 'string') {
				valueLength = value.length;
				// Update label text
				this.setLabelText(`${valueLength}/${this.props.max}`);
				// Update label background color (transparent or red)
				this.setLabelClass(['red'], valueLength === this.props.max ? 'add' : 'remove');
			}
		}
	};

	/** Private function: Handles the input change events */
	_handleInputChange = (event: SyntheticEvent<>) => {
		if (this._input.current) {
			$(this._input.current).trigger('change');
		}
		// This code is active only when the max prop is defined
		if (typeof this.props.max === 'number' && this.props.max > 0) {
			this._limitInputValue(true); // Limit Input value
		}
		// Run the callback function if available
		if (typeof this.props.onChange === 'function') {
			this.props.onChange(event);
		}
	};

	render() {
		let classes = classNames('ui', this.props.classes, this.props.variation, 'input', {
				'popup-open': this.props.tooltip
			}),
			childrenLeft = classes.indexOf('left') !== -1,
			locked = classes.indexOf('locked') !== -1,
			inputClasses = classNames(this.props.inputClasses);
		return (
			<div className={classes} {...this.props.tooltip} data-testid={this.props.testID || Input.name}>
				{childrenLeft ? this.props.children : null}
				<input
					ref={this._input}
					name={this.props.name}
					type={this.props.type}
					value={this.props.value}
					placeholder={this.props.placeholder}
					defaultValue={this.props.defaultValue}
					autoComplete={this.props.autoComplete}
					readOnly={this.props.readOnly || locked}
					autoFocus={this.props.autoFocus}
					className={inputClasses || undefined}
					{...this.props.dataAttributes}
					onFocus={this.props.onFocus}
					onChange={this._handleInputChange}
				/>
				{this._getIsLimitValueActive() ? ( // eslint-disable-line
					<Label ref={(ref) => (this._label = ref ? ref.ref : null)} />
				) : null}
				{childrenLeft ? null : this.props.children}
			</div>
		);
	}
}
