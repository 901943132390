/**
 * @prettier
 * @flow
 */

import { Dropdown, Popup } from 'liana-ui/components/';
import { Size } from 'liana-ui/types/';
import type { Params } from './Dropdown';

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/dropdown/ */
component Component(
	/** A timezone dropdown must have an input name */
	name: string,
	/**
		A timezone dropdown must have standars Liana®Cloud languages as options.
		DATA[json/timezones/timezones.json]
	*/
	timezones: Array<{
		name: string,
		offset: number,
		text: string
	}>,
	/** Initial value or value array if multiple. Use for uncontrolled components only. */
	defaultValue?: string | Array<string>,
	/** Current value or value array if multiple. Use for controlled components only. */
	value?: string | Array<string>,
	/**
		An timezone dropdown can have a placeholder text.
		PROPS[React.Node=/localization/]
	*/
	placeholder?: React.Node,
	/** A timezone dropdown can allow multiple selections. */
	multiple?: boolean = false,
	/** A timezone dropdown can be searchable. */
	search?: boolean = true,
	/** A tiimezone dropdown can be clearable. */
	clearable?: boolean = false,
	/** A timezone dropdown can take the full width of its parent. */
	fluid?: boolean = true,
	/** A timezone dropdown can be locked to indicate that the field is in use but can not be edited. */
	locked?: boolean = false,
	/** A timezone dropdown can be  disabled. */
	disabled?: boolean = false,
	/** A timezone dropdown can be different size. */
	size?: Size,
	/**
		Popup text or, react-intl coomponent or object of properties for Popup component.
		PROPS[React.Node=/language/localisation/, PopupProps=/components/modals/popup/]
	*/
	popup?: React.Node | React.PropsOf<Popup>,
	/** Test ID for testing */
	testID: string = 'TimezoneDropdown',
	/** Function called on input change. */
	onChange?: (event: SyntheticEvent<>, data: Params) => void
) {
	const getOptions = () => {
		let options: any = [];
		if (timezones) {
			timezones.map((option) => {
				let text = option.text;
				options.push({
					text: `${text.substr(0, text.indexOf(' '))} ${text.substr(text.indexOf(' ') + 1)}`,
					value: option.name
				});
			});
		}
		return options;
	};

	let dropdown = (
		<Dropdown
			name={name}
			defaultValue={defaultValue}
			value={value}
			placeholder={placeholder}
			options={getOptions()}
			fluid={fluid}
			multiple={multiple}
			search={search}
			clearable={clearable}
			locked={locked}
			disabled={disabled}
			size={size}
			popup={popup}
			testID={testID}
			onChange={onChange}
		/>
	);

	return dropdown;
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
