/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import Icon from '../icon/Icon';
import FormActions from '../form-actions/FormActions';

import 'fomantic-ui/definitions/modules/modal';
import 'fomantic-ui/definitions/modules/dimmer';
import 'fomantic-ui/definitions/modules/transition';

type Settings = {
	closable: boolean,
	allowMultiple: boolean,
	dimmerSettings: any,
	duration: number,
	autofocus: boolean,
	keyboardShortcuts: boolean,
	observeChanges: boolean,
	refresh: boolean,
	onShow: () => void,
	onHide: (event: SyntheticEvent<>) => any,
	onVisible: () => void,
	onHidden: () => any
};

type Props = {
	/** Content to place inside .ui.modal element */
	children?: React.Node,
	/** Classes for .ui.modal element */
	classes?: string,
	/** Classes for .ui.modal .content element */
	contentClasses: string,
	/** Modal header node */
	header?: React.Node,
	/** Modal control node(s) */
	actions?: React.Node,
	/** Modal tool node(s) */
	tools?: React.Node,
	/** Allow this modal to stack */
	allowMultiple?: boolean,
	/** Make modal fullscreen */
	fullscreen?: boolean,
	/** Make modal basic overlay */
	overlay?: boolean,
	/** Make modal content scrolling instead of page */
	scrolling?: boolean,
	/** Use if modal content is an iframe */
	iframe?: boolean,
	/** Attach modal to the top of the screen instead centering */
	positionTop?: boolean,
	/** Additional Fomantic UI settings */
	settings?: $Shape<Settings>,
	/** Modal size CSS-class */
	size?: string, // TODO: Update size property from modals to classes property, and remove size from properties
	/** Show or hide this Modal */
	show: boolean,
	/** Automatically clean up the Modal Portal */
	removePortal: boolean,
	/** Callback to execute before Modal is shown */
	onShow: () => mixed,
	/** Callback to execute before Modal is hidden */
	onHide: (event: SyntheticEvent<>) => mixed,
	/** Callback to execute after Modal is hidden */
	onHidden: () => mixed
};

const PORTAL_ID = 'app-portal';

/** COMPONENT BASED ON: hhttps://fomantic-ui.com/modules/modal.html */
export default class Modal extends React.PureComponent<Props> {
	$modal: JQuery;
	$closeBtn: JQuery;
	_setModal: (ref: ?HTMLDivElement) => void;

	constructor(props: Props) {
		super(props);
		this.portal = document.getElementById(PORTAL_ID);

		if (!this.portal) {
			this.portal = document.createElement('div');
			this.portal.id = PORTAL_ID;
			document.body.appendChild(this.portal);
		}

		this.$modal = null;
		this.$closeBtn = null;
		this._setModal = (ref) => (this.$modal = $(ref));
	}

	static defaultProps = {
		show: false,
		removePortal: true,
		fullscreen: false,
		overlay: false,
		scrolling: false,
		iframe: false,
		contentClasses: '',
		allowMultiple: false,
		positionTop: false,
		settings: {
			inverted: false,
			allowMultiple: false,
			duration: 300,
			autofocus: false,
			keyboardShortcuts: true,
			observeChanges: true,
			refresh: false,
			dimmerSettings: {
				template: {
					dimmer: () => $('#app-portal').attr('class', 'ui dimmer')
				}
			}
		},
		onShow: () => {},
		onHide: () => {},
		onHidden: () => {}
	};

	mergeSettings = () => {
		let functions = {
			onShow: () => this.props.onShow(),
			onHide: (event: SyntheticEvent<>) => this.props.onHide(event),
			onHidden: () => this.props.onHidden()
		};

		return Object.assign(
			{},
			Modal.defaultProps.settings,
			functions,
			this.props.settings, // Duplicated by Modal.defaultProps.settings if not set
			{
				// Props settings overrides
				centered: !this.props.positionTop,
				allowMultiple: this.props.allowMultiple,
				closable: !this.props.overlay
			}
		);
	};

	componentDidMount() {
		if (this.$modal) {
			this.$modal.modal(this.mergeSettings());
			if (this.props.show) {
				this.show();
			}
		}
	}

	componentDidUpdate(prevProps: Props) {
		if (this.props.show !== prevProps.show) {
			if (this.props.show === false) {
				this.hide();
			} else if (this.props.show === true) {
				this.show();
			}
		}
	}

	componentWillUnmount() {
		if (this.$modal) {
			window.removeEventListener('resize', this.countContentHeight);
			this.$modal.modal('destroy').remove();
			this.$modal = null;
		}
		if (this.portal && this.props.removePortal === true) {
			this.portal.remove();
			this.portal = null;
		}
	}

	//Count content area height when "fullscreen overlay"
	countContentHeight = () => {
		if ((this.props.scrolling || this.props.iframe) && new String(this.props.classes).indexOf('overlay') === -1) {
			let windowHeight = $(window).height() - 45;
			let headerHeight = this.$modal.find('> .header').outerHeight() || 0;
			let toolsHeight = this.$modal.find('> .tools').outerHeight() || 0;
			let actionsHeight = this.$modal.find('> .actions').outerHeight() || 0;
			let bottomBarHeight = $('body[data-bar="true"]').length > 0 ? 44 : 0;
			let contentHeight = windowHeight - (headerHeight + toolsHeight + actionsHeight + bottomBarHeight);
			if (this.props.scrolling) {
				this.$modal.find('> .content').innerHeight(contentHeight);
			} else if (this.props.iframe) {
				this.$modal.find('> .content iframe').height(contentHeight);
			}
			window.addEventListener('resize', this.countContentHeight);
		}
	};

	// Public function: Show this modal
	show = () => {
		// FUI fix
		if (this.props.overlay) {
			$('body').addClass('inverted-overlay');
		} else {
			$('body').removeClass('inverted-overlay');
		}
		this.$modal.modal('show');
		this.countContentHeight();
	};

	// Public function: Hide this modal
	hide = () => {
		this.$modal.modal('hide');
		window.removeEventListener('resize', this.countContentHeight);
	};

	render() {
		let classes = classNames('ui modal', this.props.size, this.props.classes, {
			fullscreen: this.props.fullscreen,
			basic: this.props.overlay,
			tools: this.props.tools
		});
		let contentClasses = classNames('content', this.props.contentClasses, {
			scrolling: this.props.scrolling,
			iframe: this.props.iframe,
			'no-actions': !this.props.actions
		});
		let modal = (
			<div>
				<div className={classes} ref={this._setModal} data-testid={this.props.testID || Modal.name}>
					{!this.props.overlay ? (
						<Icon classes='close close-modal' ref={(ref) => (this.$closeBtn = $(ref))} />
					) : null}
					{!this.props.overlay && this.props.header ? (
						<div className='header overflow-ellipsis'>{this.props.header}</div>
					) : null}
					{this.props.tools ? <div className='tools'>{this.props.tools}</div> : null}
					<div className={contentClasses}>{this.props.children}</div>
					{this.props.actions ? (
						this.props.actions && this.props.actions.type && this.props.actions.type === FormActions ? (
							this.props.actions
						) : (
							<div className='actions'>{this.props.actions}</div>
						)
					) : null}
				</div>
			</div>
		);
		return ReactDOM.createPortal(modal, this.portal);
	}
}
