/**
 * @prettier
 * @flow
 */

import { FormattedDate, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';
import { Icon, Divider, Segment, Message, Header, Label, Popup, Visibility, LabelGroup } from 'liana-ui/components/';
import { Size, Spacing } from 'liana-ui/types';
import type { Notification } from '../TopPanel';

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/dropdown/ and https://react.semantic-ui.com/collections/menu/ */
component Component(
	/** If is open */
	open: boolean = false,
	/** Notification menu data. Array of notification objects: DATA[json/notifications/notifications.json] */
	notifications?: Array<Notification>,
	/** Position, with and heigh of dropdown menu */
	style?: { [string]: string | number },
	/** Object of properties for Popup */
	popup?: React.PropsOf<Popup>,
	/** Called on menu open. */
	onOpen?: (
		event: SyntheticEvent<>,
		data: {
			id: string
		}
	) => void,
	/** Called on menu close. */
	onClose?: (
		event: Event,
		data: {
			id: string
		}
	) => void
) {
	// Render nothing if empty
	if (!notifications) {
		return null;
	}

	const ID = 'notification';
	const AMOUNT = 5;
	let viewed = [];

	const isMobile =
		document.querySelector('html')?.classList.contains('mobile') ||
		document.querySelector('html')?.classList.contains('tablet');

	const handleOpen = (event: SyntheticEvent<>, data: any) => {
		if (typeof onOpen === 'function') {
			onOpen(event, handleCallbackData(data));
		}
	};

	const handleClose = (event: Event, data: any) => {
		if (typeof onClose === 'function') {
			onClose(event, handleCallbackData(data));
		}
	};

	const handleViewed = (id: string) => {
		if (open) {
			viewed.push(id);
		}
	};

	// Handle data returned callback.
	const handleCallbackData = (data: any) => ({
		id: data.id
	});

	const getAllUnviewed = () => {
		let unviewed = 0;
		notifications.map((notification) => {
			unviewed += notification.viewed ? 0 : 1;
		});
		return unviewed;
	};

	const getRestUnviewed = () => {
		let unviewed = 0;
		notifications.map((notification, i) => {
			if (i >= AMOUNT) {
				unviewed += notification.viewed ? 0 : 1;
			}
		});
		return unviewed;
	};

	const getLabels = (notification: Notification) => {
		let labels = [
			{
				text: (
					<FormattedDate
						value={notification.publish_time}
						weekday='short'
						day='numeric'
						month='short'
						year='numeric'
					/>
				)
			}
		];
		if (!notification.viewed) {
			labels.push({
				text: <FormattedMessage id='component.notification-menu.new' />,
				circular: true,
				notification: true
			});
		}
		return labels;
	};

	const getNotifications = () => {
		let ret = [];
		if (notifications.length > 0) {
			notifications.map((notification) => {
				if (ret.length < AMOUNT) {
					ret.push(
						<>
							<Dropdown.Item
								as={Link}
								className={notification.viewed ? 'viewed' : undefined}
								to={`/notifications#notification${notification.id || ''}`}
							>
								<Visibility onTopVisible={() => handleViewed(notification.id || '')}>
									<Header as='h4' text={notification.title} />
									<p>
										<LabelGroup labels={getLabels(notification)} />
									</p>
									<p>{notification.summary}</p>
								</Visibility>
							</Dropdown.Item>
							<Divider removeMargins={Spacing.All} />
						</>
					);
				}
			});

			if (notifications.length > AMOUNT) {
				ret.push(
					<Dropdown.Item
						as={Link}
						icon='arrow right'
						to='notifications'
						content={
							<span>
								<FormattedMessage id='component.notification-menu.view_all' />
								{unviewedRest ? (
									<span>
										&nbsp;
										<Label size={Size.Tiny} notification text={unviewedRest} circular blinking />
									</span>
								) : null}
							</span>
						}
					/>
				);
			}
		} else {
			ret.push(
				<Segment basic>
					<Message
						info
						content={<FormattedMessage id='component.notification-menu.noneFoundContent' />}
						icon='fa-circle-info'
					/>
				</Segment>
			);
		}
		return ret;
	};

	let unviewedAll = getAllUnviewed();
	let unviewedRest = getRestUnviewed();

	let menu = (
		<Dropdown
			id={`${ID}Menu`}
			tabIndex={false}
			closeOnBlur={false}
			open={open}
			item
			icon={
				<Icon
					name={isMobile && open ? 'fa-close' : 'fa-bell'}
					size={isMobile && open ? undefined : Size.Large}
					circular={isMobile && open ? true : false}
					notification={unviewedAll}
				/>
			}
			onOpen={handleOpen}
			onClose={handleClose}
		>
			<Dropdown.Menu id={`${ID}Dropdown`} style={style}>
				{open ? getNotifications() : null}
			</Dropdown.Menu>
		</Dropdown>
	);

	return !open
		? // $FlowIssue - React statics; Attach popup
			Popup.attach({ text: <FormattedMessage id='component.notification-menu.name' />, ...popup }, menu)
		: menu;
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
