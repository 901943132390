/**
 * @prettier
 * @flow
 */

import { Grid, Responsive, GridColumn } from 'liana-ui/components/';
import { Device } from 'liana-ui/types/';
import SuggestedItem from './src/SuggestedItem';

type Item = {
	link: string,
	type: string,
	header: React.Node,
	subheader: React.Node,
	subinfo: React.Node | Array<React.Node>,
	content: React.Node
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/collections/grid/ */
component Component(
	/** A suggested items can have clickable items. */
	items: Array<Item>,
	/** Amount of items to display on mobile. */
	mobileAmount?: number = 1,
	/** Amount of items to display on tablet. */
	tabletAmount?: number = 2,
	/** Amount of items to display on computer. */
	computerAmount?: number = 3,
	/** Amount of items to display on largescreen. */
	largescreenAmount?: number = 4,
	/** Amount of items to display on widescreen. */
	widescreenAmount?: number = 6,
	/** Test ID for testing */
	testID: string = 'SuggestedItems'
) {
	const getColumns = (amount: number = 0) => {
		let columns = [];
		if (items.length < amount) {
			amount = items.length;
		}
		if (items && Array.isArray(items)) {
			for (let i = 0; i < amount; i++) {
				columns.push(getColumn(items[i], i));
			}
		}
		return columns;
	};
	const getColumn = (item: Item, index: number) => {
		return (
			<GridColumn key={`column${index}`}>
				<SuggestedItem
					link={item.link}
					header={item.header}
					subheader={item.subheader}
					subinfo={item.subinfo}
					content={item.content}
					type={item.type}
				/>
			</GridColumn>
		);
	};

	let menu = (
		<div className='suggesteditems-wrapper' data-testID={testID}>
			<Grid stretched columns='equal'>
				<Responsive minDevice={Device.Mobile} maxDevice={Device.Mobile}>
					{getColumns(mobileAmount)}
				</Responsive>
				<Responsive minDevice={Device.Tablet} maxDevice={Device.Tablet}>
					{getColumns(tabletAmount)}
				</Responsive>
				<Responsive minDevice={Device.Computer} maxDevice={Device.Computer}>
					{getColumns(computerAmount)}
				</Responsive>
				<Responsive minDevice={Device.LargeScreen} maxDevice={Device.LargeScreen}>
					{getColumns(largescreenAmount)}
				</Responsive>
				<Responsive minDevice={Device.WideScreen} maxDevice={Device.WideScreen}>
					{getColumns(widescreenAmount)}
				</Responsive>
			</Grid>
		</div>
	);

	return menu;
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
