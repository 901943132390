/**
 * @prettier
 * @flow
 */
import { ListDescription } from 'semantic-ui-react';

component Component(
	/** Detail as children. */
	children: React.Node
) {
	return <ListDescription>{children}</ListDescription>;
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
