/**
 * @prettier
 * @flow
 */

import { Header, Grid, Transition } from 'liana-ui/components';
import type { Props as HeaderProps } from 'liana-ui/components/header/Header';

// prettier-ignore
type Props = {
	/**
		A form section must have have a header.
		PROPS[HeaderProps=/components/texts/header/]
	*/
	header: HeaderProps,
	/** A form section must have fields in the right column. */
	fields: React.Node,
	/** A form section can have section instructions in the left column. */
	instructions?: React.Node,
	/** A form section can be visible or hidden. */
	visible: boolean,
};

const DEFAULTS = {
	visible: true
};

// FIXME: No root component to attach test IDs
const Component = React.memo<Props>((props: Props) => {
	return (
		<>
			<Header {...props.header} as='h2' size='large' dividing />
			<Transition visible={props.visible} animation='height'>
				{props.instructions ? (
					<Grid columns={2} relaxed='very' stackable>
						<Grid.Column width={4}>{props.instructions}</Grid.Column>
						<Grid.Column width={8}>{props.fields}</Grid.Column>
					</Grid>
				) : (
					props.fields
				)}
			</Transition>
		</>
	);
});

// Documentation generation support
Component.displayName = 'FormSection';
Component.defaultProps = DEFAULTS;

export default Component;
