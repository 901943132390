/**
 * @prettier
 * @flow
 */

import { FormattedMessage } from 'react-intl';
import { Button, Modal, EmptyState } from 'liana-ui/components/';
import { Size } from 'liana-ui/types';

const Translations = {
	modalHeader: 'component.limit-label.modalHeader',
	messageHeader: 'component.limit-label.messageHeader',
	close: 'component.modal.close',
	upgrade: 'component.limit-modal.upgrade'
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/modal/ */
component Component(
	/** Controls whether or not the Modal is displayed. */
	open?: boolean = false,
	/** $FlowIssue - Image. */
	image: string = `${process.env.baseUrl}img/empty-states/upsell-default.png`,
	/** Description text. */
	description?: React.Node = <FormattedMessage id='component.limit-label.messageContent' />,
	/** Upgrade page url */
	upgradeLink?: string,
	/** Custom translations. */
	translations: { [string]: string } = Translations,
	/** Test ID for testing */
	testID: string = 'LimitModal',
	/** Called on modal close. */
	onClose?: (event: SyntheticEvent<>) => void
) {
	// $FlowIssue - Merge translations
	let merged = { ...Translations, ...translations };

	return (
		<Modal
			open={open}
			size={Size.Tiny}
			header={<FormattedMessage id={merged.modalHeader} />}
			onClose={onClose}
			testID={testID}
			content={
				<EmptyState
					image={image}
					header={<FormattedMessage id={merged.messageHeader} />}
					content={description}
				/>
			}
			actions={[
				<Button key='cancel' text={<FormattedMessage id={merged.close} />} type='cancel' onClick={onClose} />,
				<Button
					key='upgrade'
					text={<FormattedMessage id={merged.upgrade} />}
					color='primary'
					icon='fa-arrow-up-right-from-square'
					link={upgradeLink}
				/>
			]}
		/>
	);
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
