/**
 * @prettier
 * @flow
 */

import { useState, Fragment } from 'react';
import { NavLink } from 'liana-ui/components';
import { AccordionAccordion } from 'semantic-ui-react';
import AccordionTitle from './AccordionTitle';
import AccordionContent from './AccordionContent';
import type { Panel } from '../Accordion';

component Component(
	/** Index of the currently active panel. When exclusive is false, defaultActiveIndex should be an array of Strings. */
	defaultActiveIndex?: string | Array<string>,
	/** Array of props for AccordionPanel. Example: [{key: 'panel1', title: 'Panel title', content: 'Panel content', panels: Array<AccordionPanelType> }] */
	panels: Array<Panel>,
	/** Only allow one panel open at a time. */
	exclusive?: boolean = false,
	/** Parent index.  */
	parentIndex: string | number = 0,
	/** Nested level.  */
	level: number = 1,
	/** Set maximum levels of accordion. */
	maxLevels?: number = 0,
	/** Accordion title can vary in size. */
	size?: 'large',
	/** Test ID used for testing. */
	testID: string = 'AccordionAccordion',
	/** Called on link click only. */
	onLinkClick?: (event: SyntheticEvent<>) => void,
	// Do not print out to docs. For testing purposes only
	onAnimationEnd?: (newHeight: number) => void
) {
	// Identical state management to Accordion
	const [activeIndexes, setActiveIndexes] = useState(() => {
		let dfa = defaultActiveIndex
			? Array.isArray(defaultActiveIndex)
				? defaultActiveIndex.map(String)
				: defaultActiveIndex.toString()
			: undefined;
		let dfaState = Array.isArray(dfa) ? (dfa ? dfa : []) : dfa !== undefined ? [dfa] : [];

		return dfaState;
	});

	// $FlowFixMe - Unknown data parameter
	const handleClick = (event: SyntheticEvent<>, data: any) => {
		if (exclusive) {
			setActiveIndexes(data.active ? [] : [data.index]);
		} else {
			setActiveIndexes(
				data.active
					? (activeIndexes) => activeIndexes.filter((activeIndex) => activeIndex !== data.index)
					: [...activeIndexes, data.index]
			);
		}
	};

	const getClasses = (panel: Panel) => {
		let classes = '';
		if (maxLevels && level + 1 === maxLevels) {
			classes = 'hide-icon';
		} else if (((!panel.panels || panel.panels.length < 1) && !panel.content) || level + 1 === maxLevels) {
			classes = 'hide-icon';
		}
		return classes;
	};

	let accordionAccordion = panels ? (
		<AccordionAccordion exclusive={exclusive} className={size} data-testid={testID}>
			{panels.map((panel, index) => {
				let idx = String(`${parentIndex}.${index + 1}`);
				return (
					<Fragment key={idx}>
						<AccordionTitle
							index={idx}
							as={panel.link ? NavLink : undefined}
							to={panel.link ? panel.link : undefined}
							disableLink={panel.panels && panel.panels.length > 0 && level + 1 < maxLevels}
							active={activeIndexes.includes(idx)}
							className={getClasses(panel)}
							labels={panel.labels}
							upsell={panel.upsell}
							onClick={handleClick}
							onLinkClick={onLinkClick}
						>
							{panel.title}
						</AccordionTitle>
						<AccordionContent
							parentIndex={idx}
							defaultActiveIndex={activeIndexes}
							active={activeIndexes.includes(idx)}
							panels={panel.panels}
							level={level + 1}
							maxLevels={maxLevels}
							onLinkClick={onLinkClick}
							onAnimationEnd={onAnimationEnd}
						>
							{panel.content}
						</AccordionContent>
					</Fragment>
				);
			})}
		</AccordionAccordion>
	) : null;

	return accordionAccordion;
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
