/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { Fragment, useState } from 'react';
import { Accordion } from 'semantic-ui-react';
import AccordionTitle from './src/AccordionTitle';
import AccordionContent from './src/AccordionContent';
import AccordionAccordion from './src/AccordionAccordion';
import { Checkbox, Divider, Header, NavLink, Label, Icon, LimitLabel } from 'liana-ui/components';
import { Spacing } from 'liana-ui/types';

type Data = {
	active: boolean,
	index: number
};

type Panel = {
	key: string,
	icon?: string | React.PropsOf<Icon>,
	title?: React.Node,
	actions?: React.Node,
	content?: React.Node,
	divider?: boolean,
	link?: string,
	header?: React.Node,
	upsell?: boolean,
	labels?: Array<React.PropsOf<Label> | React.PropsOf<LimitLabel>>,
	panels?: Array<Panel>,
	testID?: string
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/accordion/ */
component Component(
	children?: React.Node,
	/**
		An accordien one or more have toggleable panels.
		PROPS[React.Node=/language/localisation/, LabelProps=/components/labels/labels/label/]
	*/
	panels: Array<Panel> = [],
	/** Indexes of the currently active panels. */
	defaultActiveIndex?: string | Array<string>,
	/** An aacordien can allow only one panel to be open at a one time. */
	exclusive?: boolean = false,
	/** An accordion arrow icons can work up/down instead or right/down. */
	upDown?: boolean = false,
	/** An accordion can show panels as segments. */
	styled?: boolean = false,
	/** An accordion can have toggles. */
	toggle?: boolean = false,
	/** An accordion can have maximum levels of nested accordions. */
	maxLevels: number = 0,
	/** An accordion can be different size. */
	size?: 'large' | 'big',
	/** Test ID used for testing. */
	testID: string = 'Accordion',
	/** Function called on any click. */
	onClick?: (
		event?: SyntheticEvent<>,
		data: {
			active: boolean,
			index: number,
			activeIndexes: string | Array<string>
		}
	) => void,
	/** Function called on link click only. */
	onLinkClick?: (event: SyntheticEvent<>, data: any) => void,
	/** Fuction called when animation ends and panel is fully open. */
	onAnimationEnd?: (newHeight: number) => void
) {
	// Identical state management to AccordionAccordion
	const [activeIndexes, setActiveIndexes] = useState(() => {
		let dfa = defaultActiveIndex
			? Array.isArray(defaultActiveIndex)
				? defaultActiveIndex.map(String)
				: defaultActiveIndex.toString()
			: undefined;
		let dfaState = Array.isArray(dfa) ? (dfa ? dfa : []) : dfa !== undefined ? [dfa] : [];

		return dfaState;
	});

	const handleClick = (event: SyntheticEvent<>, data: Data) => {
		let active: any;
		if (exclusive) {
			active = data.active ? [] : [String(data.index)];
		} else {
			active = data.active
				? activeIndexes.filter((activeIndex) => activeIndex !== data.index)
				: [...activeIndexes, String(data.index)];
		}
		setActiveIndexes(active);
		if (typeof onClick === 'function') {
			onClick(event, handleCallbackData(data, active));
		}
	};

	const handleLinkClick = (event: SyntheticEvent<>, data: any) => {
		if (typeof onLinkClick === 'function') {
			onLinkClick(event, data);
		}
	};

	const handleAnimationEnd = (newHeight: number) => {
		if (typeof onAnimationEnd === 'function') {
			onAnimationEnd(newHeight);
		}
	};

	// Handle data returned by callbacks.
	const handleCallbackData = (data: Data, active: string | Array<string>) => ({
		active: data.active,
		index: data.index,
		activeIndexes: active
	});

	// Assign classes
	const classes = classNames({
		updown: upDown
	});

	// FIXME: upDown results in the same icon
	const getIcon = (panel: Panel) => {
		return toggle
			? false
			: panel.icon
				? panel.icon
				: upDown
					? { name: 'fa-chevron-right fa-solid' }
					: { name: 'fa-chevron-right fa-solid' };
	};

	let accordion = (
		<Accordion fluid styled={styled} className={classes} data-testid={testID}>
			{children
				? children
				: panels.map((panel, index) => {
						let strIdx = String(index + 1);
						return panel.header ? (
							<Header
								key={strIdx}
								as='h6'
								text={panel.header}
								testID={panel.testID || `Accordion::Header::${strIdx}`}
							/>
						) : panel.divider ? (
							<Divider testID={panel.testID || `Accordion::Divider::${strIdx}`} />
						) : (
							<Fragment key={strIdx}>
								{styled && index + 1 > 1 ? (
									<Divider
										removeMargins={Spacing.All}
										testID={panel.testID || `Accordion::Divider::${strIdx}`}
									/>
								) : null}
								{panel.actions ? <span className='float-right'>{panel.actions}</span> : null}
								<AccordionTitle
									index={strIdx}
									active={activeIndexes.includes(strIdx)}
									as={panel.link ? NavLink : undefined}
									to={panel.link ? panel.link : undefined}
									disableLink={panel.panels && panel.panels.length > 0}
									icon={getIcon(panel)}
									labels={panel.labels}
									upsell={panel.upsell}
									size={size}
									onClick={handleClick}
									onLinkClick={handleLinkClick}
									testID={panel.testID || `Accordion::Title::${strIdx}`}
								>
									{toggle ? (
										<Checkbox
											toggle
											label={panel.title}
											name={panel.key}
											checked={activeIndexes.includes(strIdx)}
										/>
									) : (
										panel.title
									)}
								</AccordionTitle>
								<AccordionContent
									parentIndex={strIdx}
									defaultActiveIndex={activeIndexes}
									active={activeIndexes.includes(strIdx)}
									exclusive={exclusive}
									panels={panel.panels}
									maxLevels={maxLevels}
									onLinkClick={handleLinkClick}
									onAnimationEnd={handleAnimationEnd}
									testID={panel.testID || `Accordion::Content::${strIdx}`}
								>
									{panel.content}
								</AccordionContent>
							</Fragment>
						);
					})}
		</Accordion>
	);

	return accordion;
}

const MemoComponent = (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
// $FlowIssue - Static subcomponents
MemoComponent.Title = AccordionTitle;
// $FlowIssue - Static subcomponents
MemoComponent.Content = AccordionContent;
// $FlowIssue - Static subcomponents
MemoComponent.Accordion = AccordionAccordion;

export type { Panel };

export default MemoComponent;
