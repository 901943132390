/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
require('malihu-custom-scrollbar-plugin-3.1.7/jquery.mCustomScrollbar.js');
require('jquery-mousewheel/jquery.mousewheel');

type Props = {
	/** Content to place inside container element */
	children?: React.Node,
	/** Classes for container element */
	classes?: string,
	/** Height for container element */
	height?: string
};

/** COMPONENT BASED ON: http://manos.malihu.gr/jquery-custom-content-scroller/ */
export default class ScrollContainer extends React.PureComponent<Props> {
	_container: { current: React.ElementRef<'div'> | null };
	constructor(props: Props) {
		super(props);
		this._container = React.createRef();
	}
	componentDidMount() {
		let $container = $(this._container.current);
		let pointerOnly = document.querySelector('html').classList.contains('pointer-device');

		// Dashboard + CustomScrollbars buggy/hard to use on touch. Use only for pointer devices
		if (pointerOnly) {
			$container.mCustomScrollbar({
				live: true,
				theme: 'rounded-dark',
				callbacks: {
					onInit: function () {
						$container.addClass('scrollbars-ready');
					}
				}
			});
		}
	}
	render() {
		let classes = classNames('scroll-container', this.props.classes),
			height = this.props.height ? { height: this.props.height + 'px' } : undefined;
		return (
			<div
				className={classes}
				style={height}
				ref={this._container}
				data-testid={this.props.testID || ScrollContainer.name}
			>
				{this.props.children}
			</div>
		);
	}
}
