/**
 * @prettier
 * @flow
 */

import { NavLink as BaseNavLink } from 'react-router-dom';
import { Target } from 'liana-ui/types/';

// $FlowIgnore - WTF iterator - https://reactrouter.com/en/main/components/nav-link
component Component(
	ref: React.RefSetter<HTMLElement>,
	children: React.Node,
	to?: string,
	href?: string,
	target?: Target,
	rel?: string,
	end?: boolean,
	'aria-current' as ariaCurrent?: 'page' | 'step' | 'location' | 'date' | 'time' | 'true' | 'false',
	caseSensitive?: boolean,
	reloadDocument?: boolean,
	activeClassName?: string,
	activeStyle?: { [string]: string },
	className?: string | (({ isActive: boolean }) => mixed),
	style?: { [string]: string },
	testID?: string = 'NavLink',
	onClick?: () => void
) {
	return (
		<BaseNavLink
			ref={ref}
			to={to}
			href={href}
			target={target}
			rel={rel}
			end={end}
			aria-current={ariaCurrent}
			caseSensitive={caseSensitive}
			reloadDocument={reloadDocument}
			data-testid={testID}
			onClick={onClick}
			className={({ isActive }) => [className, isActive ? activeClassName : null].filter(Boolean).join(' ')}
			style={({ isActive }) => ({
				...style,
				// $FlowIgnore - Flow can't handle spread attributes
				...(isActive ? activeStyle : null)
			})}
		>
			{children}
		</BaseNavLink>
	);
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
