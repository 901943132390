/**
 * @prettier
 * @flow
 */

import { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { Browser } from 'liana-ui/definitions';
import { loadIntl, loadPolyfills, loadLocalData, loadRemoteData, flatten } from './src/Loader';

component Component(
	children: React.Node, // Routes
	language: string = 'en',
	locales: { [string]: string } = { en: 'en-US' },
	url: string,
	path: string
) {
	// https://formatjs.io/docs/react-intl/components#intlprovider
	const [i18n, setTranslations] = useState<{ formats: Array<string> | [], messages: any }>({
		formats: [],
		messages: {}
	});

	// $FlowIgnore - Async hook details missing
	useEffect(async () => {
		let translations;
		if (!global.Intl || Browser.is.IE(true)) {
			loadIntl(language, locales); // Load all relevant polyfills
		}
		// Load actual language data (remotely or locally)
		if (typeof url === 'string' && url.length > 0) {
			translations = await loadRemoteData(language, locales, url);
		} else {
			translations = loadLocalData(language, locales, path);
		}
		setTranslations(translations);

		loadPolyfills(language, locales); // Load all language polyfills

		if (document.documentElement?.getAttribute('lang') !== language) {
			document.documentElement?.setAttribute('lang', language); // Update <html> with language
		}
	}, []);

	return (
		<IntlProvider
			defaultLocale='en'
			key={language}
			locale={language}
			formats={i18n.formats}
			messages={flatten(i18n.messages)}
		>
			{children}
		</IntlProvider>
	);
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
