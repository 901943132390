/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { GridColumn } from 'semantic-ui-react';
import { Responsive } from 'liana-ui/components/';
import { Device, Spacing, HAlign, VAlign, Float } from 'liana-ui/types';

const ELEMENT_SIDES = ['top', 'right', 'bottom', 'left'];

/** COMPONENT BASED ON: https://react.semantic-ui.com/collections/grid/ */
component Component(
	/** Grid column content as children. */
	children: React.Node,
	/** A grid column can have the id attribute */
	id?: string,
	/**
			A grid column can have a width.
			VALUES[1 - 12]
		*/
	width?: number,
	/**
			A column can specify a width for a mobile device.
			VALUES[1 - 12]
		*/
	mobile?: number,
	/**
			A column can specify a width for a tablet device.
			VALUES[1 - 12]
		*/
	tablet?: number,
	/**
			A column can specify a width for a computer.
			VALUES[1 - 12]
		*/
	computer?: number,
	/**
			A column can specify a width for a large screen device.
			VALUES[1 - 12]
		*/
	largescreen?: number,
	/**
			A column can specify a width for a wide screen device.
			VALUES[1 - 12]
		*/
	widescreen?: number,
	/** A column can stretch its contents to take up the entire grid or row height. */
	stretched?: boolean,
	/** A column can take up all possible grid or row width. */
	fluid?: boolean,
	/** A column can collapse to take up only width of content. */
	collapsing?: boolean,
	/** A grid column can specify that its contents should remain on a single line, and not wrap. */
	singleLine?: boolean,
	/** A grid column can break very long strings in tight spaces into multiple lines. */
	breakWord?: boolean,
	/**
			A grid column paddings can be removd around the column.
			VALUES['all' | 'horizontal' | 'vertical' | 'top' | 'right' | 'bottom' | 'left']
		*/
	removePaddings?: string | Array<string>,
	/** A grid column can specify its text alignment. */
	textAlign?: HAlign,
	/** A grid column can specify its vertical alignment to have all its columns vertically aligned. */
	verticalAlign?: VAlign,
	/** A column can sit flush against the left or right edge of a row. */
	floated?: Float,
	/** A column can have different background textures. */
	background?: 'check' | 'grid',
	/** A column can have additional classes. Use for very special features only! */
	className?: string,
	/** Smallest device that component will be displayed with. */
	minDevice?: Device,
	/** Largest device that component will be displayed with. */
	maxDevice?: Device,
	/** Test ID for testing */
	testID: string = 'GridColumn'
) {
	// Function to generate LianaUI GridColumn
	const createColumn = () => {
		// Assign classes
		const classes = classNames(
			className,
			{
				collapsing: collapsing,
				'fluid-column': fluid,
				'overflow-ellipsis': singleLine,
				'break-word': breakWord,
				'background-check': background === 'check',
				'background-grid': background === 'grid'
			},
			getElementClasses(removePaddings)
		);

		return (
			<GridColumn
				id={id}
				className={classes}
				width={width}
				mobile={mobile}
				tablet={tablet}
				computer={computer}
				largeScreen={largescreen}
				widescreen={widescreen}
				stretched={stretched}
				textAlign={textAlign}
				verticalAlign={verticalAlign}
				floated={floated}
				data-testid={testID}
			>
				{children}
			</GridColumn>
		);
	};

	const getElementClasses = (data?: string | Array<string>) => {
		let result: { [string]: boolean } = {},
			name = '';
		if (data) {
			if (Array.isArray(data) && data.length > 0) {
				for (let i = 0; i < ELEMENT_SIDES.length; i++) {
					name = `remove-${ELEMENT_SIDES[i]}-padding`;
					result[name] = data.includes(ELEMENT_SIDES[i]);
				}
			} else if (typeof data === 'string') {
				let spc = Spacing.cast(data);
				if (spc) {
					switch (spc) {
						case Spacing.All:
							result[`remove-paddings`] = true;
							break;
						case Spacing.Horizontal:
						case Spacing.Vertical:
							result[`remove-${data}-paddings`] = true;
							break;
						case Spacing.Top:
						case Spacing.Bottom:
						case Spacing.Left:
						case Spacing.Right:
							result[`remove-${data}-padding`] = true;
							break;
					}
				}
			}
		}
		return result;
	};

	// Display reponsively
	let column =
		minDevice || maxDevice ? (
			<Responsive minDevice={minDevice} maxDevice={maxDevice}>
				{createColumn()}
			</Responsive>
		) : (
			createColumn()
		);

	return column;
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
