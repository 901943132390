/**
 * @prettier
 * @flow
 */

import { Step as SUIRStep } from 'semantic-ui-react';
import Step from './src/Step';

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/step/#groups-ordered */
component Component(
	/**
		A steps must have series of activities.
		PROPS[React.Node=/localization/]
	*/
	steps: Array<React.PropsOf<Step>>,
	/** Test ID for testing. */
	testID: string = 'Steps'
) {
	return (
		<SUIRStep.Group size='small' ordered fluid widths={steps ? steps.length : null} data-testid={testID}>
			{steps
				? steps.map((step, index) => (
						<Step key={`step-${index}`} {...step} testID={step.testID || `Steps::Step::${index}`} />
					))
				: null}
		</SUIRStep.Group>
	);
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
