/**
 * @prettier
 * @flow
 */
import { ListContent } from 'semantic-ui-react';

component Component(
	/** Detail as children. */
	children: React.Node,
	/** An list content can be floated left or right. */
	floated?: 'left' | 'right'
) {
	return <ListContent floated={floated}>{children}</ListContent>;
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
