/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import type { Tooltip } from 'liana-ui/definitions/component/Types';

type Props = {
	/** Classes for .ui.icon element */
	classes?: string,
	/** Content to place inside .ui.icon element */
	children?: React.Node,
	/** Type of the icon (default, color) */
	type?: 'default' | 'color',
	/** Background color if type is set to "color" */
	color?: string,
	/** HTML data attrributes for .ui.icon element */
	dataAttributes?: { [string]: string },
	/** Tooltip (data-content, data-position, data-delay) */
	tooltip?: $Shape<Tooltip>,
	/** onClick -handler */
	onClick?: (event: SyntheticEvent<>) => mixed
};

/** COMPONENT BASED ON: https://fomantic-ui.com/elements/icon.html */
export default class Icon extends React.PureComponent<Props> {
	_icon: { current: React.ElementRef<'i'> | null };
	constructor(props: Props) {
		super(props);
		this._icon = React.createRef();
	}
	static defaultProps = {
		type: 'default'
	};
	get ref() {
		return this._icon.current ? this._icon.current : null;
	}
	render() {
		let classes = classNames(
				{
					icon: true,
					color: this.props.type === 'color',
					'popup-open': this.props.tooltip
				},
				this.props.classes
			),
			styles = this.props.type === 'color' ? { backgroundColor: this.props.color } : null;
		return (
			<i
				ref={this._icon}
				className={classes}
				onClick={this.props.onClick}
				{...this.props.dataAttributes}
				{...this.props.tooltip}
				style={styles}
				data-testid={this.props.testID || Icon.name}
			>
				{this.props.children}
			</i>
		);
	}
}
