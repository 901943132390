/**
 * @prettier
 * @flow
 */

import { ModalHeader } from 'semantic-ui-react';

component Component(children: React.Node, testID: string = 'ModalHeader') {
	return (
		<ModalHeader data-testid={testID} className='overflow-ellipsis'>
			{children}
		</ModalHeader>
	);
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
