/**
 * @prettier
 * @flow
 */

import { useEffect, useRef } from 'react';

/** COMPONENT BASED ON: http://gridstackjs.com/ */
component Component(
	/** Content to place inside .grid-stack-item element */
	children: React.Node,
	/** ID of dashboard item */
	id?: number | string,
	/** Y position of item in grid */
	x?: number | string,
	/** Y position of item in grid */
	y?: number | string,
	/** Width of item in grid */
	width?: number | string = 1,
	/** Height of item in grid */
	height?: number | string = 1,
	/** Widgets max width */
	maxWidth?: number | string,
	/** Widgets max height */
	maxHeight?: number | string,
	/** Widgets min width */
	minWidth?: number | string,
	/** Widgets min height */
	minHeight?: number | string,
	/** Test ID for testing. */
	testID: string = 'DashboardItem'
) {
	const ref = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		const grid = document.querySelector('.grid-stack');
		if (grid && 'gridstack' in grid) {
			// $FlowIssue[prop-missing] - Already checked above
			grid.gridstack.makeWidget(ref.current);
		}
	}, []);

	return (
		<div
			ref={ref}
			className='grid-stack-item widget'
			data-gs-id={id}
			data-gs-x={x}
			data-gs-y={y}
			data-gs-width={width}
			data-gs-height={height}
			data-gs-auto-position={!x && !y}
			data-gs-max-width={maxWidth}
			data-gs-max-height={maxHeight}
			data-gs-min-width={minWidth}
			data-gs-min-height={minHeight}
			data-testid={testID}
		>
			{children}
		</div>
	);
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
