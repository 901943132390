/**
 * @prettier
 * @flow
 */

import { StepTitle } from 'semantic-ui-react';

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/step/#groups-ordered */
component Component(
	/** Title as string. */
	text: React.Node,
	/** Test ID for testing. */
	testID: string = 'StepTitle'
) {
	return <StepTitle data-testid={testID}>{text}</StepTitle>;
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
