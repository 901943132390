/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { Table } from 'semantic-ui-react';
import { Transition, Responsive, Visibility } from 'liana-ui/components/';
import { Device } from 'liana-ui/types';

/** COMPONENT BASED ON: https://react.semantic-ui.com/collections/table/ */
component Component(
	/** TODO: Undocumented prop */
	className?: string,
	/** Key */
	reactKey?: string,
	/** ID */
	id?: string,
	/** Primary content. */
	children?: React.Node,
	/** A row can be active or selected by a user. */
	active: boolean = false,
	/** A row can glow when added. */
	added: boolean = false,
	/** A row can animate (fly away) when deleted. */
	deleted: boolean = false,
	/** A row can appear to be off. */
	off: boolean = false,
	/** A row can be disabled. */
	disabled: boolean = false,
	/** TODO: Undocumented prop */
	hideTouch?: boolean,
	/** Smallest device that cell will be displayed with. */
	minDevice?: Device,
	/** Largest device that cell will be displayed with. */
	maxDevice?: Device,
	/** Test ID for testing. */
	testID: string = 'TableRow',
	/** Rows's top edge has passed bottom of screen. */
	onTopVisible?: (id?: number | string) => void,
	/** Rows's bottom edge has passed bottom of screen. */
	onBottomVisible?: (id?: number | string) => void,
	/** Callback on when delete animation ends. */
	onAfterDeleteRows?: () => void,
	/** Callback on click. */
	onClick?: () => void
) {
	// Assign classes
	const classes = classNames(
		{
			off: off
		},
		className
	);

	// Delete transition
	let visible = deleted ? !deleted : true,
		transitionOnMount = deleted ? false : added ? true : undefined,
		animation = deleted ? 'fly left' : added ? 'glow' : null,
		duration = deleted ? 500 : added ? 1000 : 0,
		onHide = deleted ? onAfterDeleteRows : undefined,
		style = deleted ? { background: 'yellow' } : undefined;

	// Define row
	let row: any = (
		<Transition
			visible={visible}
			transitionOnMount={transitionOnMount}
			unmountOnHide={true}
			animation={animation}
			duration={duration}
			onHide={onHide}
			reactKey={`animation-${reactKey || ''}`}
		>
			<Table.Row
				data-testid={testID}
				style={style}
				active={active}
				disabled={disabled}
				className={classes}
				key={reactKey}
				onClick={onClick}
			>
				{children}
			</Table.Row>
		</Transition>
	);

	// Display reponsively
	row =
		minDevice || maxDevice || hideTouch ? (
			<Responsive minDevice={minDevice} maxDevice={maxDevice} hideTouch={hideTouch}>
				{row}
			</Responsive>
		) : (
			row
		);

	// Visibility
	if (typeof onTopVisible === 'function' || typeof onBottomVisible === 'function') {
		row = (
			<Visibility
				onTopVisible={typeof onTopVisible === 'function' ? () => onTopVisible(id) : undefined}
				onBottomVisible={typeof onBottomVisible === 'function' ? () => onBottomVisible(id) : undefined}
			>
				{row}
			</Visibility>
		);
	}

	return row;
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
