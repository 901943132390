/* istanbul ignore file */

import { createMedia } from '@artsy/fresnel';
import Breakpoints from 'liana-ui/definitions/browser/Constants';

const AppMedia = createMedia({
	breakpoints: Breakpoints
});

export const { Media, MediaContextProvider, createMediaStyle } = AppMedia;
