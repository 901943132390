/**
 * @prettier
 * @flow
 */

import { LabelDetail } from 'semantic-ui-react';
import { Icon, Popup } from 'liana-ui/components/';

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/label */
component Component(
	/** Detail content. */
	content: React.Node,
	/** Icon name or object of properties for Icon. */
	icon?: React.PropsOf<Icon>,
	/* Additional classes. */
	className?: string,
	/** Popup text or object of properties for Popup */
	popup?: React.Node | React.PropsOf<Popup>,
	/** Called on click. */
	onClick?: (event: SyntheticEvent<>) => void
) {
	// Called on checkbox click.
	const handleClick = (event: SyntheticEvent<>) => {
		if (typeof onClick === 'function') {
			onClick(event);
		}
	};

	let detail =
		icon || content ? (
			<LabelDetail as={onClick ? 'a' : undefined} className={className} onClick={handleClick}>
				{icon ? <Icon {...icon} /> : null}
				{content}
			</LabelDetail>
		) : null;

	// $FlowIssue - React statics; Attach popup
	return Popup.attach(popup, detail);
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
