/**
 * @prettier
 * @flow
 */

import { PopupContent } from 'semantic-ui-react';

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/popup/ */
component Component(
	/** Content as children. */
	children: React.Node,
	/** Test ID for testing. */
	testID: string = 'PopupContent'
) {
	return <PopupContent data-testid={testID} content={children} />;
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
