/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import Validate from 'liana-ui/definitions/component/Validate';
import { Segment } from 'semantic-ui-react';
import SegmentGroup from './SegmentGroup';
import SegmentInline from './SegmentInline';
import { Popup, Responsive, Loader, Label } from 'liana-ui/components/';
import { Size, Spacing, Device, HAlign } from 'liana-ui/types';

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/segment/ */
component Component(
	/** Segment content as children. */
	children: React.Node,
	/** A segment can have the id attribute */
	id?: string,
	/** Segment can be a link. Opens absolute links in new browser tab and internal links via router. */
	link?: string,
	/** A basic segment has no special formatting. */
	basic?: boolean = false,
	/** A segment can be formatted to raise above the page. */
	raised?: boolean = false,
	/** A segment can be formatted to lower below the page. */
	lowered?: boolean = false,
	/** A segment can be formatted to be selected. */
	selected?: boolean = false,
	/** A segment can be formatted to contain error. */
	error?: boolean = false,
	/** A segment can be formatted to appear less noticeable. */
	secondary?: boolean = false,
	/** A segment can be formatted to appear viewed. */
	viewed?: boolean = false,
	/** A segment can have its colors inverted for contrast. */
	inverted?: boolean = false,
	/** A segment content area can be more noticable. */
	joined?: boolean = false,
	/** A segment can be hoverable. */
	hoverable?: boolean = false,
	/** A segment can show its content is unnecessary to be interacted with. */
	off?: boolean = false,
	/** A segment can be locked to indicate that the content is in use but can not be edited. */
	locked?: boolean = false,
	/** A segment can show its content is loading. Use 'scrolling' to make loader scroll with users screen to keep it always visible. */
	loading?: boolean | 'scrolling' = false,
	/** A segment can have different background textures. */
	background?: 'check' | 'grid' | 'upsell',
	/** A segment can have different color. */
	color?: 'blue' | 'green' | 'yellow' | 'red',
	/** A segment text can be aligned. */
	textAlign?: HAlign,
	/** A streched segment can specify its vertical alignment to have all content vertically aligned. */
	verticalAlign?: 'middle',
	/** A segment can format content to be aligned vertically. */
	vertical?: boolean = false,
	/** A segment can be compressed to have less padding. */
	compressed?: boolean | 'very' = false,
	/** A segment can increase its padding. */
	padded?: boolean | 'very' = false,
	/** A segment can take up only as much space as is necessary for content. */
	compact?: boolean | 'very' = false,
	/** A segment can be totally squared without any border radius. */
	squared?: boolean = false,
	/** A segment can indicate it contains multiple stacked segments. */
	stacked?: boolean = false,
	/** A segment can be used to reserve space for conditionally displayed content. */
	placeholder?: boolean = false,
	/** A segment can be pointing to other content. */
	pointing?: 'top' | 'bottom',
	/** A segment can be attached to other content. */
	attached?: 'top' | 'bottom',
	/**
			A segment margins can be removd around the segment.
			VALUES['all' | 'horizontal' | 'vertical' | 'top' | 'right' | 'bottom' | 'left']
		*/
	removeMargins?: Spacing | Array<Spacing>,
	/**
			A segment paddings can be removd around the segment.
			VALUES['all' | 'horizontal' | 'vertical' | 'top' | 'right' | 'bottom' | 'left']
		*/
	removePaddings?: Spacing | Array<Spacing>,
	/** A segment can scroll overflowing content horizontally. */
	scrollOverflow?: boolean | 'x' | 'y' = false,
	/** A segment can have additional classes. Use for very special features only! */
	className?: string,
	/** A segment can be different size. */
	size?: Size,
	/** Smallest device that component will be displayed with. */
	minDevice?: Device,
	/** Largest device that component will be displayed with. */
	maxDevice?: Device,
	/** Hide content on touch devices */
	hideTouch?: boolean = false,
	/**
			Popup text or, react-intl coomponent or object of properties for Popup component.
			PROPS[React.Node=/language/localisation/, PopupProps=/components/modals/popup/]
		*/
	popup?: React.Node | React.PropsOf<Popup>,
	/** Hard-coded styles */
	style?: { [string]: string | number },
	/** Test ID for testing */
	testID?: string = 'Segment',
	/** Function called on segment click. */
	onClick?: (event: SyntheticEvent<>) => void
) {
	const navigate = useNavigate();
	// Get link type
	let linkType = Validate.linkType(link);

	const getElementClasses = (type: 'margin' | 'padding', data?: Spacing | Array<Spacing>) => {
		let result: { [string]: boolean } = {},
			name = '';

		if (data) {
			if (Array.isArray(data) && data.length > 0) {
				for (let i = 0; i < data.length; i++) {
					if (data[i] === Spacing.All) {
						continue;
					}
					name =
						data[i] === Spacing.Horizontal || data[i] === Spacing.Vertical
							? `remove-${String(data[i])}-${type}s`
							: `remove-${String(data[i])}-${type}`;
					if (Spacing.isValid(data[i])) {
						result[name] = true;
					}
				}
			} else if (!Array.isArray(data) && Spacing.isValid(data)) {
				const val = data as string;
				switch (data) {
					case Spacing.All:
						name = `remove-${type}s`;
						result[name] = true;
						break;
					case Spacing.Horizontal:
					case Spacing.Vertical:
						name = `remove-${val}-${type}s`;
						result[name] = true;
						break;
					case Spacing.Top:
					case Spacing.Right:
					case Spacing.Bottom:
					case Spacing.Left:
						name = `remove-${val}-${type}`;
						result[name] = true;
						break;
				}
			}
		}
		return result;
	};

	const handleClick = (event: SyntheticEvent<HTMLButtonElement>) => {
		// Trigger onClick callback funtion
		if (typeof onClick === 'function') {
			event.preventDefault();
			onClick(event);
		}

		// Trigger internal link
		if (linkType && linkType === 'internal') {
			event.preventDefault();
			if (linkType === 'internal') {
				navigate(link);
			}
		}
	};

	const toStatus = (val: any) => {
		if (Array.isArray(val) && val.length > 0) {
			return val.map((i) => Spacing.cast(i));
		} else if (typeof val === 'string') {
			return Spacing.cast(val) ?? undefined;
		}
	};

	// Function to generate LianaUI Segment
	const createSegment = () => {
		const margins = toStatus(removeMargins);
		const paddings = toStatus(removePaddings);

		// Assign classes
		let classes = classNames(
			{
				off: off,
				locked: locked,
				lowered: lowered,
				hoverable: onClick || link || hoverable,
				selected: selected,
				error: error,
				viewed: viewed,
				joined: joined,
				very: compressed === 'very',
				compressed: compressed,
				squared: squared,
				// $FlowIssue - Handled by classNames
				[`pointing pointing-${pointing}`]: pointing,
				'middle-aligned': verticalAlign === 'middle',
				'background-check': background === 'check',
				'background-grid': background === 'grid',
				'background-upsell': background === 'upsell',
				'overflow-scroll': scrollOverflow && typeof scrollOverflow !== 'string',
				'overflow-scroll-y': scrollOverflow === 'y',
				'overflow-scroll-x': scrollOverflow === 'x'
			},
			getElementClasses('margin', margins),
			getElementClasses('padding', paddings),
			className
		);

		// Define Segment
		let segment = (
			<Segment
				rel={linkType === 'external' ? 'noopener noreferrer' : undefined}
				className={classes}
				style={style}
				as={onClick || link || hoverable ? 'a' : undefined}
				id={id}
				href={link ? link : onClick || hoverable ? '#' : undefined}
				target={linkType === 'external' ? '_blank' : undefined}
				basic={basic}
				raised={raised}
				inverted={Boolean(inverted || background === 'check' || color)}
				color={color}
				secondary={secondary}
				textAlign={textAlign}
				loading={loading === true}
				vertical={vertical}
				padded={padded}
				compact={compact}
				stacked={stacked}
				placeholder={placeholder}
				attached={attached}
				size={size}
				onClick={handleClick}
				data-testid={testID}
			>
				{children}
				{loading === 'scrolling' ? <Loader active dimmer /> : null}
			</Segment>
		);

		// Attach popup. TODO: Currently a segment with an error can not have popup or crashes the app.
		segment = error ? (
			<>
				{segment}
				<Label text={error} pointing='above' color='red' />
			</>
		) : (
			// $FlowIssue - React statics; Attach popup
			Popup.attach(popup, segment)
		);

		return segment;
	};

	// Display reponsively
	let component =
		minDevice || maxDevice || hideTouch ? (
			<Responsive minDevice={minDevice} maxDevice={maxDevice} hideTouch={hideTouch}>
				{createSegment()}
			</Responsive>
		) : (
			createSegment()
		);

	return component;
}

const MemoComponent = (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
// $FlowIssue - Static subcomponents
MemoComponent.Group = SegmentGroup;
// $FlowIssue - Static subcomponents
MemoComponent.Inline = SegmentInline;

export default MemoComponent;
