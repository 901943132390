/**
 * @prettier
 * @flow
 */

import { Grid } from 'liana-ui/components/';
import { GridColumn } from 'liana-ui/components/';
import SegmentMenuItem from './src/SegmentMenuItem';

/** COMPONENT BASED ON: https://react.semantic-ui.com/collections/grid/ */
component Component(
	/**
		A segment menu can have clickable segments.
		DATA[json/segment-menu/segment-menu.json]
		PROPS[LabelProps=/components/labels/labels/label/, IconProps=/components/labels/icons/icon, IconGroupProps=/components/labels/icons/icon-group]
	*/
	segments: Array<React.PropsOf<SegmentMenuItem>>,
	/**
		Represents column count per row in Grid.
		VALUES['equal', 1 - 12]
	*/
	columns?: string | number,
	/** A segment menu can preserve its vertical and horizontal gutters on first and last columns. */
	padded?: boolean | 'horizontally' | 'vertically' = false,
	/** A segment menu can increase its gutters to allow for more negative space. */
	relaxed?: boolean | 'very' = false,
	/**
		A column can specify a width for a mobile device.
		VALUES[1 - 12]
	*/
	mobile?: number = 12,
	/**
		A column can specify a width for a tablet device.
		VALUES[1 - 12]
	*/
	tablet?: number = 6,
	/**
		A column can specify a width for a computer.
		VALUES[1 - 12]
	*/
	computer?: number = 4,
	/**
		A column can specify a width for a large screen device.
		VALUES[1 - 12]
	*/
	largescreen?: number = 3,
	/**
		A column can specify a width for a wide screen device.
		VALUES[1 - 12]
	*/
	widescreen?: number = 3,
	/** Test ID for testing */
	testID: string = 'SegmentMenu'
) {
	let menu = (
		<div className='segmentmenu-wrapper' data-testid={testID}>
			<Grid stretched columns={columns} padded={padded} relaxed={relaxed} stackable={columns !== undefined}>
				{segments && Array.isArray(segments)
					? segments.map((segment, index) => (
							<GridColumn
								key={`column${index}`}
								mobile={!columns ? mobile : undefined}
								tablet={!columns ? tablet : undefined}
								computer={!columns ? computer : undefined}
								largescreen={!columns ? largescreen : undefined}
								widescreen={!columns ? widescreen : undefined}
							>
								<SegmentMenuItem
									link={segment.link}
									header={segment.header}
									icon={segment.icon}
									iconGroup={segment.iconGroup}
									labels={segment.labels}
									content={segment.content}
									onClick={segment.onClick}
									upsell={segment.upsell}
									testID={segment.testID || `SegmentMenu::SegmentMenuItem::${index}`}
								/>
							</GridColumn>
						))
					: null}
			</Grid>
		</div>
	);

	return menu;
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
