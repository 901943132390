/**
 * @prettier
 * @flow
 */

import { Media } from './src/MediaContext';
import { Device } from 'liana-ui/types';

/** COMPONENT BASED ON: https://github.com/artsy/fresnel */
component Component(
	/** Main content */
	children: React.Node,
	/** Smallest device that component will be displayed with. */
	minDevice?: Device,
	/** Largest device that component will be displayed with. */
	maxDevice?: Device,
	/** Hide content on touch devices */
	hideTouch?: boolean = false
) {
	let pointerDevice = document.querySelector('html')?.classList.contains('pointer-device');

	let getMin: () => Device = () => {
		let min = Device.WideScreen;

		if (minDevice) {
			switch (minDevice) {
				case Device.Mobile:
					min = Device.Min;
					break;
				case Device.Tablet:
					min = Device.Mobile;
					break;
				case Device.Computer:
					min = Device.Tablet;
					break;
				case Device.LargeScreen:
					min = Device.Computer;
					break;
				case Device.WideScreen:
					min = Device.LargeScreen;
					break;
				default:
					break;
			}
		}
		return min;
	};

	let between: [Device, Device] | [] = [];
	if (maxDevice && minDevice) {
		between = [getMin(), maxDevice];
	} else if (maxDevice && !minDevice) {
		between = [Device.Min, maxDevice];
	} else if (!maxDevice && minDevice) {
		between = [getMin(), Device.WideScreen];
	}

	return (
		<Media between={between}>
			{(mediaClassNames, renderChildren) => {
				return renderChildren && (!hideTouch || (hideTouch && pointerDevice)) ? children : null;
			}}
		</Media>
	);
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
