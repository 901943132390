/**
 * @prettier
 * @flow
 */

// A utility function to deal with how refs work with SUIR
// https://github.com/Semantic-Org/Semantic-UI-React/issues/3819
// https://github.com/yannickcr/eslint-plugin-react/issues/709

const voidElements = [
	'area',
	'base',
	'br',
	'col',
	'embed',
	'hr',
	'img',
	'input',
	'keygen',
	'link',
	'menuitem',
	'meta',
	'param',
	'source',
	'track',
	'wbr'
];

// A function to handle SUI-R refs
let handleRef = (ref: any = null, name: string = 'ref') => {
	return ref && name
		? (node: any = null) => {
				if (node !== null && typeof node === 'object') {
					if (typeof ref === 'function') {
						ref(node);
					} else if (typeof ref === 'object' && node.hasOwnProperty(name)) {
						ref.current = node[name].current; // current = readOnly, ref = mutable
					}
				}
		  }
		: null;
};

// Attach ref into SUI-R
let withRef =
	(As) =>
	({ forwardedRef, ...props }) => {
		let { children, ...rest } = props;
		return voidElements.indexOf(As) > -1 ? (
			<As ref={forwardedRef} {...rest} />
		) : (
			<As ref={forwardedRef} {...rest}>
				{children}
			</As>
		);
	};

export { handleRef, withRef };
