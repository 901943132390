/**
 * @prettier
 * @flow
 */

import { Modal, Label } from 'liana-ui/components/';
import { Size } from 'liana-ui/types';

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/modal/ */
component Component(
	/** A modal can be open. */
	open?: boolean,
	/** A modal can have a header text. */
	header?: React.Node,
	/** A modal must have content as children. */
	content: React.Node,
	/** Clear label props */
	clearLabelProps?: React.PropsOf<Label>,
	/** A modal can be different size. */
	size?: Size,
	/**	TODO: Undocumented props */
	actions?: React.Node | Array<React.Node>,
	/** Test ID for testing. */
	testID: string = 'FilterModal',
	/** Called on modal close. */
	onClose?: (event: SyntheticEvent<>) => void
) {
	return (
		<Modal
			open={open}
			size={size}
			header={header}
			headerLabels={clearLabelProps ? [clearLabelProps] : undefined}
			content={content}
			actions={actions}
			testID={testID}
			onClose={onClose}
		/>
	);
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
