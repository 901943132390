/**
 * @prettier
 * @flow
 */

const CENTER_OFFSET = 80;

type QueryElement = HTMLElement | null;

const Utils = {
	setIcon(icon: QueryElement, top: number | false) {
		if (icon) {
			// Icon check needed for modal
			icon.style.top = typeof top === 'number' ? `${top}px` : '';
		}
	},
	getPadding(container: QueryElement, useDimmer: boolean = false): number {
		let paddingTop = window.getComputedStyle(container, null).getPropertyValue('padding-top');
		let paddingBottom = window.getComputedStyle(container, null).getPropertyValue('padding-bottom');
		return useDimmer ? 0 : parseFloat(paddingTop) + parseFloat(paddingBottom);
	},
	isTopVisible(el: QueryElement): boolean {
		return el ? el.getBoundingClientRect().top >= 0 : false;
	},
	isBottomVisible(el: QueryElement): boolean {
		return el ? el.getBoundingClientRect().bottom <= window.innerHeight : false;
	},
	isFullyVisible(el: QueryElement): boolean {
		if (el) {
			let top = el.getBoundingClientRect().top,
				bottom = el.getBoundingClientRect().bottom;
			return top >= 0 && bottom <= window.innerHeight;
		}
		return false;
	},
	centerWhenBottomAndTopNotVisible(container: QueryElement, icon: QueryElement, containerPadding: number = 0) {
		if (container) {
			let height = window.innerHeight - container.getBoundingClientRect().top * 2 - containerPadding;
			if (height > 0) {
				Utils.setIcon(icon, height / 2);
			}
		}
	},
	centerWhenBottomNotVisible(container: QueryElement, icon: QueryElement, containerPadding: number = 0) {
		if (container) {
			let height = window.innerHeight - container.getBoundingClientRect().top - containerPadding;
			let iconPadding = icon && icon.offsetHeight > CENTER_OFFSET ? icon.offsetHeight : CENTER_OFFSET;

			if (height > 0 && height > iconPadding) {
				Utils.setIcon(icon, height / 2);
			}
		}
	},
	centerWhenTopNotVisible(container: QueryElement, icon: QueryElement, containerPadding: number = 0) {
		if (container) {
			let height = container.clientHeight - container.getBoundingClientRect().top - containerPadding;
			let iconPadding = icon && icon.offsetHeight > CENTER_OFFSET ? icon.offsetHeight : CENTER_OFFSET;

			if (height > 0 && container.getBoundingClientRect().bottom > iconPadding) {
				Utils.setIcon(icon, height / 2);
			}
		}
	},
	center(container: QueryElement, icon: QueryElement, useDimmer: boolean = false) {
		if (Utils.isFullyVisible(container)) {
			Utils.setIcon(icon, false);
			return; // Early exit
		}
		let padding = Utils.getPadding(container, useDimmer);

		if (Utils.isBottomVisible(container) === false && Utils.isTopVisible(container) === false) {
			Utils.centerWhenBottomAndTopNotVisible(container, icon, padding);
		} else if (Utils.isBottomVisible(container) === false) {
			Utils.centerWhenBottomNotVisible(container, icon, padding);
		} else if (Utils.isTopVisible(container) === false) {
			Utils.centerWhenTopNotVisible(container, icon, padding);
		}
	}
};

export default Utils;
