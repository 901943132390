
/* @flow */

enum Size {
	Favicon = 'favicon',
	ExtraMini = 'extramini',
	Mini = 'mini',
	Tiny = 'tiny',
	Small = 'small',
	Medium = 'medium',
	Large = 'large',
	Big = 'big',
	Huge = 'huge',
	Massive = 'massive'
}

enum Device {
	Min = 'min',
	Mobile = 'mobile',
	Tablet = 'tablet',
	Computer = 'computer',
	LargeScreen = 'largescreen',
	WideScreen = 'widescreen'
}

enum HAlign {
	Left = 'left',
	Center = 'center',
	Right = 'right',
}

enum VAlign {
	Bottom = 'bottom',
	Middle = 'middle',
	Top = 'top'
}

enum Float {
	Left = 'left',
	Right = 'right'
}

enum Spacing {
	All = 'all',
	Horizontal = 'horizontal',
	Vertical = 'vertical',
	Top = 'top',
	Right = 'right',
	Bottom = 'bottom',
	Left = 'left'
}

enum Width {
	Thin = 'thin',
	Wide = 'wide',
	VeryWide = 'very wide',
	Fullscreen = 'fullscreen'
}

enum Target {
	Blank = '_blank',
	Self = '_self',
	Parent = '_parent',
	Top = '_top'
}

enum Position {
	TopCenter = 'top center',
	TopLeft = 'top left',
	TopRight = 'top right',
	BottomCenter = 'bottom center',
	BottomLeft = 'bottom left',
	BottomRight = 'bottom right',
	CenterRight = 'right center',
	CenterLeft = 'left center'
}

enum On {
	Hover = 'hover',
	Click = 'click'
}

export { Size, Device, HAlign, VAlign, Float, Spacing, Width, Target, Position, On };