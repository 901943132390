/**
 * @prettier
 * @flow
 */

import { useIntl } from 'react-intl';
import { Breadcrumb, ActionHeader, Header } from 'liana-ui/components/';
import PageHeaderContext from './src/PageHeaderContext';

/* Note: Do NOT use React.Memo for this component */
component Component(
	/** A page header must have an application name to display in browser tab. */
	appName: string,
	/**
		Page header can have a breadcrumb.
		PROPS[BreadcrumbProps=/components/menus/breadcrumb/]
	*/
	breadcrumb?: React.PropsOf<Breadcrumb>,
	/**
		Page header can have a simple header without actions.
		PROPS[HeaderProps=/components/texts/header/]
	*/
	header?: React.PropsOf<Header>,
	/**
		Page header can have an more complex action header with actions, filters etc.
		PROPS[ActionHeaderProps=/components/containers/action-header/]
	*/
	actionHeader?: React.PropsOf<ActionHeader>,
	/** Test ID used for testing. */
	testID?: string
) {
	const intl = useIntl();
	let key = null,
		title = [];

	if (Array.isArray(breadcrumb?.sections) && breadcrumb.sections.length > 1) {
		for (let i = 0; i < breadcrumb.sections.length; i++) {
			key = breadcrumb.sections[i].page; // Convenience
			title.push(
				// $FlowFixMe - You shouldn't read props this way
				React.isValidElement(key) && key.props?.id
					? intl.formatMessage({ id: key.props.id }, key.props.values) // Translate a <FormatMessage> component
					: key // Basic text
			);
		}

		title.reverse();
	} else if (actionHeader?.header?.text || header?.text) {
		key = actionHeader?.header?.text || header?.text; // Convenience
		title.push(
			// $FlowFixMe - You shouldn't read props this way
			React.isValidElement(key) && key.props?.id
				? intl.formatMessage({ id: key.props.id }, key.props.values) // Translate a <FormatMessage> component
				: key // Basic text
		);
	}

	if (appName) {
		title.push(appName);
	}
	document.title = title.join(' - ');

	const getBreadcrumb = () => {
		if (breadcrumb?.sections && breadcrumb.sections.length > 1) {
			return <Breadcrumb sections={breadcrumb.sections} />;
		}
		return null;
	};

	const getActionHeader = () => {
		if (header) {
			return <ActionHeader page={true} header={header} />;
		} else if (actionHeader) {
			let contextMenuProps = actionHeader?.contextMenu;
			if (contextMenuProps) {
				contextMenuProps = {
					// $FlowIgnore[not-an-object]
					...contextMenuProps,
					text: intl.formatMessage({ id: 'component.context-menu.actions' })
				};
			}
			return <ActionHeader page={true} {...actionHeader} contextMenu={contextMenuProps} />;
		}
		return null;
	};

	return (
		<div className='page-header' data-testid={testID}>
			{getBreadcrumb()}
			{getActionHeader()}
		</div>
	);
}

const MemoComponent = (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
// $FlowIssue - Static subcomponents
MemoComponent.Context = PageHeaderContext;

export default MemoComponent;
