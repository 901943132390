/**
 * @prettier
 * @flow
 */

import { Segment } from 'semantic-ui-react';

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/segment/#types-placeholder-inline */
component Component(
	/** Content as children. */
	children: React.Node,
	/** Test ID for testing */
	testID?: string
) {
	return <Segment.Inline data-testid={testID}>{children}</Segment.Inline>;
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
