/**
 * @prettier
 * @flow
 */

import { LabelGroup } from 'semantic-ui-react';
import { Label, LimitLabel } from 'liana-ui/components/';
import { Size } from 'liana-ui/types/';

// Note: Trying to load LabelGroup separately without Label will cause a cyclic dependency error
/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/label/#groups */
component Component(
	/**
		A label group can have multiple label components.
		PROPS[LabelProps=/components/labels/labels/label/, LimitLabelProps=/components/labels/limit-label/]
	*/
	labels?: Array<React.PropsOf<Label> | React.PropsOf<LimitLabel>>,
	/** A label group can be rendered as different HTML elements. */
	as?: 'div' | 'p' | 'span' = 'div',
	/** A label group can have different sizes. */
	size?: Size = Size.Small,
	/** Test ID for testing */
	testID: string = 'LabelGroup'
) {
	if (labels) {
		return Array.isArray(labels) ? (
			<LabelGroup as={as} size={size} data-testid={testID}>
				{labels && Array.isArray(labels)
					? labels.map((label, index) => {
							const testID = label.testID || `LabelGroup::Label::${index}`;
							return label.upgradeLink || (label.limit && label.onClick) ? (
								<LimitLabel {...label} key={index} size={size} testID={testID} />
							) : (
								// $FlowFixMe - Spread props leak
								<Label {...label} key={index} size={size} testID={testID} />
							);
						})
					: null}
			</LabelGroup>
		) : (
			// $FlowFixMe - Impossible type refinement
			<Label {...labels} testID={testID} />
		);
	}
	return null;
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
