/**
 * @prettier
 * @flow
 */

import { Grid, GridColumn, EmptyState } from 'liana-ui/components/';

/** COMPONENT BASED ON: https://react.semantic-ui.com/collections/message/ */
component Component(
	/**
		An empty state group must have multiple empty states.
		PROPS[EmptyStateProps=/components/feedback/empty-state/]
	*/
	emptyStates: Array<React.PropsOf<EmptyState>>,
	/** A grid can preserve its vertical and horizontal gutters on first and last columns. */
	padded?: boolean | 'horizontally' | 'vertically',
	/** A grid can increase its gutters to allow for more negative space. */
	relaxed?: boolean | 'very',
	/**
		Represents column count per row in Grid.
		VALUES['equal', 1 - 12]
	*/
	columns?: string | number,
	/**
		A column can specify a width for a mobile device.
		VALUES[1 - 12]
	*/
	mobile?: number = 12,
	/**
		A column can specify a width for a tablet device.
		VALUES[1 - 12]
	*/
	tablet?: number = 12,
	/**
		A column can specify a width for a computer.
		VALUES[1 - 12]
	*/
	computer?: number = 4,
	/**
		A column can specify a width for a large screen device.
		VALUES[1 - 12]
	*/
	largescreen?: number = 4,
	/**
		A column can specify a width for a wide screen device.
		VALUES[1 - 12]
	*/
	widescreen?: number = 4,
	/** Test ID for testing */
	testID?: string = 'EmptyStateGroup'
) {
	return (
		<div className='empty-states-wrapper' data-testid={testID}>
			<Grid stretched padded={padded} relaxed={relaxed} columns={columns} stackable={columns !== undefined}>
				{emptyStates && Array.isArray(emptyStates)
					? emptyStates.map((emptyState, index) => (
							<GridColumn
								key={`emptyState{index}`}
								mobile={!columns ? mobile : undefined}
								tablet={!columns ? tablet : undefined}
								computer={!columns ? computer : undefined}
								largescreen={!columns ? largescreen : undefined}
								widescreen={!columns ? widescreen : undefined}
							>
								<EmptyState
									segment
									{...emptyState}
									testID={emptyState.testID || `EmptyStateGroup::EmptyState::${index}`}
								/>
							</GridColumn>
						))
					: null}
			</Grid>
		</div>
	);
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
