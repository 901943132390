/**
 * @prettier
 * @flow
 */

import { ListList } from 'semantic-ui-react';
import ListItem from './ListItem';

// prettier-ignore
type Props = {
	/** Array of strings, React Intl components or prop objects for ListItem. */
	items: Array<React.Node> | Array<React.PropsOf<ListItem>>
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/list/ */
component Component(...props: Props) {
	return props.items ? (
		<ListList>
			{props.items && Array.isArray(props.items)
				? props.items.map((item, index) =>
						typeof item === 'string' || React.isValidElement(item) ? (
							// $FlowIssue[incompatible-type] - Flow doesn't recogonize React.isValidElement
							<ListItem key={index} content={item} />
						) : (
							// $FlowFixMe - Can't determine spread props
							<ListItem key={index} {...item} />
						)
					)
				: null}
		</ListList>
	) : null;
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
