/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { IconGroup } from 'semantic-ui-react';
import { Icon, Popup } from 'liana-ui/components/';
import { Size } from 'liana-ui/types';

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/icon/#groups-icon-group */
component Component(
	/**
		Array of props for Icon.
		PROPS[IconProps=/components/labels/icons/icon/]
	*/
	icons: Array<string> | Array<React.PropsOf<Icon>>,
	/** An icon can appear on the left or right of the text. */
	iconPosition?: 'left' | 'right',
	/** Size of the icon group. */
	size?: Size,
	/**
		Popup text or, react-intl coomponent or object of properties for Popup component.
		PROPS[React.Node=/language/localisation/, PopupProps=/components/modals/popup/]
	*/
	popup?: React.Node | React.PropsOf<Popup>,
	/** Test ID for testing */
	testID: string = 'IconGroup'
) {
	// Assign classes
	let classes = classNames({
		'icon-right': iconPosition === 'right'
	});

	let group = icons ? (
		<IconGroup size={size} className={classes} data-testid={testID}>
			{icons
				? icons.map((icon, index) =>
						typeof icon === 'string' ? <Icon key={index} name={icon} /> : <Icon key={index} {...icon} />
					)
				: null}
		</IconGroup>
	) : null;

	// $FlowIssue - React statics; Attach popup
	return Popup.attach(popup, group);
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
