/**
 * @prettier
 * @flow
 */

import { StepDescription } from 'semantic-ui-react';

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/step/#groups-ordered */
component Component(
	/** Description as text. */
	text: React.Node,
	/** Test ID for testing. */
	testID: string = 'StepDescription'
) {
	return <StepDescription data-testid={testID}>{text}</StepDescription>;
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
