/**
 * @prettier
 * @flow
 */

import { ModalActions } from 'semantic-ui-react';

component Component(children: React.Node, testID: string = 'ModalActions') {
	return <ModalActions data-testid={testID}>{children}</ModalActions>;
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
