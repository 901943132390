/**
 * @prettier
 * @flow
 */

import { useIntl } from 'react-intl';
import { Dropdown, Popup } from 'liana-ui/components/';
import { Size } from 'liana-ui/types';
import type { Params } from './Dropdown';

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/dropdown/ */
component Component(
	/** A language dropdown must have an input name */
	name: string,
	/**
			A language dropdown must have standars Liana®Cloud languages as options.
			DATA[json/dropdown/language-dropdown.json]
		*/
	languages: Array<string>,
	/** Initial value or value array if multiple. Use for uncontrolled components only. */
	defaultValue?: string | Array<string>,
	/** Current value or value array if multiple. Use for controlled components only. */
	value?: string | Array<string>,
	/**
			An dropdown can have a placeholder text.
			PROPS[React.Node=/localization/]
		*/
	placeholder?: React.Node,
	/** A language dropdown can allow multiple selections. */
	multiple?: boolean = false,
	/** A country dropdown can be searchable. */
	search?: boolean = true,
	/** A country dropdown can be clearable. */
	clearable?: boolean = false,
	/** A language dropdown can take the full width of its parent. */
	fluid?: boolean = true,
	/** A language dropdown can be locked to indicate that the field is in use but can not be edited. */
	locked?: boolean = false,
	/** A language dropdown can be  disabled. */
	disabled?: boolean = false,
	/** A language dropdown can be different size. */
	size?: Size,
	/** A language dropdown can display all language options in their native language. */
	noTranslation?: boolean = false,
	/**
			Popup text or, react-intl coomponent or object of properties for Popup component.
			PROPS[React.Node=/language/localisation/, PopupProps=/components/modals/popup/]
		*/
	popup?: React.Node | React.PropsOf<Popup>,
	/** Test ID for testing */
	testID: string = 'LanguageDropdown',
	/** Called on input change. */
	onChange?: (event: SyntheticEvent<>, data: Params) => void
) {
	const intl = useIntl();

	const getOptions = () => {
		let options = [];
		if (languages) {
			for (let i = 0; i < languages.length; i++) {
				let native = intl.formatMessage({ id: `language-translations.${languages[i]}` }),
					translated = intl.formatMessage({ id: `language.${languages[i]}` });
				options.push({
					text:
						noTranslation && native !== translated
							? `${native} (${translated})`
							: noTranslation
								? native
								: translated,
					label: { content: languages[i], className: 'lang-label' },
					value: languages[i]
				});
			}
		}
		return options;
	};

	let dropdown = (
		<Dropdown
			name={name}
			defaultValue={defaultValue}
			value={value}
			placeholder={placeholder}
			/** $FlowIssue - Flow compeltely breaks here */
			options={getOptions()}
			fluid={fluid}
			multiple={multiple}
			search={search}
			clearable={clearable}
			locked={locked}
			disabled={disabled}
			size={size}
			popup={popup}
			testID={testID}
			onChange={onChange}
		/>
	);

	return dropdown;
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
