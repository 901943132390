/**
 * @prettier
 * @flow
 */

import { Statistic } from 'semantic-ui-react';
import { LianaUI } from 'liana-ui/definitions';

/** COMPONENT BASED ON: https://react.semantic-ui.com/views/statistic */
component Component(
	/** Statistic label as text. */
	content: React.Node,
	/** Test ID for testing. */
	testID: string = 'StatisticLabel'
) {
	return <Statistic.Label data-testid={testID}>{content}</Statistic.Label>;
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
