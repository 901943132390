/**
 * @prettier
 * @flow
 */

import { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useIntl, FormattedMessage } from 'react-intl';
import { Gainsight, Zendesk } from 'liana-ui/definitions';
import Form from 'liana-ui/legacy/components/form/Form';
import { PageHeader, TopPanel, Search, Loader } from 'liana-ui/components';
import SidePanel from 'components/side-panel/SidePanel';
import MeContext from 'context/MeContext';
import AppContext from 'context/AppContext';
import navigationData from 'components/side-panel/SidePanel.json';
import Notification from '../../definitions/notifications';
import { useAuth0 } from '@auth0/auth0-react';
import { useMutation } from '@apollo/client';
import { DeleteSsoSession } from '../../graphql/Me.graphql';
import { GlobalSearch } from 'graphql/Search.graphql';
import { GetOrganizationSalesContact } from 'graphql/Customer.graphql';

type Props = {
	active: boolean,
	getAccessTokenForOrganization: function,
	changingOrganization: Boolean,
	children: React.Node
};

let Component = (props: Props) => {
	const intl = useIntl();
	const navigate = useNavigate();
	const location = useLocation();
	let meCtx = useContext(MeContext);
	let appCtx = useContext(AppContext);
	let pageHeaderContext = useContext(PageHeader.Context);
	const { logout } = useAuth0();
	const [deleteSsoSession] = useMutation(DeleteSsoSession);
	const [searchLoading, setSearchLoading] = useState(false);
	const [searchResults, setSearchResults] = useState();

	const gainsightCategories = [
		{
			value: 'issue',
			id: '133d8714-e881-11eb-a728-42010a9c008e'
		},
		{
			value: 'feature_request',
			id: '133b5b9d-e881-11eb-a728-42010a9c008e'
		},
		{
			value: 'general_feedback',
			id: '133c20be-e881-11eb-a728-42010a9c008e'
		},
		{
			value: 'other',
			id: '133e4d3d-e881-11eb-a728-42010a9c008e'
		}
	];

	let classes = classNames('page-container app-ready', {
		index: props.active
	});

	const { data } = useQuery(GetOrganizationSalesContact, {
		variables: {
			id: meCtx.selectedOrganization
		}
	});

	useEffect(() => {
		if (appCtx.config.GAINSIGHT_KEY && meCtx && data) {
			const solutions = meCtx.organizations?.find((org) => org.id === meCtx.selectedOrganization)?.solutions;
			let solNames = [];
			let customAttributes = { user: { organizationRole: meCtx.organizationRole, solutions: '' } };

			if (solutions && solutions.length > 0) {
				solutions.map((sol) => {
					let additionalName = sol.additionalName ? ` (${sol.additionalName})` : '';
					let name = sol.name.toLowerCase() === 'others' ? 'LianaAccount' : `${sol.name}${additionalName}`;
					solNames.push(name);
				});
				customAttributes.user.solutions = solNames.join(', ');
			} else {
				customAttributes.user.solutions = '';
			}

			const salesContact = data.organization?.salesContact;
			if (salesContact) {
				customAttributes.user.salesContact = salesContact.name
					? `${salesContact.name} (${salesContact.email})`
					: salesContact.email;
			} else {
				customAttributes.user.salesContact = '';
			}

			const gainsightUser = JSON.parse(JSON.stringify(meCtx));
			switch(meCtx.language) {
				case 'fi':
					gainsightUser.language = 'fi-FI';
					break;
				case 'en':
					gainsightUser.language = 'en-EN';
					break;
				case 'fr':
					gainsightUser.language = 'fr-FR';
					break;
				case 'de':
					gainsightUser.language = 'de-DE';
					break;
				case 'sv':
					gainsightUser.language = 'sv-SV';
					break;
				default:
					gainsightUser.language = 'en-EN';
			}

			if (typeof aptrinsic !== 'function') {
				let gainsightConfig = { GAINSIGHT_KEY: appCtx.config.GAINSIGHT_KEY };
				Gainsight.init(gainsightConfig, gainsightUser, customAttributes);
			} else {
				Gainsight.update(gainsightUser, customAttributes);
			}
		}
		if (typeof zE !== 'function') {
			Zendesk.init(appCtx.config, meCtx);
		}
	}, [data]);

	const handleSearchChange = (event, data) => {
		if (!data.value) {
			setSearchResults(null);
		}
		setSearchLoading(true);
	};
	const handleSearchChangeDelay = (event, data) => {
		const searchWord = data.value;
		if (searchWord && searchWord.length >= data.minCharacters) {
			startSideSearch({
				variables: {
					orgId: meCtx.selectedOrganization,
					solutionFilter: {
						or: [{ name: { contains: searchWord } }, { additionalName: { contains: searchWord } }]
					},
					accountFilter: { or: [{ name: { contains: searchWord } }, { email: { contains: searchWord } }] }
				}
			});
		}
	};

	const getTools = () => {
		return (
			<Form>
				<Form.Field>
					<Search
						name='top-search'
						maxResults={5}
						minCharacters={3}
						submitUrl='/search'
						showMoreLink='/search'
						loading={searchLoading}
						results={searchResults}
						onSearchChange={handleSearchChange}
						onSearchChangeDelay={handleSearchChangeDelay}
					/>
				</Form.Field>
			</Form>
		);
	};

	const [startSideSearch] = useLazyQuery(GlobalSearch, {
		onCompleted: (data) => {
			const solutions = data.organization?.solutions?.nodes;
			const accounts = data.organization?.accounts?.nodes;
			let results = [];
			if (solutions) {
				solutions.map((sol) => {
					const plan = sol.plan ? ` ${sol.plan}` : '';
					const additionalName = sol.additionalName ? ` (${sol.additionalName})` : '';
					results.push({
						title: sol.name + plan + additionalName,
						description: <FormattedMessage id='general.solutions' />,
						link: `/solutions/${sol.id}`
					});
				});
			}
			if (accounts) {
				accounts.map((acc) => {
					results.push({
						title: acc.name || acc.email,
						description: <FormattedMessage id='general.users' />,
						link: `/organization/users/${acc.id}`
					});
				});
			}
			setSearchResults(results);
			setSearchLoading(false);
		},
		onError: () => {
			setSearchResults([]);
			setSearchLoading(false);
		}
	});

	const getSupport = () => ({
		site: {
			active: true,
			link: meCtx.supportSite
		},
		chat: {
			active: appCtx.config?.ZENDESK_KEY ? true : false,
			key: appCtx.config?.ZENDESK_KEY
		},
		feedback: {
			active: true,
			categories: gainsightCategories
		}
	});

	const onLogout = () => {
		deleteSsoSession().then(
			() => {
				logout({ logoutParams: { returnTo: window.location.origin } });
			},
			() => {
				logout({ logoutParams: { returnTo: window.location.origin } });
			}
		);
	};

	const onOrganizationChange = (organizationId) => {
		Notification.showSuccess(intl.formatMessage({ id: 'notifications.organization_is_changed' }));
		meCtx.setState((prev) => ({ ...prev, selectedOrganization: organizationId }));
		if (appCtx.config.ENVIRONMENT !== 'local') {
			props.getAccessTokenForOrganization(organizationId);
		}
		const curPathname = location.pathname;
		let paths = curPathname.split('/');
		let newPathname;
		if (paths.includes('solutions')) {
			// when on solutions page and its sub pages, on organisation change user should always be redirected to solutions page
			newPathname = '/solutions';
		}
		navigate(newPathname, { replace: true });
	};

	const onFeedback = (data, done) => {
		Gainsight.feedback(data, gainsightCategories);
		done();
		Notification.showSuccess(intl.formatMessage({ id: 'notifications.feedback_received' }));
	};

	return (
		<div className={classes}>
			<TopPanel
				appName={appCtx.config.APP_NAME}
				logo='/static/img/lianaaccount-logo.svg'
				user={meCtx}
				applications={{ organizations: meCtx.organizations }}
				navigation={navigationData}
				tools={getTools()}
				support={getSupport()}
				onLogout={onLogout}
				onOrganizationChange={onOrganizationChange}
				onFeedback={onFeedback}
			/>
			<div className='content-wrapper' id='content-wrapper'>
				<SidePanel />
				<section className='main-content' id='main-content'>
					<PageHeader
						appName={appCtx.config.APP_NAME}
						breadcrumb={pageHeaderContext?.breadcrumb}
						header={pageHeaderContext?.header}
						actionHeader={pageHeaderContext?.actionHeader}
					/>
					{!props.changingOrganization ? (
						props.children
					) : (
						<Loader active centerInEmptySpace text={<FormattedMessage id='actions.loading' />} />
					)}
				</section>
			</div>
		</div>
	);
};
Component.displayName = 'Main';
export default Component;
