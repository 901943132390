/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { Checkbox } from 'semantic-ui-react';
import { Icon, Popup, Image } from 'liana-ui/components/';
import { Size } from 'liana-ui/types';
import CheckboxLabel from './src/CheckboxLabel';

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/checkbox/ */
component Component(
	/** An checkbox must have an input name */
	name: string,
	/** An checkbox can have diffrent HTML input type. */
	type: 'checkbox' | 'radio' = 'checkbox',
	/** A checkbox can have a value value. */
	value?: string | number,
	/** Initial state of checkbox. Use for uncontrolled components only. */
	defaultChecked?: string | number,
	/** Current state of checkbox. Use for controlled components only. */
	checked?: boolean,
	/** Initial state of indeterminate checkbox. Use for uncontrolled components only. */
	defaultIndeterminate?: boolean,
	/** Current state of indeterminate checkbox. Use for controlled components only. */
	indeterminate?: boolean,
	/**
			A checkbox can have a label.
			PROPS[React.Node=/localization/]
		*/
	label?: React.Node,
	/**
			A checkbox can have a description.
			PROPS[React.Node=/localization/]
		*/
	description?: React.Node,
	/** A checkbox can be a toggle. This means it is an on/off option. */
	toggle?: boolean = false,
	/** A checkbox can have a flag. */
	flag?: string,
	/**
			A checkbox can have an image.
			PROPS[ImageProps=/components/texts/image/]
		*/
	image?: string,
	/**
			A checkbox can have an icon.
			PROPS[IconProps=/components/labels/icons/icon/]
		*/
	icon?: string,
	/** Removes empty space around a checkbox that has no label. */
	fitted?: boolean = false,
	/** A checkbox label text can always keep all content on a single line. */
	singleLine?: boolean,
	/**
			A checkbox can be locked to indicate that the field is in use but can not be edited.
		*/
	locked?: boolean = false,
	/** A checkbox can be disabled. */
	disabled?: boolean = false,
	/** A checkbox can be be read only. */
	readOnly: boolean = false,
	/** A checkbox can be different size. */
	size?: Size,
	/** Text or object of properties for help Popup */
	help?: string | React.Node | React.PropsOf<Popup>,
	/**
			Popup text or, react-intl coomponent or object of properties for Popup component.
			PROPS[React.Node=/language/localisation/, PopupProps=/components/modals/popup/]
		*/
	popup?: React.Node | React.PropsOf<Popup>,
	/* Test ID for testing. */
	testID: string = 'Checkbox',
	/** Function called on checkbox or label click. */
	onClick?: (
		event: SyntheticEvent<>,
		data: {
			name: string,
			value: string,
			checked: boolean
		}
	) => void,
	/** Function called on checked state change. */
	onChange?: (
		event: SyntheticEvent<>,
		data: {
			name: string,
			value: string,
			checked: boolean
		}
	) => void
) {
	// Called on checkbox click.
	const handleClick = (event: SyntheticInputEvent<>) => {
		if (typeof onClick === 'function') {
			onClick(event, handleCallbackData({ value: event.target.value, checked: event.target.checked }));
		}
	};

	// Called on checkbox change.
	const handleChange = (event: SyntheticInputEvent<>) => {
		if (typeof onChange === 'function') {
			onChange(event, handleCallbackData({ value: event.target.value, checked: event.target.checked }));
		}
	};

	// Handle data returned by callbacks.
	const handleCallbackData = (data: any) => {
		return {
			name: name,
			value: data.value,
			checked: data.checked
		};
	};

	// Assign classes
	const classes =
		type === 'radio'
			? classNames(
					'ui checkbox radio',
					{
						toggle: toggle,
						fitted: fitted,
						disabled: disabled,
						locked: locked,
						'no-pointer-events': readOnly,
						'has-description': description
					},
					size
				)
			: classNames(
					{
						fitted: fitted,
						disabled: disabled,
						locked: locked,
						'no-pointer-events': readOnly,
						'has-description': description
					},
					size
				);

	// Semantic UI React does not support uncontrolled checkboxes
	const prefix = type === 'radio' ? 'radio' : 'checkbox';
	const randID = `${prefix}-${Date.now()}-${Math.round(Math.random() * 9999)}`;

	let checkboxLabel = (
		<>
			<CheckboxLabel
				className={singleLine ? 'nowrap' : undefined}
				htmlFor={randID}
				text={
					<>
						{label}
						{help ? (
							<>
								{' '}
								<Icon name='fa-question-circle cursor-help' color='primary' popup={help} />
							</>
						) : null}
					</>
				}
				description={description}
				image={image}
				icon={icon}
				flag={flag}
			/>
		</>
	);

	let common = {
		id: randID,
		className: classes,
		name: name,
		value: value,
		label: checkboxLabel,
		checked: checked,
		defaultChecked: defaultChecked,
		toggle: toggle,
		fitted: fitted,
		disabled: disabled || locked,
		onClick: handleClick,
		onChange: handleChange
	};

	let checkbox =
		type === 'radio' ? (
			<div className={classes} data-default={defaultChecked} data-testid={testID}>
				<input
					id={common.id}
					type='radio'
					className='hidden'
					name={common.name}
					value={common.value}
					defaultChecked={common.defaultChecked}
					checked={common.checked}
					disabled={common.disabled}
					onClick={common.onClick}
					onChange={common.onChange}
				/>
				{common.label}
			</div>
		) : (
			<Checkbox
				type='checkbox'
				indeterminate={indeterminate}
				defaultIndeterminate={defaultIndeterminate}
				data-default={defaultChecked}
				tabIndex={readOnly ? -1 : 0}
				readOnly={readOnly}
				data-testid={testID}
				{...common}
			/>
		);

	// $FlowIssue - React statics; Attach popup
	return Popup.attach(popup, checkbox);
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
