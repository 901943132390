/**
 * @prettier
 * @flow
 */

import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import ResizeSensor from 'css-element-queries/src/ResizeSensor';
import ScrollContainer from 'liana-ui/legacy/components/scroll-container/ScrollContainer';
import { Text, Icon, Segment, Divider, ActionHeader } from 'liana-ui/components';
import { Size, HAlign, Spacing, Float } from 'liana-ui/types';

component Component(
	contentRef: React.RefSetter<HTMLElement>,
	/** Widget ID */
	id: number | string,
	/** Content to place inside .grid-stack element */
	children: React.Node,
	/** Title for widget. Can contain link ans labels */
	title: React.Node,
	/** Sub title for widget. Can contain link ans labels */
	subTitle?: React.Node,
	/** Type of widget (Possible types atm: add) */
	type?: string,
	/** Test ID for testing */
	testID?: string = 'Widget',
	/** Callback when widget is resized */
	onResize?: () => void,
	/** Callback when widget type 'add' is clicked */
	onAdd?: () => void,
	/** Callback when widget delete action is clicked */
	onDelete?: () => void,
	/** Callback when widget settings action is clicked */
	onSettings?: () => void,
	/** Callback to trigger when component has mounted */
	onMount?: () => void
) {
	const handleDelete = () => {
		let grid = document.querySelector('.grid-stack');

		if (typeof onDelete === 'function') {
			onDelete();
		} else if (grid && 'gridstack' in grid) {
			const self = document.querySelector(`.grid-stack-item[data-gs-id="${id}"]`);
			// $FlowIgnore - GridStack: False to not remove node. Let React handle in onDelete
			grid.gridstack.removeWidget(self, false);
		}
	};

	useEffect(() => {
		if (typeof onMount === 'function') {
			onMount();
		}
		let rs = null;
		const parent = document.querySelector(`.grid-stack-item[data-gs-id="${id}"]`);
		if (parent) {
			rs = new ResizeSensor(parent, () => {
				if (typeof onResize === 'function') {
					onResize();
				}
			});
		}

		return () => {
			if (rs) {
				rs.detach(parent);
			}
		};
	}, []);

	return (
		<div className={`grid-stack-item-content ${type === 'add' ? 'add-widget' : ''}`.trim()} data-testid={testID}>
			{type === 'add' ? (
				<Segment raised hoverable textAlign={HAlign.Center} onClick={onAdd}>
					<Icon name='fa-plus' size={Size.Massive} />
					<Text as='div' color='black'>
						Add new chart
					</Text>
				</Segment>
			) : (
				<Segment className='widget-container' raised removePaddings={Spacing.All} removeMargins={Spacing.All}>
					<ActionHeader
						size={Size.Small}
						stackable={false}
						sticky={false}
						header={{
							text: title,
							subheader: subTitle
						}}
						contextMenu={{
							size: Size.Tiny,
							direction: Float.Left,
							options: [
								{ header: <FormattedMessage id='component.widget.actions' /> },
								{
									text: <FormattedMessage id='component.widget.settings' />,
									icon: 'fa-gear',
									onClick: onSettings
								},
								{ divider: true },
								{
									text: <FormattedMessage id='component.widget.delete' />,
									icon: 'fa-trash-can',
									onClick: handleDelete
								}
							]
						}}
					/>
					<Segment basic compressed removePaddings={Spacing.Vertical} removeMargins={Spacing.All}>
						<Divider removeMargins={Spacing.All} />
					</Segment>
					<Segment basic className='widget-content' removeMargins={Spacing.All}>
						<div className='widget-content-wrapper' ref={contentRef}>
							<ScrollContainer classes='ui segment compressed basic remove-vertical-paddings remove-margins'>
								{children}
							</ScrollContainer>
						</div>
					</Segment>
				</Segment>
			)}
		</div>
	);
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
