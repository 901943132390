/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import type { Tooltip } from 'liana-ui/definitions/component/Types';

type Props = {
	/** Content placed inside .fields element */
	children: React.Node,
	/** Classes for .fields element */
	classes?: string,
	/** If fields are hidden or not */
	hidden?: boolean,
	/** Wrap field/fields in a visual segment */
	wrap?: boolean,
	/** Displayes inputs horizontally */
	inline?: boolean,
	/** Fields can have their widths divided evenly by providing amount (two, three, four, five etc.)  */
	fields?: boolean | string,
	/** Non inline field can be equal width */
	equal?: boolean,
	/** Displays inputs vertivcally */
	grouped?: boolean,
	/** Move above/below fields closer together */
	combined?: boolean,
	/** Float field left */
	float?: boolean,
	/** Tooltip (data-content, data-html, data-position, data-delay, data-hoverable, data-on) */
	tooltip?: Tooltip
};

/** COMPONENT BASED ON: https://fomantic-ui.com/collections/form.html */
export default class FormFieldRow extends React.PureComponent<Props> {
	constructor(props: Props) {
		super(props);
	}
	render() {
		let classes = classNames(
			this.props.fields,
			{
				'equal width': this.props.equal,
				fields: this.props.fields !== false,
				inline: this.props.inline,
				grouped: this.props.grouped,
				combined: this.props.combined,
				hidden: this.props.hidden,
				'ui segment raised compressed remove-margins': this.props.wrap,
				'float-left': this.props.float,
				clearfix: (this.props.grouped && this.props.float) || (this.props.inline && this.props.float),
				'popup-open': this.props.tooltip
			},
			this.props.classes
		);
		return (
			<div className={classes} {...this.props.tooltip} data-testid={this.props.testID || FormFieldRow.name}>
				{this.props.children}
			</div>
		);
	}
}
