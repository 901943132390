/**
 * @prettier
 * @flow
 */

import ReactDOMServer from 'react-dom/server';
import Noty from 'noty';
import { Header, Icon } from 'liana-ui/components/';

type NotyType = {
	close: () => void,
	setText: (txt: string) => void
};

type NotyOptions = {
	icon?: string,
	text?: string,
	subheader?: string,
	type?: string,
	status?: number,
	dismissQueue?: boolean,
	timeout?: number,
	force?: boolean,
	killer?: boolean,
	maxVisible?: number,
	theme?: boolean | string,
	layout?: string,
	animation?: { [string]: string },
	closeWith?: Array<string>,
	template?: string
};

const COLORS = {
	default: 'default',
	success: 'green',
	error: 'red',
	warning: 'yellow',
	info: 'purple'
};

/** COMPONENT BASED ON: https://ned.im/noty/ */
export default class Notification {
	noty: NotyType;
	options: $Shape<NotyOptions>;
	icon: string;

	constructor(options: $Shape<NotyOptions>) {
		if (typeof options === 'object' && options.icon) {
			this.icon = options.icon;
			delete options.icon;
		}

		if (options?.subheader) {
			this.subheader = options.subheader;
			delete options.subheader;
		}

		if (options?.intl) {
			switch (options?.status) {
				case 200:
					options.type = 'success';
					options.text = options.intl.formatMessage({ id: options.text });
					this.icon = 'fa-check';
					break;
				case 400:
					options.type = 'error';
					options.text = options.intl.formatMessage({ id: options.text });
					this.icon = 'fa-triangle-exclamation';
					break;
				case 429:
					options.type = 'warning';
					options.text = options.intl.formatMessage({ id: 'component.error.429.header' });
					this.icon = 'fa-circle-exclamation';
					this.subheader = options.intl.formatMessage({ id: 'component.error.429.subheader' });
					break;
				case 500:
					// Zendesk "Submit request" form ID and parameters
					const FORM_ID = '1900000388273';
					const params = `?ticket_form_id=${FORM_ID}&tf_subject=${options.intl.formatMessage({
						id: 'component.error.500.subject'
					})}&tf_description=${encodeURIComponent(options.info)}&tf_anonymous_requester_email=${
						options.email
					}`;

					options.timeout = 0;
					options.type = 'error';
					options.text = options.intl.formatMessage({ id: 'component.error.500.header' });
					this.icon = 'fa-triangle-exclamation';
					this.subheader = (
						<>
							<a
								href={
									options.intl.locale === 'fi'
										? `https://support.lianatech.com/hc/fi/requests/new${params}`
										: `https://support.lianatech.com/hc/en-us/requests/new${params}`
								}
								target='_blank'
							>
								{options.intl.formatMessage({ id: 'component.error.500.subheader' })}
							</a>{' '}
							<Icon name='fa-arrow-up-right-from-square' size='small' />
						</>
					);
					break;
				case 503:
					options.type = 'warning';
					options.text = options.intl.formatMessage({ id: 'component.error.503.header' });
					this.icon = 'fa-circle-exclamation';
					this.subheader = options.intl.formatMessage({ id: 'component.error.503.subheader' });
					break;
			}
		}

		this.options = Object.assign(
			({
				text: '-',
				subheader: '-',
				type: 'information',
				dismissQueue: true,
				timeout: 4000,
				force: false,
				killer: false,
				maxVisible: 5,
				progressBar: false
			}: $Shape<NotyOptions>),
			options
		);
		this.show();
	}

	show() {
		const $component = this;
		let opts: $Shape<NotyOptions> = Object.assign(
			{},
			({
				theme: 'custom',
				layout: 'bottomRight',
				closeWith: this.options.timeout === 0 ? ['button'] : ['click', 'button'],
				animation: {
					open: 'animated bounceInUp',
					close: 'animated bounceOutRight'
				},
				callbacks: {
					onTemplate: function () {
						this.barDom.innerHTML = $component.render(this.options.text);
					}
				}
			}: $Shape<NotyOptions>),
			this.options
		);

		this.noty = new Noty(opts);
		this.noty.show();
	}

	hide() {
		this.noty.close();
	}

	setText(text: string) {
		this.noty.setText(this.render(text));
	}

	getColor = (type: string) => (COLORS.hasOwnProperty(type) ? COLORS[type] : 'default');

	static hideAll() {
		Noty.closeAll();
	}

	static clearQueue(queue) {
		Noty.closeAll(queue);
	}

	static injectTranslations(intl: Intl, values: { [string]: string }) {
		if (typeof values !== 'object') {
			return {};
		}
		for (let i in values) {
			values[i] = intl.formatMessage({ id: values[i] });
		}
		return values;
	}

	render(text: string) {
		return ReactDOMServer.renderToStaticMarkup(
			<>
				<div
					className={`noty_body ${this.getColor(this.options.type)}`}
					data-testid={this.options.testID || Notification.name}
				>
					<Header
						text={text}
						subheader={this.subheader}
						icon={{
							name: this.icon,
							size: 'large',
							color: this.icon !== 'ui loader' ? this.getColor(this.options.type) : undefined
						}}
					/>
				</div>
				<div className='noty_progressbar'></div>
			</>
		);
	}
}
