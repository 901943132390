/** @prettier */

// Minimum JSON fetch API
// https://developers.google.com/web/updates/2015/03/introduction-to-fetch
// TODO: Missing polyfill? - https://github.com/github/fetch
// Note: There is no request cancellation for fetch - https://github.com/whatwg/fetch/issues/20

let globalOptions = { credentials: 'include' };

const Fetch = {
	call: async (url, opts = {}) => {
		if (typeof opts === 'object') {
			opts = Object.keys(globalOptions).length > 0 ? { ...globalOptions, ...opts } : opts;
		} else {
			opts = globalOptions;
		}
		return fetch(url, opts).then(async (response) => {
			const data = await response.json();
			if (response.status !== 200) {
				return Promise.reject(new Error(data.message));
			}
			return Promise.resolve(data);
		});
	},
	post: (url, data, opts = {}) => {
		// method = 'POST'
		let headers = { 'Content-type': 'application/json; charset=UTF-8' };
		if (opts.headers) {
			headers = {
				...opts.headers,
				...headers
			};
		}
		opts = {
			method: 'POST',
			body: JSON.stringify(data),
			...opts,
			headers
		};
		return Fetch.call(url, opts);
	},
	get: (url, params, opts = {}) => {
		opts;
		if (typeof params === 'object') {
			let urlsp = new URLSearchParams(params);
			url = `${url}?${urlsp.toString()}`;
		}
		return Fetch.call(url, { method: 'GET' });
	},
	delete: (url, opts = {}) => Fetch.call(url, { ...opts, method: 'DELETE' }),
	patch: (url, data, opts = {}) => Fetch.post(url, data, { ...opts, method: 'PATCH' }),
	put: (url, data, opts = {}) => Fetch.post(url, data, { ...opts, method: 'PUT' }),
	setGlobalOptions: (opts = {}) => {
		if (typeof opts === 'object') {
			globalOptions = { ...globalOptions, ...opts };
		}
	},
	clearGlobalOptions: () => (globalOptions = {})
};

const Get = Fetch.get;
const Post = Fetch.post;
const Patch = Fetch.patch;
const Put = Fetch.put;
const Delete = Fetch.delete;

export { Get, Post, Patch, Delete, Put };

export default Fetch;
