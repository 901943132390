/**
 * @prettier
 * @flow
 */

// Note: There's no acceptable solution to dynamically altering the content of this component. It should always be done via props.
// https://reactjs.org/docs/integrating-with-other-libraries.html#integrating-with-dom-manipulation-plugins
// > The easiest way to avoid conflicts is to prevent the React component from updating.
// > You can do this by rendering elements that React has no reason to update, like an empty <div />.

type Props = {
	/** HTMl content */
	html?: React.Node,
	/** HTML ID attribute */
	id?: string,
	/** Classes for element */
	classes?: string,
	/** Callback when writing in container */
	onInput: (event: SyntheticInputEvent<HTMLDivElement>) => mixed,
	/** Callback when focus goes to container */
	onFocus: (event: SyntheticInputEvent<>) => mixed,
	/** Callback when focus goes out of container */
	onBlur: (event: SyntheticInputEvent<>) => mixed
};

export default class ContentEditable extends React.PureComponent<Props> {
	_content: { current: React.ElementRef<'div'> | null };
	constructor() {
		super();
		this._content = React.createRef();
	}
	static defaultProps = {
		onInput: () => {},
		onBlur: () => {},
		onFocus: () => {}
	};
	emitChange = (event: SyntheticInputEvent<HTMLDivElement>) => {
		if (
			this._content.current &&
			typeof this.props.onInput === 'function' &&
			this._content.current.innerHTML !== event.target.innerHTML
		) {
			this._content.current.innerHTML = event.target.innerHTML;
			this.props.onInput(event);
		}
	};
	get ref() {
		return this._content.current ? this._content.current : null;
	}
	render() {
		return (
			<div
				id={this.props.id}
				ref={this._content}
				className={this.props.classes}
				contentEditable
				onBlur={this.props.onBlur}
				onFocus={this.props.onFocus}
				onInput={this.emitChange}
				dangerouslySetInnerHTML={{ __html: this.props.html }}
				data-testid={this.props.testID || ContentEditable.name}
			/>
		);
	}
}
