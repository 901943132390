/**
 * @prettier
 * @flow
 */

import { useState, useRef, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Dropdown } from 'semantic-ui-react';
import Form from 'liana-ui/legacy/components/form/Form';
import { Size, Spacing } from 'liana-ui/types';
import {
	Text,
	Icon,
	List,
	Divider,
	Header,
	Message,
	Segment,
	Image,
	Popup,
	Dropdown as DropdownSelection
} from 'liana-ui/components/';
import type { Organization, User } from '../TopPanel';

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/dropdown/ and https://react.semantic-ui.com/collections/menu/ */
component Component(
	/** If is open */
	open: boolean = false,
	/** If preview mode */
	preview?: boolean,
	/** User menu data. User object: DATA[json/user/user.json] */
	user: User,
	/** Application menu data. Array of applications object: DATA[json/applications/applications.json] */
	applications?: {
		organizations: Array<Organization>,
		links: { manageMenu: string, addSolution: boolean }
	},
	/** Position, with and heigh of dropdown menu */
	style?: { [string]: string | number },
	/** Object of properties for Popup */
	popup?: React.PropsOf<Popup>,
	/** Called on menu open. */
	onOpen?: (
		event: SyntheticEvent<>,
		data: {
			id: string
		}
	) => void,
	/** Called on menu close. */
	onClose?: (
		event: Event,
		data: {
			id: string
		}
	) => void
) {
	const popupMount = useRef<HTMLDivElement | null>(null);
	const ID = 'application';

	const isMobile =
		document.querySelector('html')?.classList.contains('mobile') ||
		document.querySelector('html')?.classList.contains('tablet');

	const getOrganizationID = () => {
		let organizationID;
		if (user || applications) {
			if (preview) {
				organizationID = applications?.organizations[0].id;
			} else {
				organizationID = user.selectedOrganization;
				// If organization not selected pick first
				if (!organizationID && user.organizations && user.organizations.length > 0) {
					organizationID = user.organizations[0].id;
				}
				// TODO: Why are these duplicated?
				if (!organizationID && applications?.organizations && applications.organizations.length > 0) {
					organizationID = applications.organizations[0].id;
				}
			}
		}
		// Note: If organizationID is still null/undefined here, everything will break
		return organizationID;
	};

	const [openSelection, setOpenSelection] = useState(false);
	const [selectedOrganization, setSelectedOrganization] = useState(getOrganizationID());

	useEffect(() => {
		if (user || applications) {
			// Reset organization back to default when menu is closed
			let org = getOrganizationID();
			if (org !== selectedOrganization) {
				setSelectedOrganization(org);
			}
		}
	}, [open]);

	// Render nothing if empty
	if (!user || !applications) {
		return null;
	}

	const getOrganizations = () => {
		// Only one organization present, no point in rendering selection
		if (!user.organizations || user.organizations.length < 2) {
			return null;
		}

		let organizations: any = [];
		let previousSection = null;
		user.organizations.map((organization, i) => {
			if (i > 0 && organization.section && organization.section !== previousSection) {
				organizations.push({ divider: true });
			}
			organizations.push({
				text: organization.name,
				className: 'text-hyphenate',
				image: <Image src={organization.image} squared avatar='building' size={Size.Favicon} />,
				value: organization.id
			});
			previousSection = organization.section;
		});

		return (
			<>
				<Segment basic compressed removeMargins={Spacing.All}>
					{/* $FlowIgnore - Legacy component */}
					<Form>
						<Form.Field>
							<Text as='p' size={Size.Small} bold>
								<FormattedMessage id='component.application-menu.displaySolutions' />:
							</Text>
							<DropdownSelection
								open={openSelection}
								name='name'
								search
								scrolling
								size={Size.Small}
								value={selectedOrganization}
								options={organizations}
								onClick={() => setOpenSelection(true)}
								onBlur={() => setOpenSelection(false)}
								onChange={handleChangeOrganization}
								onKeyDown={(event) =>
									event && event.keyCode === 32 ? event.stopPropagation() : undefined
								}
							/>
						</Form.Field>
					</Form>
				</Segment>
			</>
		);
	};

	const handleChangeOrganization = (event: ?SyntheticEvent<>, data: any) => {
		setSelectedOrganization(data.value);
		setOpenSelection(false);
	};

	const getSolutions = () => {
		if (!applications || !applications.organizations) {
			return null;
		}

		let menu: any = [];
		let filteredSolutions = applications.organizations.filter(
			(organization) => selectedOrganization === organization.id
		)[0];

		if (filteredSolutions?.solutions) {
			filteredSolutions.solutions.forEach((solution, index) => {
				let header = (
					<Header
						as='h5'
						text={solution.name}
						subheader={
							solution.additionalName ? (
								<Text limit={40} popup={{ size: Size.Mini, mountNode: popupMount.current }}>
									{solution.additionalName}
								</Text>
							) : undefined
						}
						key={index}
					/>
				);

				let applications = getApplications(
					Array.isArray(solution.applications) ? solution.applications : [],
					user?.selectedSolution === solution.id && solution.applications.length === 1 // Currently used to display active application for single app solutions. Should be changed as soon as API returns actual selectedApplication data.
				);
				menu.push(
					<>
						{index > 0 || (user.organizations && user.organizations.length > 1) ? <Divider /> : null}
						{header} {applications}
					</>
				);
			});
		} else {
			menu.push(
				<Segment basic compressed removeMargins={Spacing.All}>
					<Message
						error
						layout='big'
						size={Size.Mini}
						vertical={true}
						// FIXME: Untranslated string
						header='This account has an organization misconfiguration'
						content={
							<>
								Please{' '}
								<a href='https://support.lianatech.com/hc/en-us/requests/new' target='_blank'>
									submit a bug report
								</a>{' '}
								and we will fix it as soon as possible.
							</>
						}
					/>
				</Segment>
			);
		}

		return menu;
	};

	const getApplications = (applications: Array<any>, active: boolean) => {
		let items = [];
		applications.forEach((application) => {
			items.push(
				<Dropdown.Item
					className='application-option'
					as='a'
					active={active}
					href={application.url}
					target={application.target}
				>
					<Header
						text={application.name}
						image={{ src: application.image, squared: true, avatar: 'fa-globe' }}
						size={Size.Tiny}
					/>
				</Dropdown.Item>
			);
		});

		return items;
	};

	const getLinks = () => {
		if (!applications.organizations) {
			return null;
		}

		let links: any = [];
		let organization = applications.organizations.filter(
			(organization) => selectedOrganization === organization.id
		)[0];

		if (organization && organization.links && (organization.links.manageMenu || organization.links.addSolution)) {
			if (organization.links.manageMenu) {
				links.push({
					content: <FormattedMessage id='component.application-menu.manageMenu' />,
					icon: 'configure',
					link: organization.links.manageMenu,
					linkTarget: '_self',
					onClick: handleClose
				});
			}
			// $FlowIgnore[incompatible-type] - Already checked above
			if (organization.links.addSolution) {
				links.push({
					content: <FormattedMessage id='component.application-menu.addSolution' />,
					icon: 'add',
					link: organization.links.addSolution,
					linkTarget: '_self',
					onClick: handleClose
				});
			}
			return (
				<>
					<Divider />
					<Segment basic compressed removeMargins={Spacing.All}>
						<List size={Size.Tiny} items={links} />
					</Segment>
				</>
			);
		}
		return null;
	};

	const handleOpen = (event: SyntheticEvent<>, data: any) => {
		if (typeof onOpen === 'function') {
			onOpen(event, handleCallbackData(data));
		}
	};

	const handleClose = (event: Event, data: any) => {
		if (typeof onClose === 'function') {
			onClose(event, handleCallbackData(data));
		}
	};

	// Handle data returned by onClose callback.
	const handleCallbackData = (data: any) => ({
		id: data.id
	});

	let menu = preview ? (
		<div className={`${ID}Dropdown`}>{getSolutions()}</div>
	) : (
		<Dropdown
			id={`${ID}Menu`}
			tabIndex={false}
			closeOnBlur={false}
			open={open}
			item
			multiple
			icon={
				<Icon
					name={isMobile && open ? 'fa-close' : 'fa-grid'}
					size={isMobile && open ? undefined : Size.Large}
					circular={isMobile && open ? true : false}
				/>
			}
			onOpen={handleOpen}
			onClose={handleClose}
		>
			<Dropdown.Menu
				id={`${ID}Dropdown`}
				className={`${ID}Dropdown`}
				style={style}
				onClick={(event) => event.stopPropagation()}
			>
				{open ? (
					<>
						{getOrganizations()}
						{getSolutions()}
						{getLinks()}
					</>
				) : null}
			</Dropdown.Menu>
		</Dropdown>
	);

	menu =
		!open && !preview
			? // $FlowIssue - Attach popup; React statics
				Popup.attach({ text: <FormattedMessage id='component.application-menu.name' />, ...popup }, menu)
			: menu;

	return (
		<>
			{menu}
			<div id='popup-mount' ref={popupMount} />
		</>
	);
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
