/**
 * @prettier
 * @flow
 */

import { FormattedMessage, useIntl } from 'react-intl';
import { Segment, Textarea, Dropdown, Button } from 'liana-ui/components/';
import Form from 'liana-ui/legacy/components/form/Form';

// TODO: Undocumented properties
component Component(
	ref: React.RefSetter<any>,
	loading: boolean = false,
	modified: boolean = false,
	testID: string = 'FeedbackForm',
	onChange?: () => void,
	onSubmit?: () => void,
	onClose?: () => void
) {
	const intl = useIntl();
	const FEEDBACK_FORM_SETTINGS = {
		fields: {
			name: {
				identifier: 'category',
				rules: [
					{
						type: 'empty',
						prompt: intl.formatMessage({ id: 'component.support-menu.emptyField' })
					}
				]
			},
			feedback: {
				identifier: 'description',
				rules: [
					{
						type: 'empty',
						prompt: intl.formatMessage({ id: 'component.support-menu.emptyField' })
					}
				]
			}
		}
	};

	return (
		// $FlowIgnore - Legacy component
		<Form ref={ref} settings={FEEDBACK_FORM_SETTINGS} onChange={onChange} onSubmit={onSubmit}>
			<Segment basic testID={testID}>
				<Form.Field required>
					<Form.Label text={<FormattedMessage id='component.support-menu.topic' />} />
					<Dropdown
						className='feedbackDropdown'
						name='category'
						defaultValue='general_feedback'
						options={[
							{
								text: <FormattedMessage id='component.support-menu.generalFeedback' />,
								value: 'general_feedback'
							},
							{
								text: <FormattedMessage id='component.support-menu.featureRequest' />,
								value: 'feature_request'
							},
							{ text: <FormattedMessage id='component.support-menu.issue' />, value: 'issue' },
							{ text: <FormattedMessage id='component.support-menu.other' />, value: 'other' }
						]}
						openOnFocus={false}
					/>
				</Form.Field>
				<Form.Field required>
					<Form.Label text={<FormattedMessage id='component.support-menu.message' />} />
					<Textarea name='description' />
				</Form.Field>
			</Segment>
			<Form.Actions modified={modified} loading={loading}>
				<Button
					text={<FormattedMessage id='component.support-menu.cancelFeedback' />}
					type='button'
					onClick={onClose}
				/>
				<Button
					text={<FormattedMessage id='component.support-menu.sendFeedback.title' />}
					type='submit'
					icon='paper plane'
					color='primary'
				/>
			</Form.Actions>
		</Form>
	);
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
