/**
 * @prettier
 * @flow
 */

import { useState } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Text, Button, Header, Accordion, AccordionTitle, AccordionContent } from 'liana-ui/components/';
import { Size, HAlign } from 'liana-ui/types';

component Component(
	/** A hero can have a header in the middle of the container. */
	header?: string,
	/** A hero can have a sub header in the middle of the container. */
	subheader?: string,
	/** A hero can have a text in the middle of the container. */
	text?: React.Node,
	/** A hero can have a content header in the top left corner of the container. */
	contentHeader?: string,
	/** A hero can have custom content for the container as children. */
	children?: React.Node,
	/** A hero can be opened and closed. */
	open?: boolean = true,
	/** Test ID used for testing. */
	testID: string = 'Hero',
	/** Function called when toggle is clicked. */
	onToggle?: (open: boolean) => void
) {
	const [active, setActive] = useState(open);
	const getPopup = () => <FormattedMessage id={active ? 'component.hero.close' : 'component.hero.open'} />;
	const [popup, setPopup] = useState<React.Node>(getPopup());

	const handleClick = () => {
		setPopup(false);
		setActive(!active);
		if (typeof onToggle === 'function') {
			onToggle(!active);
		}
	};

	const handleAnimationEnd = () => {
		setPopup(getPopup());
	};

	const getButton = () => {
		return (
			<Button
				circular
				blurOnClick
				icon={active ? 'close' : 'chevron down'}
				size={Size.Small}
				popup={{
					text: popup,
					size: Size.Mini
				}}
			/>
		);
	};

	const containerClasses = classNames('hero-container', {
		'hero-banner': header || subheader
	});

	let hero = (
		<section className='hero' data-testid={testID}>
			<Accordion>
				<div className='titleWrapper'>
					{contentHeader ? <Header size={Size.Huge} textAlign={HAlign.Left} text={contentHeader} /> : null}
					<AccordionTitle index='hero' icon={false} active={active} onClick={handleClick}>
						{getButton()}
					</AccordionTitle>
				</div>
				<AccordionContent active={active} onAnimationEnd={handleAnimationEnd}>
					<div className={containerClasses}>
						{header || subheader ? (
							<Header as='h1' textAlign={HAlign.Center} text={header} subheader={subheader} />
						) : null}

						{text ? (
							<Text as='p' textAlign={HAlign.Center} size={Size.Large}>
								{text}
							</Text>
						) : null}
						{children}
					</div>
				</AccordionContent>
			</Accordion>
		</section>
	);

	return hero;
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
