/**
 * @prettier
 * @flow
 */

import { useEffect, useState, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMatch } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import * as jose from 'jose';
import { ApolloClient, ApolloProvider, InMemoryCache, HttpLink, ApolloLink, concat } from '@apollo/client';
import { Fetch, AppUtils } from 'liana-ui/definitions';
import { AppChunkError, Message, AppScroll } from 'liana-ui/components';
import Provider from 'liana-ui/lib/provider/Provider';
import AppContext from 'context/AppContext';
import MeContext from 'context/MeContext';
import Main from './Main';
import OrganizationSelect from './OrganizationSelect';
import NoCustomerView from './NoCustomerView';
import { GetData, GetMe } from '../../graphql';
import { getLinksByLanguage } from '@lianacloud/ui-common/dist';
import * as Sentry from '@sentry/react';

type Props = {
	children: React.Node
};

const Component = (props: Props) => {
	const { isLoading, isAuthenticated, error, user, loginWithRedirect, getAccessTokenSilently } = useAuth0();

	const atHome = useMatch('/home');
	const appCtx = useContext(AppContext);
	const meCtx = useContext(MeContext);
	const [userData, setUserData] = useState(null);
	const [changingOrganization, setChangingOrganization] = useState(false);
	const [userOrganizations, setUserOrganizations] = useState(null);
	const [errors, setErrors] = useState(null);

	let settings = Object.assign({}, process.env.language); // Legacy hackish

	let [client, setClient] = useState(null);

	let debug = (msg, data) => {
		if (typeof process === 'object' && typeof process.env === 'object' && process.env.NODE_ENV === 'development') {
			console.info(msg, data);
		}
	};

	const replaceCountryCodeFieldName = (countries) => {
		const newArray = countries.map(({ code: isoAlpha2, ...rest }) => ({
			isoAlpha2,
			...rest
		}));
		return newArray;
	};

	const catchError = (error) => {
		console.error(error);
		setErrors(error);
	};

	// get access token from Auth0 with App id
	// If organization has been changed don't use cache
	const getAccessToken = async (aid, organizationId) => {
		try {
			const detailedToken = await getAccessTokenSilently({
				authorizationParams: {
					aid: aid
				},
				detailedResponse: true,
				ignoreCache: true,
				cacheMode: !meCtx.selectedOrganization || organizationId == meCtx.selectedOrganization ? 'on' : 'off'
			});
			if (detailedToken && detailedToken.access_token) {
				apolloClient(detailedToken.access_token, organizationId);
			}
			const claims = jose.decodeJwt(detailedToken.access_token);
			
			// user role for current application
			const roleClaim = claims['https://lianacloud.com/role'];
			const roleStrings = roleClaim?.split('_');
			const role = roleStrings && roleStrings.length > 1 ? roleStrings[1] : 'USER';

			// update role to meCtx
			meCtx.setState((prev) => ({ ...prev, organizationRole: role }));
		} catch (e) {
			catchError(e);
			console.log(e);
		}
	};

	const getAccessTokenForOrganization = (organizationId) => {
		setChangingOrganization(true);
		if (meCtx && meCtx.organizations && meCtx.organizations.find((org) => org.id == organizationId)) {
			const lianaAccountId = userData.organizations
				.find((org) => org.id == organizationId)
				.solutions.find((sol) => sol.name === 'Others')
				.applications.find((app) => app.name == 'LianaAccount').id;
			getAccessToken(lianaAccountId, organizationId);
		}
	};

	useEffect(() => {
		if (!user || !isAuthenticated) {
			return;
		} else {
			getAccessToken(
				user['https://lianacloud.com/applicationId'],
				user['https://lianacloud.com/applicationOrganizationId']
			);
		}
	}, [isAuthenticated, user]);

	const apolloClient = (token, organizationId) => {
		AppUtils.init(); // Initialize various DOM listener
		let base = process.env.baseUrl || '/';

		Fetch.get(`${base}json/config.json`).then((config) => {
			debug('Dynamic config loaded!', config);
			const httpLink = new HttpLink({
				uri: config.API_URL || 'https://base.127.0.0.1.nip.io/graphql',
				fetchOptions: {
					mode: 'cors'
				},
				headers: {
					'Content-Type': 'application/json',
					Authorization: token ? `Bearer ${token}` : ''
				}
			});

			const authMiddleware = new ApolloLink((operation, forward) => {
				if (token) {
					const claims = jose.decodeJwt(token);
					if (claims.exp - Date.now() / 1000 < 60) {
						getAccessTokenForOrganization(organizationId);
					}
				}
				return forward(operation);
			});

			let client = new ApolloClient({
				link: concat(authMiddleware, httpLink),
				credentials: 'include',
				cache: new InMemoryCache({
					typePolicies: {
						Query: {
							fields: {
								organization: {
									merge: true
								}
							}
						}
					}
				}),
				defaultOptions: {
					watchQuery: {
						fetchPolicy: 'network-only'
					},
					query: {
						fetchPolicy: 'network-only'
					}
				}
			});

			setClient(client);

			if (!userData || !userData.organizations || userData.organizations.length == 0) {
				// Get languages, timezones, countries etc. This will be done only once per session
				Promise.all([client.query({ query: GetData }), client.query({ query: GetMe })])
					.then((data) => {
						const me = data[1].data.me;

						const updateMeLinks = {
							organizationSettings: '/organization/settings',
							userSettings: '/account/settings',
							logout: me.links.logout,
							privacyPolicy: getLinksByLanguage(me.language).privacyPolicyLink
						};

						let tempUserData = {
							...me,
							links: updateMeLinks,
							supportSite: getLinksByLanguage(me.language).supportSiteLink
						};

						if (me.organizations.length === 1) {
							// If there's only one customer, immediately set it as default (don't show customer selection)
							tempUserData.selectedOrganization = tempUserData.organizations[0].id;
						}

						setUserData(tempUserData);
						setUserOrganizations(tempUserData.organizations);

						const app = data[0].data;
						let countries;
						if (app.countries && app.countries.length > 0) {
							countries = replaceCountryCodeFieldName(app.countries);
						}

						let appData = {
							...app,
							config,
							countries: countries,
							ready: true
						};

						const tempUserDataClone = structuredClone(tempUserData);

						tempUserDataClone.organizations.forEach((organization) => {
							organization.solutions.forEach(
								(solution) =>
									(solution.applications = solution.applications.filter(
										(application) => application.isVisible
									))
							);
							const solutions = structuredClone(organization.solutions).filter(
								(solution) => solution.applications.length > 0
							);
							organization.solutions = solutions;
						});

						meCtx.setState((prev) => {
							return {
								...prev,
								...tempUserDataClone
							};
						});

						if (appCtx.countries.length === 0) {
							appCtx.setState((appDataOld) => ({ ...appDataOld, ...appData }));
						}

						Sentry.setUser({
							id: tempUserData.id,
							username: tempUserData.name,
							email: tempUserData.email,
							ip_address: '{{auto}}'
						});
						debug('User config loaded!', tempUserData);
						debug('Application config loaded!', appData);

						return null;
					})
					.catch((error) => {
						// Note: Best guess error - Usually session has expired
						// Note: Intl isn't even available yet at this point
						console.error(error);
						setErrors(error);
					});
			}

			if (config.ENVIRONMENT !== 'local') {
				Sentry.init({
					dsn: config.SENTRY_APP_DSN,
					integrations: [
						new Sentry.BrowserTracing({
							// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
							tracePropagationTargets: [config.APP_URL]
						})
					],
					// Performance Monitoring
					tracesSampleRate: 0.1, // Capture 10% of the transactions
					environment: config.ENVIRONMENT || 'unknown'
				});
			}

			setTimeout(function () {
				setChangingOrganization(false);
			}, 1000);

			return null;
		});
	};

	useEffect(() => {
		if (!isLoading && !isAuthenticated) {
			const forceLogin = async () => {
				await loginWithRedirect({
					appState: { returnTo: window.location.origin }
				});
			};
			forceLogin();
		}
	}, [isLoading]);

	useEffect(() => {
		if (!isLoading && !isAuthenticated) {
			const forceLogin = async () => {
				await loginWithRedirect({
					appState: { returnTo: window.location.origin }
				});
			};
			forceLogin();
		}
	}, []);

	useEffect(() => {
		setUserOrganizations(meCtx.organizations);
	}, [meCtx.organizations]);

	if (isLoading) {
		return <div className='ui active loader' />;
	}
	if (error) {
		return (
			<Provider language='en'>
				<div className='main-column'>
					<Message
						error
						layout='big'
						header={<FormattedMessage id='notifications.error' />}
						content={error.message}
					/>
				</div>
			</Provider>
		);
	}

	if (isAuthenticated) {
		return errors ? (
			<Provider language='en'>
				<div className='main-column'>
					<Message error layout='big' header={<FormattedMessage id='notifications.error' />} />
				</div>
			</Provider>
		) : !appCtx.ready ? (
			<div className='ui active loader' />
		) : (
			<Provider
				language={
					meCtx && meCtx.language && settings.locales.hasOwnProperty(meCtx.language)
						? meCtx.language
						: userData && userData.language && settings.locales.hasOwnProperty(userData.language)
						? userData.language
						: 'en'
				}
				{...settings}
			>
				<ApolloProvider client={client}>
					<AppChunkError>
						<AppScroll />
						{userData &&
						userOrganizations &&
						userOrganizations.length > 1 &&
						!meCtx.selectedOrganization ? (
							<OrganizationSelect
								appName={appCtx.config.APP_NAME}
								appImage={`${process.env.baseUrl}img/lianaaccount-logo.svg`}
								getAccessTokenForOrganization={getAccessTokenForOrganization}
								organizations={userOrganizations}
							/>
						) : null}
						{userData && userOrganizations && userOrganizations.length < 1 ? <NoCustomerView /> : null}
						{!meCtx.selectedOrganization || userOrganizations?.length < 1 ? null : (
							<Main
								active={atHome}
								changingOrganization={changingOrganization}
								getAccessTokenForOrganization={getAccessTokenForOrganization}
							>
								{props.children}
							</Main>
						)}
					</AppChunkError>
				</ApolloProvider>
			</Provider>
		);
	} else {
		return <div className='ui active loader' />;
	}
};

Component.displayName = 'App';

export default Component;
