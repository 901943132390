/**
 * @prettier
 * @flow
 */

import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Responsive } from 'liana-ui/components/';
import { isNumber } from 'lodash';
import FilterSidebar from './src/FilterSidebar';
import FilterModal from './src/FilterModal';
import { Float, Size, Width, Device } from 'liana-ui/types';

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/sidebar/ */
component Component(
	/** A filter can be a side panel or a modal. */
	type: 'panel' | 'modal' = 'panel',
	/**
		A button can have text.
		PROPS[React.Node=/language/localisation/]
	*/
	text?: React.Node = <FormattedMessage id='component.filter-sidebar.filters' />,
	/** A filter must have content. */
	filters: React.Node,
	/** A filter must know if the form is modified. */
	isFormModified: boolean = false,
	/** A filter must know the amount of modified filters. */
	modifiedFilters: number,
	/** A filter panel can be visible. */
	visible?: boolean = false,
	/** A filter button can have no empty space around it. */
	fitted?: boolean = false,
	/**  A filter button can be aligned to the left or right of its container. */
	floated?: Float,
	/** A filter button can have different sizes. */
	size?: Size,
	/** A filter panel or modal can have different widths. */
	width?: Width,
	/** Test ID used for testing. */
	testID: string = 'Filter',
	/** Function called when 'Clear all filters' is clicked. */
	onClear?: () => void,
	/** Function called when 'Apply filters' is clicked. */
	onSubmit?: () => void,
	/** Function called when modified filters number is changed */
	onModifiedFiltersChange?: (helpers: {
		activeFiltersNumber: number,
		setActiveFiltersNumber: (activeFiltersNumber: number) => void,
		filtersOpen: boolean,
		setFiltersOpen: (filtersOpen: boolean) => void
	}) => void
) {
	let [filtersOpen, setFiltersOpen] = useState(visible);
	const [activeFiltersNumber, setActiveFiltersNumber] = useState(modifiedFilters);

	const openFilters = () => setFiltersOpen(true);
	const closeFilters = () => setFiltersOpen(false);
	const handleSubmit = () => {
		if (typeof onSubmit === 'function') {
			onSubmit();
			if (modifiedFilters !== activeFiltersNumber) {
				setActiveFiltersNumber(modifiedFilters);
			}
		}
	};

	useEffect(() => {
		if (typeof onModifiedFiltersChange === 'function') {
			onModifiedFiltersChange({
				activeFiltersNumber,
				setActiveFiltersNumber,
				filtersOpen,
				setFiltersOpen
			});
		}
	}, [modifiedFilters]);

	const handleClear = () => {
		if (typeof onClear === 'function') {
			setActiveFiltersNumber(0);
			onClear();
		}
	};

	let clearLabel = {
		visible: !!modifiedFilters,
		text: (
			<>
				<FormattedMessage id='component.action-table.clear' /> ({modifiedFilters || activeFiltersNumber})
			</>
		),
		color: 'purple',
		circular: true,
		icon: 'fa-remove',
		onClick: handleClear
	};

	// Make sure that the form is modified or there are active filters
	const ifm = Boolean(isFormModified && (modifiedFilters || activeFiltersNumber));

	return (
		<>
			<Responsive minDevice={Device.Tablet}>
				<Button
					icon='fa-sliders'
					text={text}
					focused={filtersOpen}
					notification={activeFiltersNumber}
					fitted={fitted}
					floated={floated}
					size={size}
					onClick={openFilters}
				/>
			</Responsive>
			<Responsive maxDevice={Device.Mobile}>
				<Button
					icon='fa-sliders'
					notification={activeFiltersNumber}
					fitted={fitted}
					floated={floated}
					size={size}
					onClick={openFilters}
				/>
			</Responsive>
			{type === 'panel' ? (
				<FilterSidebar
					width={width}
					visible={filtersOpen}
					modifiedFilters={modifiedFilters}
					clearLabelProps={clearLabel}
					isFormModified={ifm}
					testID={testID}
					onClear={handleClear}
					onHide={closeFilters}
					onSubmit={handleSubmit}
				>
					{filters}
				</FilterSidebar>
			) : (
				<FilterModal
					open={filtersOpen}
					size={width ? Size.cast(width.valueOf()) : undefined}
					clearLabelProps={clearLabel}
					header={text}
					content={filters}
					testID={testID}
					actions={[
						<Button
							key='cancel'
							text={<FormattedMessage id='component.modal.cancel' />}
							type='cancel'
							onClick={closeFilters}
						/>,
						<Button
							key='clear'
							color='primary'
							text={
								<FormattedMessage
									id={`component.filter-sidebar.${
										isNumber(modifiedFilters) ? 'applyFilters' : 'close'
									}`}
									values={{ amount: modifiedFilters }}
								/>
							}
							type='submit'
							off={!isFormModified}
							onClick={() => {
								handleSubmit();
								setFiltersOpen(false);
							}}
						/>
					]}
					onClose={closeFilters}
				/>
			)}
		</>
	);
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
