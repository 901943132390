/**
 * @prettier
 * @flow
 */

import { orderBy } from 'lodash';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import PrefillInput from 'liana-ui/legacy/components/prefill-input/PrefillInput';

// prettier-ignore
type Props = {
	/* Automatic react-intl translation object. */
	intl: Intl,
	/** A phone input must have name. */
	name: string,
	/** Array of LianaCloud country codes and calling codes codes. DATA[json/phone-regions/phone-regions.json]  */
	phoneRegions: Array<{
		isoAlpha2: string,
		name: string,
		callingCode: string
	}>,
	/** Initial value for input. Use for uncontrolled components only. Both prefix and text. */
	defaultValue?: string,
	/** Current value for input. Use for controlled components only. Both prefix and text. */
	value?: string,
	/** A phone input can have a placeholder text. */
	placeholder?: string,
	/** A phone input can have deafult country selected. */
	defaultCountry?: string,
	/** A phone input can have some countries placed on top of the dropdown */
	topCountries?: string,
	/** A phone input can have an id. */
	id?: string,
	/** A phone input can have additional classes. */
	classes?: string,
	/** Timeout for input events */
	delay?: number,
	/**
		A number input can have a tooltip.
		VALUES[tooltip={{'data-content': string, 'data-variation': string, 'data-delay': number}}]
	*/
	tooltip?: Tooltip,
	/** Function called when value is changed. Returns: value, name, id */
	onChange?: (
		value,
		name,
		id
	) => {
		value: string,
		name: string,
		id: string
	},
	/** Callback when component is done loading dependencies */
	onLoad?: () => void
};

/** COMPONENT BASED ON: https://fomantic-ui.com/elements/input.html#labeled */

class PhoneInput extends React.Component<Props> {
	loading: boolean;

	constructor(props: Props) {
		super(props);

		this.loading = true;

		this._defaultValue = '';
		this._topCountries = [];
		this._options = [];
	}

	static defaultProps = {
		placeholder: '',
		delay: 0,
		topCountries: [],
		onChange: () => {},
		onLoad: () => {}
	};

	async componentDidMount() {
		let CountryNames = await import(/* webpackChunkName: "countries" */ 'i18n-iso-countries');
		CountryNames.registerLocale(
			await import(/* webpackChunkName: "countries" */ `i18n-iso-countries/langs/${this.props.intl.locale}.json`)
		);

		this._getOptions(this.props.phoneRegions, CountryNames);
		this.loading = false;
		this.forceUpdate(this.props.onLoad);
	}

	_getOptions = (
		PhoneRegions: Array<{ isoAlpha2: string, name: string, callingCode: string }>,
		CountryNames: Array<any>
	) => {
		if (this.props.topCountries) {
			for (let i = 0; i < this.props.topCountries.length; i++) {
				let isocode = this.props.topCountries[i].toUpperCase();
				let country = PhoneRegions.find((element) => element.isoAlpha2 === isocode);
				if (country) {
					let callingcode = this._formatCallingCode(country.callingCode);
					this._topCountries.push(
						this._makeObject(this._getCountryName(country, CountryNames), isocode, callingcode)
					);
					this._checkDefault(isocode, callingcode);
				}
			}
		}

		for (let i = 0; PhoneRegions.length > i; i++) {
			let isocode = PhoneRegions[i].isoAlpha2.toUpperCase();
			let callingcode = PhoneRegions[i].callingCode;

			this._options.push(
				this._makeObject(
					this._getCountryName(PhoneRegions[i], CountryNames),
					isocode,
					this._formatCallingCode(callingcode)
				)
			);

			this._checkDefault(isocode, callingcode);
		}

		if (this._topCountries.length > 0) {
			this._topCountries.push({ divider: true });
		}
		this._options = orderBy(this._options, ['text'], ['asc']);
		this._options = this._topCountries.concat(this._options);
	};

	_formatCallingCode = (callingcode: String) => {
		if (callingcode.charAt(0) !== '+') {
			callingcode = '+' + callingcode;
		}
		return callingcode;
	};

	_getCountryName = (country: Object, CountryNames: Array<any>) => {
		let name = CountryNames.getName(country.isoAlpha2, this.props.intl.locale);
		return name ? name : country.name;
	};

	_makeObject = (name: String, isokey: String, callingcode: String) => {
		return {
			text: (
				<>
					<span className='hide-on-select text'>{name}</span>
					<span className='description'>{callingcode}</span>
				</>
			),
			value: callingcode,
			key: isokey,
			flag: isokey.toLowerCase()
		};
	};

	_checkDefault = (isokey: String, callingcode: String) => {
		if (
			!this.props.defaultValue &&
			!this.props.value &&
			this.props.defaultCountry &&
			this.props.defaultCountry === isokey
		) {
			this._defaultValue = '+' + callingcode;
		}
	};

	render() {
		let classes = classNames('phone-input', this.props.classes, { invisible: this.loading });
		return (
			<PrefillInput
				id={this.props.id}
				name={this.props.name}
				type='tel'
				placeholder={this.props.placeholder}
				defaultValue={this.props.defaultValue || this._defaultValue}
				value={this.props.value}
				classes={classes}
				tooltip={this.props.tooltip}
				delay={this.props.delay}
				prefixOptions={this._options}
				search
				testID={this.props.testID || PhoneInput.name}
				onChange={this.props.onChange}
			/>
		);
	}
}

let Wrapper = injectIntl(PhoneInput, { forwardRef: true });
Wrapper.displayName = 'PhoneInput';
export default Wrapper;
