/**
 * @prettier
 * @flow
 */

import React from 'react';
import { Visibility } from 'semantic-ui-react';

/** COMPONENT BASED ON: https://react.semantic-ui.com/behaviors/visibility/ */
component Component(
	/** An element type to render as. */
	as?: any = React.Fragment,
	/** Primary content. */
	children: React.Node,
	/** Value that context should be adjusted in pixels. Useful for making content appear below content fixed to the page. */
	offset?: number | Array<number> = [0, 0],
	/** Element's top edge has passed bottom of screen. */
	onTopVisible?: () => void,
	/** Element's bottom edge has passed bottom of screen. */
	onBottomVisible?: () => void
) {
	return (
		<Visibility
			as={as}
			offset={offset}
			onTopVisible={onTopVisible}
			onBottomVisible={onBottomVisible}
			children={children}
			updateOn='repaint'
		/>
	);
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
