/**
 * @prettier
 * @flow
 */

import { SegmentGroup } from 'semantic-ui-react';
import { Segment } from 'liana-ui/components/';
import { Size } from 'liana-ui/types';

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/segment/#groups */
component Component(
	/**
		A segment group can have multiple segments.
		PROPS[SegmentProps=/components/containers/segments/segment/]
	*/
	segments?: Array<React.PropsOf<Segment>>,
	/** Segment groups can also be passed as children. */
	children?: React.Node,
	/** A segment group can be formatted to raise above the page. */
	raised?: boolean,
	/** A segment group can take up only as much space as is necessary for content. */
	compact?: boolean | 'very' = false,
	/** A segment group can be different size. */
	size?: Size,
	/** Test ID for testing */
	testID?: string
) {
	return (
		<SegmentGroup raised={raised} compact={compact} size={size} data-testid={testID}>
			{segments
				? segments.map((segment, index) => (
						<Segment
							key={index}
							testID={segment.testID || `Segment.Group::Segment::${index}`}
							{...segment}
						/>
					))
				: children}
		</SegmentGroup>
	);
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
