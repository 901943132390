/**
 * @prettier
 * @flow
 */

import { FormattedMessage } from 'react-intl';
import { Accordion, Label } from 'liana-ui/components';
import type { Panel } from 'liana-ui/components/accordion/Accordion';

type Page = {
	icon?: string,
	text?: string,
	link?: string,
	header?: string,
	labels?: React.PropsOf<Label>,
	pages?: Array<Page>
};

component Component(
	/** Navigation data. Array of pages object. */
	navigation: Array<Page>,
	/** Set maximum levels of accordion. */
	maxLevels: number = 3,
	/** Use plaintext translations */
	raw: boolean = false,
	/** Test ID for testing */
	testID: string = 'Navigation',
	/** Function called on menu item click. */
	onClick?: (
		event?: SyntheticEvent<>,
		data: {
			name: string
		}
	) => void,
	/** Function called on menu link item click only. */
	onLinkClick?: (event: SyntheticEvent<>, data: any) => void,
	/** Function called when toggled section is fully open. */
	onAnimationEnd?: (newHeight: number) => void
) {
	let formatNavigation = () => {
		let formatted = [];
		navigation.map((item, i) => {
			formatted.push(getItem(item, i));
		});
		return formatted;
	};

	let getItem = (item: any, i: string | number): Panel => {
		let panels = [];
		if (item.pages && item.pages.length > 0) {
			item.pages.map((subitem, j) => {
				panels.push(getItem(subitem, `${i}-${j}`));
			});
		}
		const translate = (key: string) => {
			if (!key) {
				return undefined;
			}
			return raw ? key : <FormattedMessage id={key} />;
		};
		return {
			key: String(i),
			icon: item.icon,
			header: translate(item.header),
			title: translate(item.text),
			divider: item.divider,
			labels: item.labels,
			link: item.link,
			upsell: item.upsell,
			panels: panels && panels.length > 0 ? panels : undefined,
			testID: item.testID || `Navigation::Panel::${i}`
		};
	};

	let handleClick = (event?: SyntheticEvent<>, data: any) => {
		if (typeof onClick === 'function') {
			onClick(event, data);
		}
	};

	let handleLinkClick = (event: SyntheticEvent<>, data: any) => {
		if (typeof onLinkClick === 'function') {
			onLinkClick(event, data);
		}
	};

	let handleAnimationEnd = (newHeight: number) => {
		if (typeof onAnimationEnd === 'function') {
			onAnimationEnd(newHeight);
		}
	};

	return navigation ? (
		<nav className='main-navigation' data-testid={testID}>
			<Accordion
				exclusive
				panels={formatNavigation()}
				maxLevels={maxLevels}
				onClick={handleClick}
				onLinkClick={handleLinkClick}
				onAnimationEnd={handleAnimationEnd}
			/>
		</nav>
	) : null;
}

export type { Page };

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
