/**
 * @prettier
 * @flow
 */

import { Grid, Statistic, GridRow, GridColumn } from 'liana-ui/components';
import { Size, HAlign } from 'liana-ui/types';

/** COMPONENT BASED ON: https://react.semantic-ui.com/views/statistic */
component Component(
	/**
		A statistic group can have multiple statistics as props object for Stattistic component.
		PROPS[StatisticProps=/components/statistics/statistic/statistic/]
	*/
	items: Array<React.PropsOf<Statistic>>,
	/**
		A statistic group can have multiple rows of statistics as props object for Stattistic component.
		PROPS[StatisticProps=/components/statistics/statistic/statistic/]
	*/
	itemRows: Array<Array<React.PropsOf<Statistic>>>,
	/**
		Represents column count per row in Grid.
		VALUES['equal' | 1 - 12]
	*/
	columns: string | number = 3,
	/** A static group can increase its gutters to allow for more negative space. */
	relaxed?: boolean | 'very' = false,
	/** A static group can have different sizes. */
	size?: Size,
	/** Test ID for testing */
	testID: string = 'StatisticGroup'
) {
	const getColumns = (data: Array<React.PropsOf<Statistic>>) => {
		let columns = [];

		if (data && Array.isArray(data)) {
			data.map((item, index) => {
				columns.push(
					<GridColumn textAlign={HAlign.Center}>
						<Statistic
							size={size}
							{...item}
							testID={item.testID || `Statistic.Group::Statistic::${index}`}
						/>
					</GridColumn>
				);
			});
		}

		return columns;
	};

	const getRows = (data: Array<Array<React.PropsOf<Statistic>>>) => {
		let rows = [];

		if (data && Array.isArray(data)) {
			for (let i = 0; i < data.length; i++) {
				let columns = [];
				columns.push(getColumns(data[i]));
				rows.push(<GridRow>{columns}</GridRow>);
			}
		}

		return rows;
	};

	let statistics = (
		<Grid divided celled='internally' stretched stackable columns={columns} relaxed={relaxed} testID={testID}>
			{itemRows ? getRows(itemRows) : getColumns(items)}
		</Grid>
	);

	return statistics;
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
