/**
 * @prettier
 * @flow
 */

// Rendering utilities; does not support Class components
// https://github.com/yahoo/react-intl/wiki/Testing-with-React-Intl
import { IntlProvider } from 'react-intl';
import { MemoryRouter } from 'react-router-dom';
import TestUtils from 'react-dom/test-utils';

const TEST_CONTAINER_ID = 'test-container';

const handleTranslationError = () => {}; // noop

export default {
	// Simple utility function to retrieve a hash
	random() {
		return Math.random().toString(36).substring(7);
	},
	// Simple utility function to trigger an async timeout
	async wait(delay: number = 0) {
		return new Promise((resolve) => setTimeout(resolve, delay));
	},
	// Initialize a DOM node for testing. If you want your node to receive render updates, using this or a similar function is REQUIRED.
	init() {
		let node = null;
		if (typeof document === 'object') {
			node = document.body.querySelector(`#${TEST_CONTAINER_ID}`);
			if (!node) {
				node = document.createElement('div');
				node.setAttribute('id', TEST_CONTAINER_ID);
				document.body.appendChild(node);
			}
		}
		return node;
	},
	// Remove a DOM node and unmount any React components inside it
	// Prefer calling reset() rather than this function directly
	remove(node: HTMLElement) {
		if (typeof node === 'object' && node.parentNode) {
			if (node.childNodes.length > 0) {
				ReactDOM.unmountComponentAtNode(node);
			}
			node.parentNode.removeChild(node);
			node = null;
		}
		return null;
	},
	// Clear a DOM node and optionally purge the entire DOM tree (keep scripts) - See: remove()
	reset(node: HTMLElement, hard: boolean = true) {
		if (typeof node === 'object') {
			this.remove(node);
		} else {
			console.warn('Render::reset - node is not valid. Did you call Render::init?');
		}
		if (hard === true && typeof document === 'object') {
			let extra = document.querySelectorAll('body :not(script)');
			for (let i = 0; i < extra.length; i++) {
				extra[i].remove();
			}
		}
	},
	// Render a React component using act() into a DOM node
	render(component: React.Node, node: HTMLElement = null) {
		if (typeof document === 'object') {
			node = !node ? this.init() : node;
			TestUtils.act(() => {
				ReactDOM.render(component, node); // act() must return undefined
			});
			return node.children.length > 1 ? node.children : node.children[0];
		}
		return null;
	},
	// Render a React component with React Intl support - See: render()
	intl(component: React.Node, node: HTMLElement = null, render = true, messages = { foo: 'bar' }) {
		component = React.cloneElement(component);
		let key = node ? undefined : this.random();
		let wrapper = (
			<IntlProvider key={key} locale='en' defaultLocale='en' messages={messages} onError={handleTranslationError}>
				{component}
			</IntlProvider>
		);
		return render ? this.render(wrapper, node) : wrapper;
	},
	// Render a React component with React Router support - See: render()
	router(component: React.Node, node: HTMLElement = null, render = true) {
		let wrapper = <MemoryRouter>{component}</MemoryRouter>;
		return render ? this.render(wrapper, node) : wrapper;
	},
	// Render a React component with both React Router and React Intl support - See: render()
	combo(component: React.Node, node: HTMLElement = null) {
		component = this.intl(component, node, false);
		return this.router(component, node);
	}
};
