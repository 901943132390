/**
 * @prettier
 */

// Base descriptor / HOF for components
const LianaUI = {
	Component: (Component, config = {}) => {
		const { forwardRef, displayName, defaultProps, ...subComponents } = config;
		const testID = displayName || 'Component';

		let WrapperComponent =
			forwardRef === true
				? // eslint-disable-next-line react/display-name
				  React.forwardRef((props, ref) => (
						<Component {...props} testID={props.testID || testID} forwardRef={ref} />
				  ))
				: (props) => <Component {...props} testID={props.testID || testID} />;

		if (displayName) {
			WrapperComponent.displayName = displayName;
		}
		if (defaultProps) {
			WrapperComponent.defaultProps = defaultProps;
		}

		if (typeof subComponents === 'object') {
			for (let sub in subComponents) {
				WrapperComponent[sub] = subComponents[sub];
			}
		}

		return WrapperComponent;
	}
};

export default LianaUI;
