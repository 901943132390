/**
 * @prettier
 * @flow
 */

import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import type { Tooltip } from 'liana-ui/definitions/component/Types';
import Time from 'liana-ui/definitions/time/Time';
import Button from 'liana-ui/legacy/components/button/Button';
import Icon from 'liana-ui/legacy/components/icon/Icon';

import 'jquery-ui/ui/widget';
import 'jquery-ui/ui/widgets/mouse';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui-touch-punch';

// prettier-ignore
type Props = {
	intl: Intl,
	/** A date range must have an input name */
	name: string,
	/** A date range can have an id */
	id?: string,
	/** A date range can have additional classes. */
	classes?: string,
	/** A date range Classes can have additional classes for the button. VALUES['active', 'disabled' | 'locked' | 'error']  */
	buttonClasses?: string,
	/**
		A date range can have a placeholder text.
	*/
	placeholder?: string,
	/** A date range can be clearable. */
	isResettable?: boolean,
	/** A date range can have a start date. VALUES[YYYY-MM-DD | ''] */
	startDate?: string,
	/** A date range can have a end date. VALUES[YYYY-MM-DD | ''] */
	endDate?: string,
	/** A date range can have timezone offset. Timezone offset in seconds for date range. Default is this.context.user.get('timezone_offset') or this.context.user.timezone.offset. */
	timezoneOffset?: number,
	/** A date range can hide the input field and show only a button. */
	hideInput?: boolean,
	/** A date range can set a minimum allowed date. DATA[https://api.jqueryui.com/datepicker/#option-minDate] */
	minDate?: string,
	/** A date range can set a maximum alloed date. DATA[https://api.jqueryui.com/datepicker/#option-maxDate] */
	maxDate?: string,
	/** A date range picker can have vertical layout. */
	vertical?: boolean,
	/** A date range picker can be initially positioned differently realive to field. */
	pickerPosition?: 'left bottom' | 'right bottom' | 'right top' | 'left top',
	/** A date range can move the date picker from default wrapper into another DOM element. */
	pickerParent?: string,
	/**
		A number input can have a tooltip.
		VALUES[tooltip={{'data-content': string, 'data-variation': string, 'data-delay': number}}]
	*/
	tooltip?: Tooltip,
	/** Extra settings for jQuery UI datepicker. DATA[http://api.jqueryui.com/datepicker/] */
	settings?: {},
	/** Function called when input date is focused.*/
	onFocus?: (
		startDate: string,
		endDate: string,
		name: string,
		id: string
	) => mixed,
	/** Function called when input date range is changed. */
	onChange?: (
		startDate: string,
		endDate: string,
		name: string,
		id: string
	) => mixed,
	/** Function called when user selects date from picker. */
	onSelect?: (
		startDate: string,
		endDate: string,
		name: string,
		id: string
	) => mixed,
	/** Function called when picker is opened. */
	onOpen?: (
		startDate: string,
		endDate: string,
		name: string,
		id: string
	) => mixed,
	/** Function called when picker is closed. */
	onClose?: (
		startDate: string,
		endDate: string,
		name: string,
		id: string
	) => mixed,
	/** Function called when input is reset. */
	onReset?: (
		name: string,
		id: string
	) => mixed
};

/** COMPONENT BASED ON: https://jqueryui.com/datepicker/ */
class DateRange extends React.Component<Props> {
	startDate: string;
	endDate: string;
	timezoneOffset: string | number;
	lastValue: string;
	$display: JQuery;
	$input: JQuery;
	$startText: JQuery;
	$endText: JQuery;
	$wrapper: JQuery;
	$picker: JQuery;
	$startPicker: JQuery;
	$endPicker: JQuery;
	$button: JQuery;
	$reset: JQuery;
	$resetButton: JQuery;

	setWrapperRef: (?HTMLDivElement) => {};
	setDisplayRef: (?HTMLInputElement) => {};
	setInputRef: (?HTMLInputElement) => {};
	setStartTextRef: (?HTMLSpanElement) => {};
	setEndTextRef: (?HTMLSpanElement) => {};
	setPickerRef: (?HTMLDivElement) => {};
	setStartPickerRef: (?HTMLDivElement) => {};
	setEndPickerRef: (?HTMLDivElement) => {};

	constructor(props: Props) {
		super(props);

		// Variables
		this.timezoneOffset = 0;
		this.startDate = '';
		this.endDate = '';

		// jQuery ref instances
		this.$wrapper = null;
		this.$reset = null;
		this.$display = null;
		this.$input = null;
		this.$startText = null;
		this.$endText = null;
		this.$picker = null;
		this.$startPicker = null;
		this.$endPicker = null;
		this.$resetButton = null;
		this.pickerPosition = '';
		//this.$button = null;
		//this.$closeButton = null;

		// Use callback refs not inline functions as refs to avoid null Refresh
		// https://reactjs.org/docs/refs-and-the-dom.html#callback-refs
		// TODO: Overuse of refs
		this.setWrapperRef = (element) => (this.$wrapper = $(element));
		this.setDisplayRef = (element) => (this.$display = $(element));
		this.setInputRef = (element) => (this.$input = $(element));
		this.setStartTextRef = (element) => (this.$startText = $(element));
		this.setEndTextRef = (element) => (this.$endText = $(element));
		this.setPickerRef = (element) => (this.$picker = $(element));
		this.setStartPickerRef = (element) => (this.$startPicker = $(element));
		this.setEndPickerRef = (element) => (this.$endPicker = $(element));
		this.closeButtonRef = (element) => (this.$closeButton = $(element));
	}

	static defaultProps = {
		classes: '',
		buttonClasses: '',
		isResettable: true,
		inputStart: '',
		inputEnd: '',
		hideInput: false,
		minDate: null,
		maxDate: null,
		pickerPosition: 'left top',
		settings: {
			dateFormat: 'yy-mm-dd',
			showOn: 'both',
			changeMonth: true,
			changeYear: true,
			yearRange: '-100:+100'
		},
		onFocus: () => {},
		onChange: () => {},
		onSelect: () => {},
		onOpen: () => {},
		onClose: () => {},
		onReset: () => {}
	};

	static contextTypes = {
		user: () => {}
	};

	componentDidMount() {
		this.pickerPosition = this.props.hideInput ? 'right top' : 'left top';
		this._initComponent();
	}

	componentDidUpdate(prevProps: Props) {
		// Refresh timezone offset
		if (this.props.timezoneOffset) {
			this.timezoneOffset = this.props.timezoneOffset;
		}
		this._refreshComponent();
	}

	shouldComponentUpdate(nextProps: Props) {
		// Let jQuery datepicker always set the value when it is changed with React value property
		if (
			(nextProps.startDate && nextProps.startDate !== this.getStartDate()) ||
			(nextProps.endDate && nextProps.endDate !== this.getEndDate())
		) {
			this.setRange(nextProps.startDate + ' - ' + nextProps.endDate);
		}

		// If jQuery datepicker needs re-rendering
		return (
			nextProps.timezoneOffset !== this.props.timezoneOffset ||
			nextProps.minDate !== this.props.minDate ||
			nextProps.maxDate !== this.props.maxDate ||
			nextProps.buttonClasses !== this.props.buttonClasses
		);
	}

	componentWillUnmount() {
		// http://stackoverflow.com/questions/16269034/jquery-2-0-jquery-ui-datepicker-bug-uncaught-typeerror-cannot-read-property/19039473
		// One way that I've found to fix this problem, assuming you want to reset the datepicker is: $(".hasDatepicker").removeClass("hasDatepicker");
		this.$startPicker.removeClass('hasDatepicker');
		this.$startPicker.datepicker('destroy');
		this.$endPicker.removeClass('hasDatepicker');
		this.$endPicker.datepicker('destroy');
		window.removeEventListener('resize', this._positionPicker);
	}

	_getLanguage = () => {
		// Get user language and fetch translations
		let lang = this.props.intl.locale;

		if (lang !== 'en' && !(lang in $.datepicker.regional)) {
			require(`jquery-ui/ui/i18n/datepicker-${lang}.js`);
		} else if (lang === 'en') {
			lang = '';
		}

		$.datepicker.setDefaults($.datepicker.regional[lang]);
		return $.datepicker.regional[lang];
	};

	_initComponent = () => {
		if (this.props.startDate && this.props.endDate) {
			this.lastValue = this.props.startDate + ' - ' + this.props.endDate;
		}

		// Uses either this.context.user.get('timezone_offset') (Immutable) or this.context.user.timezone.offset (Non immutable) as default
		if (!this.props.timezoneOffset && this.context.user) {
			this.timezoneOffset =
				typeof this.context.user.get === 'function' && this.context.user.get('timezone_offset')
					? this.context.user.get('timezone_offset')
					: this.context.user.timezone && this.context.user.timezone.offset
					? this.context.user.timezone.offset
					: undefined;
		} else {
			this.timezoneOffset = this.props.timezoneOffset;
		}

		// Init starts DatePicker
		this.$startPicker.datepicker(this._getStartOptions());
		this.$startPicker.find('.ui-state-active').removeClass('ui-state-active');

		// Init end DatePicker
		this.$endPicker.datepicker(this._getEndOptions());
		this.$endPicker.find('.ui-state-active').removeClass('ui-state-active');

		// Close picker on screen resize to handle positionin correctly
		window.addEventListener('resize', this._positionPicker);

		// Set default dates
		if (this.props.startDate || this.props.endDate) {
			this.setRange(this.props.startDate + ' - ' + this.props.endDate);
		}

		// Set default visaul dates
		this._setVisualDate();

		// Listen for any change to hidden input to trigger onChange callback
		this.$input.on(
			'change',
			function () {
				let value = this.getRange();
				if (value !== this.lastValue) {
					this.lastValue = value;
					this._setVisualDate();
					this.props.onChange(this.startDate, this.endDate, this.props.name, this.props.id);
				}
			}.bind(this)
		);
	};

	_refreshComponent() {
		// Refresh starts DatePicker
		this.$startPicker.datepicker('destroy').datepicker(this._getStartOptions());
		this.$startPicker.find('.ui-state-active').removeClass('ui-state-active');

		// Refresh end DatePicker
		this.$endPicker.datepicker('destroy').datepicker(this._getEndOptions());
		this.$endPicker.find('.ui-state-active').removeClass('ui-state-active');

		// Set the same date that was before resresh. Also sets date correctly if min/max/restricted dates were changed.
		if (this.getRange()) {
			this.setRange(this.getRange());
		}
	}

	_getStartOptions = () => this._getOptions('startDate');

	_getEndOptions = () => this._getOptions('endDate');

	_getOptions = (bound: 'startDate' | 'endDate') => {
		let propOptions = {
			defaultDate: Time.get(this.timezoneOffset, 'now', 'date'),
			minDate: this._getMinDate(),
			maxDate: this._getMaxDate(),
			onSelect: (date, $instace) => {
				// $FlowFixMe Indexer property
				this[bound] = date;
				this._handleSelect();
				this._enableDone();
				this.props.onSelect(this.startDate, this.endDate, this.props.name, this.props.id);
			},
			beforeShowDay: (date) => {
				return this._highlightSelectedRange(date);
			},
			closeText: this.props.intl.formatMessage({
				id: 'component.date-range.close'
			})
		};
		return Object.assign(
			{},
			DateRange.defaultProps.settings,
			this._getLanguage(),
			this.props.settings,
			propOptions
		);
	};

	_getMinDate = () => this._getDateBound('minDate');

	_getMaxDate = () => this._getDateBound('maxDate');

	_getDateBound = (prop: 'maxDate' | 'minDate') => {
		let date = '';
		if (this.props[prop] !== null) {
			if (this._isValidDate(this.props[prop])) {
				date = this.props[prop];
			} else if (this.props[prop].toString().charAt(0) === '-' || this.props[prop].toString().charAt(0) === '+') {
				date = Time.get(this.timezoneOffset, this.props[prop], 'date');
			} else {
				date = Time.get(this.timezoneOffset, '+' + this.props[prop] + 'd', 'date');
			}
		}
		return date;
	};

	_isValidDate = (date: Date | string) => date.toString().match(/(\d{4})-(\d{2})-(\d{2})/);

	_handleSelect = () => {
		let nowDate = Time.get(this.timezoneOffset, 'now', 'date'),
			nowSeconds = new Date(nowDate).getTime(),
			startSeconds = new Date(this.startDate).getTime(),
			endSeconds = new Date(this.endDate).getTime();

		if (!this.startDate && this.$startPicker.find('.ui-datepicker-today').length === 1) {
			if (endSeconds < nowSeconds) {
				this.$startPicker.datepicker('setDate', this.endDate);
				this.startDate = this.endDate;
			} else {
				this.$startPicker.datepicker('setDate', nowDate);
				this.startDate = nowDate;
			}
		}

		if (!this.endDate && this.$endPicker.find('.ui-datepicker-today').length === 1) {
			if (startSeconds > nowSeconds) {
				this.$endPicker.datepicker('setDate', this.startDate);
				this.endDate = this.startDate;
			} else {
				this.$endPicker.datepicker('setDate', nowDate);
				this.endDate = nowDate;
			}
		}

		if (this.startDate && this.endDate) {
			this._setMinMaxDate();
		}
		this.$input.val(this.startDate + ' - ' + this.endDate);
		this.$input.trigger('change');
	};

	_setMinMaxDate = () => {
		/* Prevent cross selecting invalid time ranges */
		let startSeconds = new Date(this.startDate).getTime(),
			endSeconds = new Date(this.endDate).getTime();

		if (startSeconds > endSeconds) {
			this.endDate = this.startDate;
		}

		this.$startPicker.datepicker('option', 'maxDate', this.endDate || this.props.maxDate);
		this.$endPicker.datepicker('option', 'minDate', this.startDate || this.props.minDate);
	};

	_handleReset = () => {
		this.startDate = '';
		this.endDate = '';
		this.$input.val('');
		this._refreshComponent();
		this.$input.trigger('change');
	};

	_toggleReset = () => {
		if (this.props.isResettable === true) {
			let value = this.$input.val();
			if (value.length > 0) {
				if (this.$reset) {
					this.$reset.parent().addClass('reset-visible');
				}
				this.$resetButton.removeClass('disabled');
			} else {
				if (this.$reset) {
					this.$reset.parent().removeClass('reset-visible');
				}
				this.$resetButton.addClass('disabled');
			}
		}
	};

	_disableDone = () => {
		if (!this.getStartDate() || !this.getEndDate()) {
			this.$closeButton.addClass('disabled');
		}
	};

	_enableDone = () => {
		this.$closeButton.removeClass('disabled');
	};

	_setVisualDate = () => {
		let visualize = (dateString) =>
				dateString
					? this.props.intl.formatDate(dateString, {
							timeZone: 'UTC',
							year: 'numeric',
							month: 'short',
							day: 'numeric'
					  })
					: '',
			vStart = visualize(this.startDate),
			vEnd = visualize(this.endDate);

		this.$startText.text(vStart);
		this.$endText.text(vEnd);
		this.$display.val(vStart && vEnd ? vStart + ' - ' + vEnd : '');

		this._toggleReset(); // Enable/disable reset
	};

	_highlightSelectedRange = (date: string) => {
		let cdate = new Date(Date.parse(date)).getTime(),
			sdate = new Date(Date.parse(this.startDate)).getTime(),
			edate = new Date(Date.parse(this.endDate)).getTime();

		return this.startDate && this.endDate && cdate && sdate && edate && cdate >= sdate - 86400000 && cdate <= edate
			? [true, 'highlight']
			: [true, ''];
	};

	_positionPicker = () => {
		if (this.props.pickerParent && this.$picker.parent(this.props.pickerParent).length === 0) {
			$(this.props.pickerParent).css('position', 'relative').append(this.$picker);
		}

		let pickerHeight = this.$picker.height(),
			position = this.pickerPosition,
			windowTop = $(window).scrollTop(),
			windowBottom = $(window).scrollTop() + $(window).height(),
			documentBottom = $(document).height(),
			wrapperTop = this.$wrapper.offset().top,
			wrapperBottom = wrapperTop + this.$wrapper.height();

		if (
			this.pickerPosition.indexOf('bottom') > -1 &&
			(windowBottom || documentBottom) <= wrapperBottom + pickerHeight
		) {
			position = position.replace('bottom', 'top');
		}
		if (position.indexOf('top') > -1 && windowTop >= wrapperTop - pickerHeight) {
			position = position.replace('top', 'bottom');
		}
		if (this.$picker.offset().left <= 0) {
			position = position.replace('right', 'left');
		}
		if (this.$picker.offset().left + this.$picker.width() >= $(window).width()) {
			position = position.replace('left', 'right');
		}

		this.pickerPosition = position;
		this.$picker.removeClass('left right top bottom').addClass(this.pickerPosition);
	};

	/** Open picker */
	open = () => {
		this._positionPicker();
		this.$picker.addClass('visible');
		this._disableDone();
		this.props.onOpen(this.startDate, this.endDate, this.props.name, this.props.id);

		// Trigger onFocus Callback. Not really "focus" though.
		this.props.onFocus(this.startDate, this.endDate, this.props.name, this.props.id);
	};

	/** Close picker */
	close = () => {
		this.$picker.removeClass('visible');
		setTimeout(() => {
			this.$picker.removeClass('left right top bottom').addClass('right bottom');
		}, 250);
		this.props.onClose(this.startDate, this.endDate, this.props.name, this.props.id);
	};

	/** Get range. Returns: selected date range */
	getRange = () => this.$input.val();

	/** Get start date. Returns: selected start date */
	getStartDate = () => this.$input.val().split(' - ')[0];

	/** Get end date. Returns: selected end date */
	getEndDate = () => this.$input.val().split(' - ')[1];

	/** Set range. Params: range, Returns: selected range */
	setRange = (range: string) => {
		this.startDate = range.split(' - ')[0];
		this.endDate = range.split(' - ')[1];
		this._setMinMaxDate();
		if (this.startDate) {
			this.$startPicker.datepicker('setDate', this.startDate);
		}
		if (this.endDate) {
			this.$endPicker.datepicker('setDate', this.endDate);
		}
		this._handleSelect();
		return this.getRange();
	};

	/** Set start date. Params: date, Returns: selected start date */
	setStartDate = (date: string) => {
		this.startDate = date;
		this.setRange(this.startDate + ' - ');
		return this.getStartDate();
	};

	/** Set end date. Params: date, Returns: selected end date */
	setEndDate = (date: string) => {
		this.endDate = date;
		this.setRange(' - ' + this.endDate);
		return this.getEndDate();
	};

	/** Reset date. Returns: selected date range */
	resetRange = () => {
		this.startDate = '';
		this.endDate = '';
		this._setMinMaxDate();
		this.$startPicker.datepicker('setDate', null);
		this.$endPicker.datepicker('setDate', null);
		this.$startPicker.find('.ui-state-active').removeClass('ui-state-active');
		this.$endPicker.find('.ui-state-active').removeClass('ui-state-active');
		this.$input.val('');
		this.$input.trigger('change');
		this._enableDone();
		this.props.onReset(this.props.name, this.props.id);
		return this.getRange();
	};

	/** Change current timezone offset. Params: timezone offset, Returns: current timezone offset */
	setTimezoneOffset = (timezoneOffset: string) => {
		if (typeof timezoneOffset === 'string') {
			this.timezoneOffset = timezoneOffset;
			this._refreshComponent();
		}
		return this.timezoneOffset;
	};

	render() {
		let wrapperClasses = classNames('date-range-wrapper', this.props.classes, {
				'inline-block': this.props.hideInput,
				vertical: this.props.vertical
			}),
			inputClasses = classNames(
				'ui input left icon',
				{
					fluid: !this.props.hideInput,
					locked: String(this.props.classes).indexOf('locked') > -1,
					'icon reset-input': this.props.isResettable && !this.props.hideInput,
					'reset-visible':
						!this.props.hideInput && this.props.isResettable && this.props.startDate && this.props.endDate,
					'input-visible': !this.props.hideInput,
					'popup-open': this.props.tooltip
				},
				this.props.classes
			),
			calendarClasses = classNames(
				'date-range',
				{
					vertical: this.props.vertical
				},
				this.props.pickerPosition
			);

		return (
			<div className={wrapperClasses} data-testid={this.props.testID || DateRange.name}>
				<div className={calendarClasses} ref={this.setPickerRef}>
					<div className='ui padded celled grid'>
						<div className='remove-paddings twelve wide mobile twelve wide tablet six wide computer six wide large screen six wide widescreen column'>
							<div className='ui header center aligned'>
								{this.props.intl.formatMessage({
									id: 'component.date-range.startDate'
								})}
							</div>
							<div ref={this.setStartPickerRef} className='start-picker' />
						</div>
						<div className='remove-paddings twelve wide mobile twelve wide tablet six wide computer six wide large screen six wide widescreen column'>
							<div className='ui header center aligned'>
								{this.props.intl.formatMessage({
									id: 'component.date-range.endDate'
								})}
							</div>
							<div ref={this.setEndPickerRef} className='end-picker' />
						</div>
						<div className='twelve wide column left aligned selections'>
							<div className='selected-range'>
								<div className='selected-text'>
									{this.props.intl.formatMessage({
										id: 'component.date-range.selectedRange'
									})}
									:&nbsp;
									<span className='text-bold' ref={this.setStartTextRef} />
									&nbsp;&mdash;&nbsp;
									<span className='text-bold' ref={this.setEndTextRef} />
								</div>
								<Button
									ref={(ref) => (this.$closeButton = ref ? $(ref.ref) : null)}
									classes='close-button primary float-right small remove-right-margin'
									onClick={this.close}
								>
									{this.props.intl.formatMessage({
										id: 'component.date-range.close'
									})}
								</Button>
								<Button
									ref={(ref) => (this.$resetButton = ref ? $(ref.ref) : null)}
									classes='reset-button float-right small'
									onClick={this.resetRange}
								>
									{this.props.intl.formatMessage({
										id: 'component.date-range.reset'
									})}
								</Button>
							</div>
						</div>
					</div>
				</div>
				<div className='date-range-overlay' onClick={this.close} />
				<div className={inputClasses} {...this.props.tooltip} ref={this.setWrapperRef}>
					{this.props.isResettable && !this.props.hideInput ? (
						<Button
							ref={(ref) => (this.$reset = ref ? $(ref.ref) : null)}
							classes='circular icon extramini reset-button'
							onClick={this.resetRange}
						>
							<Icon classes='fa-remove fa-solid' />
						</Button>
					) : null}
					<input
						ref={this.setDisplayRef}
						readOnly
						type={this.props.hideInput ? 'hidden' : 'text'}
						placeholder={this.props.placeholder}
						onClick={this.open}
					/>
					<input ref={this.setInputRef} readOnly type='hidden' id={this.props.id} name={this.props.name} />
					{!this.props.hideInput ? (
						<Icon classes='fa-calendar' />
					) : (
						<Button classes={'icon ' + this.props.buttonClasses} onClick={this.open}>
							<Icon classes='fa-calendar' />
						</Button>
					)}
				</div>
			</div>
		);
	}
}

let Wrapper = injectIntl(DateRange, { forwardRef: true });
Wrapper.displayName = 'DateRange';
export default Wrapper;
