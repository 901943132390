/**
 * @prettier
 * @flow
 */

import { Flag } from 'semantic-ui-react';
import { Popup } from 'liana-ui/components/';

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/flag */
component Component(
	/** A flag must have a two digit country code or the full name.
		DATA[https://react.semantic-ui.com/elements/flag/]
	*/
	name: string,
	/**
		Popup text or, react-intl coomponent or object of properties for Popup component.
		PROPS[React.Node=/language/localisation/, PopupProps=/components/modals/popup/]
	*/
	popup?: React.Node | React.PropsOf<Popup>,
	/** Test ID used for testing. */
	testID: string = 'Flag	'
) {
	let flag = <Flag name={name.toLowerCase()} data-testid={testID} />;
	// $FlowIssue - React statics; Attach popup
	return Popup.attach(popup, flag);
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
