/**
 * @prettier
 * @flow
 */

import { useLocation } from 'react-router-dom';

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/icon */
component Component(
	/** Content as children. */
	children: React.Node,
	/** Mode for content width */
	mode: 'narrow' | 'wide' = 'wide',
	/** Regexp array for full width application pages. */
	widePages: Array<RegExp>,
	/** Test ID used for testing. */
	testID?: string
) {
	let width = 'wide';
	if (mode === 'narrow' && !widePages.some((regex) => regex.test(useLocation().pathname))) {
		width = 'narrow';
	}

	return (
		<section className='main-content' id='main-content' data-testid={testID}>
			<div className={width} id='width-wrapper'>
				{children}
			</div>
		</section>
	);
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
