/**
 * @prettier
 * @flow
 */

import { StepContent } from 'semantic-ui-react';

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/step/#groups-ordered */
component Component(
	/** Detail as children. */
	children: React.Node,
	/** Test ID for testing. */
	testID: string = 'StepContent'
) {
	return <StepContent data-testid={testID}>{children}</StepContent>;
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
