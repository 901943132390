/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { Icon } from 'liana-ui/components';

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/search/ */
component Component(
	/** Main content */
	title: string,
	/** Additional details */
	description?: string,
	/** Result icon */
	icon: string,
	/** Render as link */
	link?: string,
	/** Whether the result should be printed as a showMoreLink */
	showMoreLink?: boolean,
	/** Test ID for testing */
	testID: string = 'SearchResult',
	/** Called on click. */
	onClick?: (event: SyntheticEvent<>, data: any) => void = () => {}
) {
	// Assign classes
	const classes = classNames('content', {
		'show-more': showMoreLink
	});

	return (
		<div
			className={classes}
			onClick={(e) => onClick(e, { title, description, icon, link, showMoreLink, onClick })}
			data-testid={testID}
		>
			{icon && <Icon name={icon} color='black' />}
			{title && <div className='title'>{title}</div>}
			{description && <div className='description'>{description}</div>}
		</div>
	);
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
