/**
 * @prettier
 * @flow
 */

import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import AvatarEditor from 'react-avatar-editor';
import DropZone from 'react-dropzone';
import NumberSlider from 'liana-ui/legacy/components/number-slider/NumberSlider';
import Notification from 'liana-ui/legacy/components/notification/Notification';
import ImageEditorTopActions from './ImageEditorTopActions';
import ImageEditorActions from './ImageEditorActions';

// prettier-ignore
type Props = {
	intl: Intl,
	/** An image editor input must have name. */
	name: string,
	/** An image editor can have a filename of the picture */
	image?: string,
	/** An image editor can have a title text. */
	title?: string,
	/** An image editor can have a description text. */
	description?: string,
	/** An image can have target width. */
	width?: number,
	/** An image can have target height. */
	height?: number,
	/** An image editor can have "border" size. */
	borderSize?: Array<number>,
	/** An image editor can have "border" color. */
	borderColor?: Array<number>,
	/** An image editor can have "border" radieus. */
	borderRadius?: Array<number>,
	/** An image editor can accepted only specified file types */
	acceptFileTypes?: Array<string>,
	/** An image editor can have maximum file size in bytes */
	maxSize?: number,
	/** An image editor can have top actions visible or not. */
	isImageTopActionsVisible?: boolean,
	/** An image editor can have slider visible or not. */
	isImageScaleSliderVisible?: boolean,
	/** The cross-origin property for loading the image itself. Passing an empty string disables the attribute. Defaults to 'use-credentials'. */
	crossOrigin: 'use-credentials' | 'anonymous' | '',
	/** Function called when image is loaded. */
	onLoad?: (
		name: ?mixed
	) => mixed,
	/** Function called when image is removed. */
	onRemove?: () => mixed
};

type ImageSize = {
	width: number,
	height: number
};

/** COMPONENT BASED ON: https://github.com/mosch/react-avatar-editor#readme */
class ImageEditor extends React.PureComponent<Props> {
	_editor: ?AvatarEditor;
	_dropZone: ?DropZone;
	_imageWrapper: ?HTMLDivElement;
	_isImageChanged: boolean;
	_loading: boolean;
	_scale: number;
	_image: string;
	originalImageSize: ?ImageSize;
	orginalImagePreview: mixed;
	wrapperElementWidth: number;
	wrapperElementHeight: number;
	setEditorRef: React.Ref<typeof AvatarEditor>;
	setDropZoneRef: React.Ref<typeof DropZone>;
	setImageWrapperRef: (?React.ElementRef<'div'>) => mixed;

	constructor(props: Props) {
		super(props);
		this._image = '';
		if (props.image) {
			this._image = props.image;
		}
		this.setEditorRef = (ref) => (this._editor = ref);
		this.setDropZoneRef = (ref) => (this._dropZone = ref);
		this.setImageWrapperRef = (ref) => (this._imageWrapper = ref);
		this._isImageChanged = false;
		this._loading = true;
		this._scale = 1;
	}
	static defaultProps = {
		height: 200,
		width: 400,
		borderSize: 10,
		borderColor: [58, 57, 57, 0.8],
		borderRadius: 0,
		maxSize: 2000000, // 2mb
		isImageTopActionsVisible: true,
		isImageScaleSliderVisible: true,
		acceptFileTypes: ['image/jpeg', 'image/png', 'image/gif'],
		crossOrigin: 'use-credentials',
		onLoad: () => {},
		onRemove: () => {}
	};
	componentDidMount() {
		window.addEventListener('resize', this._setWrapperSize);
	}
	componentWillUnmount() {
		// releases an existing object
		window.URL.revokeObjectURL(this._image);
		window.removeEventListener('resize', this._setWrapperSize);
	}
	_setWrapperSize = (reRender = false) => {
		if (this._imageWrapper) {
			let reRenderIsNeeeded = false;
			// Wrapper width && height might be wrong if ImageEditor is opened in modal
			// So rerender is need.
			if (
				this.wrapperElementWidth !== this._imageWrapper.offsetWidth ||
				this.wrapperElementHeight !== this._imageWrapper.offsetHeight
			) {
				reRenderIsNeeeded = true;
			}
			this.wrapperElementWidth = this._imageWrapper.offsetWidth;
			this.wrapperElementHeight = this._imageWrapper.offsetHeight;
			if (reRenderIsNeeeded || reRender) {
				this.forceUpdate();
			}
		}
	};
	_handleDrop = (accepted, rejected, event) => {
		if (rejected.length > 0) {
			const file = rejected[0].file; // Convenience
			if (file.size > this.props.maxSize) {
				this._displayError(
					this.props.intl.formatMessage(
						{
							id: 'component.image-editor.error.invalidFileSize'
						},
						{
							max: this.props.maxSize / 1000
						}
					)
				);
			} else if (this.props.acceptFileTypes.includes(file.type) === false) {
				this._displayError(
					this.props.intl.formatMessage(
						{
							id: 'component.image-editor.error.validFileTypes'
						},
						{
							types: this.props.acceptFileTypes.join(', ')
						}
					)
				);
			}
		} else if (accepted.length > 0) {
			let image = new Image();
			image.src = URL.createObjectURL(accepted[0]);
			image.addEventListener('load', () => {
				this.originalImageSize = {
					width: image.width,
					height: image.height
				};
				this._isImageChanged = true;
				this._image = accepted[0];
				this.forceUpdate(() => {
					// Also fire change event when drag 'n' dropping
					if (event.type === 'drop') {
						let change = new Event('change', { bubbles: true });
						this._imageWrapper.dispatchEvent(change);
					}
				});
			});
		}
	};
	_calculateDropZone() {
		let obj = {};

		obj.height = this.props.height + 'px';

		if (this.wrapperElementHeight && this.wrapperElementHeight > this.props.height) {
			obj.height = this.wrapperElementHeight + 'px';
		}
		if (this.wrapperElementWidth) {
			obj.width = '100%';
		}
		return obj;
	}
	_calculateBorders() {
		let arr = [];
		let horizontalPadding = (this.wrapperElementWidth - this.props.width) / 2;
		let verticalPadding = (this.wrapperElementHeight - this.props.height) / 2;

		if (this._editor && this._editor.canvas) {
			if (this.props.width > this.wrapperElementWidth) {
				horizontalPadding = 0;

				// How many times target width is bigger than wrapper div width
				let differenceBetweenWrapper = this.props.width / this.wrapperElementWidth;

				// Get empty vertical space between wrapper div
				verticalPadding =
					(this.wrapperElementHeight * differenceBetweenWrapper - this.wrapperElementHeight) / 2;
			}
		}
		arr = [horizontalPadding, verticalPadding];
		return arr;
	}
	_changeScale = (unModifiedScale: number) => {
		if (unModifiedScale) {
			let scale = unModifiedScale / 100;
			this._scale = scale;
			this._isImageChanged = true;
			this.forceUpdate();
		}
	};
	_displayError(text) {
		return new Notification({
			text: text,
			icon: 'fa-triangle-exclamation',
			timeout: 7000,
			type: 'error'
		});
	}
	_onImageLoaded = () => {
		this._loading = false;
		this._setWrapperSize(true);
		this.props.onLoad();
	};
	/** Check is the image changed */
	isImageChanged = () => this._isImageChanged;
	/** Get base64 version of the image */
	getOriginalImage = () => {
		let base64 = null;
		if (this._editor && this._image && this._isImageChanged) {
			const canvas = this._editor.getImage();
			base64 = canvas.toDataURL();
		}
		return base64;
	};
	/** Get scaled base64 version of the image */
	getScaledImage = () => {
		let base64 = null;
		if (this._editor && this._image) {
			const canvasScaled = this._editor.getImageScaledToCanvas();
			base64 = canvasScaled.toDataURL();
		}
		return base64;
	};
	/** Get coordinates from a selected area */
	getAreaCoordinates = () => {
		return this._editor && this._image && this._isImageChanged ? this._editor.getCroppingRect() : null;
	};
	/** Open an upload dialog */
	openUploadDialog = () => {
		if (this._dropZone) {
			this._dropZone.open();
		}
	};
	/** Remove a selected image */
	removeSelectedImage = () => {
		if (this._image) {
			window.URL.revokeObjectURL(this._image);
		}
		this._image = '';
		this._loading = true;
		if (typeof this.props.onRemove === 'function') {
			this.props.onRemove();
		}
		this.forceUpdate(() => {
			// Also fire change event when removing image
			let change = new Event('change', { bubbles: true });
			this._imageWrapper.dispatchEvent(change);
		});
	};
	render() {
		let dropZoneSize = this._calculateDropZone();
		let isImageSelected = this._image ? true : false;
		// Currently overflow area of image is hidden so only way to show whole image is to add "borders"
		let borders = this._loading === false ? this._calculateBorders() : null;

		// Assign classes
		const classes = classNames('image-editor-wrapper', {
			'no-scale': !this.props.isImageScaleSliderVisible
		});

		return (
			<div className={classes} data-testid={this.props.testID || ImageEditor.name}>
				{this.props.isImageTopActionsVisible ? (
					<ImageEditorTopActions
						isEnabled={isImageSelected}
						onOpenUpload={this.openUploadDialog}
						onRemoveImage={this.removeSelectedImage}
					/>
				) : null}
				<div
					className={
						isImageSelected
							? 'image-added image-wrapper background-check'
							: 'empty image-wrapper background-check'
					}
					ref={this.setImageWrapperRef}
				>
					<DropZone
						ref={this.setDropZoneRef}
						onDrop={this._handleDrop}
						noClick={isImageSelected}
						multiple={false}
						accept={this.props.acceptFileTypes.join()}
						maxSize={this.props.maxSize}
					>
						{({ getRootProps, getInputProps }) => (
							<div className='image-editor-drop-zone' style={dropZoneSize} {...getRootProps()}>
								{isImageSelected ? (
									<AvatarEditor
										ref={this.setEditorRef}
										className='avatar-editor'
										image={this._image}
										width={this.props.width}
										height={this.props.height}
										crossOrigin={this.props.crossOrigin}
										border={borders}
										color={this.props.borderColor} // RGBA
										borderRadius={this.props.borderRadius}
										scale={this._scale}
										rotate={0}
										style={{ width: '100%', height: 'auto' }}
										onLoadSuccess={this._onImageLoaded}
										onDoubleClick={this.openUploadDialog}
									/>
								) : (
									<ImageEditorActions
										title={this.props.title}
										description={this.props.description}
										height={this.props.height}
										width={this.props.width}
									/>
								)}
								<input
									name={this.props.name}
									type='hidden'
									value={
										this._image
											? typeof this._image === 'object'
												? this._image.name
												: `${this.props.name}-image`
											: ''
									}
								/>
								<input name={`${this.props.name}-file-select`} {...getInputProps()} />
							</div>
						)}
					</DropZone>
				</div>
				{this.props.isImageScaleSliderVisible ? (
					<NumberSlider
						name='scale'
						defaultValue={this._scale}
						max={200}
						min={100}
						unit='%'
						onSlide={this._changeScale}
						isRangeVisible={false}
						isDisabled={isImageSelected ? false : true}
						label='Zoom'
					/>
				) : null}
			</div>
		);
	}
}

let Wrapper = injectIntl(ImageEditor, { forwardRef: true });
Wrapper.displayName = 'ImageEditor';
export default Wrapper;
