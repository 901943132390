/**
 * @prettier
 * @flow
 */

import { FormattedMessage } from 'react-intl';
import { Text, Icon } from 'liana-ui/components';

const Component = React.memo<Props>((props: Props) => {
	return (
		<Text size='small' testID={props.testID || 'FormRequiredFields'}>
			<FormattedMessage id='component.form.requiredFields' />{' '}
			<Text as='sup' size='medium'>
				<Icon name='fa-asterisk' solid color='black' size='tiny' />
			</Text>
		</Text>
	);
});

// Documentation generation support
Component.displayName = 'FormRequiredFields';

export default Component;
