/**
 * @prettier
 * @flow
 */

import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Segment, Image, Grid, Header, Modal, List, Text } from 'liana-ui/components';
import MeContext from 'context/MeContext';
import AppContext from 'context/AppContext';

type Props = {
	appName: string,
	appImage?: string,
	organizations: Array<{ name: string, id: string, image?: string }>,
	setCustomerSelectActive: function,
	getAccessTokenForOrganization: function
};

const Component = (props: Props) => {
	const meCtx = useContext(MeContext);
	const appCtx = useContext(AppContext);
	const organizations = props.organizations;

	const setCustomer = (organizationId) => {
		meCtx.setState((prev) => ({ ...prev, selectedOrganization: organizationId }));
		if (appCtx.config.ENVIRONMENT !== 'local') {
			props.getAccessTokenForOrganization(organizationId);
		}
	};

	const generateOrganizationList = (organizations) => {
		let items = [];
		organizations.map((org) => {
			let orgObj = {
				content: org.name,
				image: {
					src: org.image || '',
					avatar: 'building',
					squared: true
				},
				onClick: () => {
					setCustomer(org.id);
				}
			};
			items.push(orgObj);
		});
		return (
			<Segment raised>
				<List divided relaxed items={items} />
			</Segment>
		);
	};

	return (
		<Modal
			open={true}
			overlay
			closeIcon={false}
			content={
				<Segment basic>
					<Grid>
						<Grid.Row centered>
							<Grid.Column width={12} mobile={12} tablet={8} computer={6} largeScreen={6} wideScreen={6}>
								<Text as='div' textAlign='center'>
									{props.appImage ? (
										<Image squared size='small' alt={props.appName} src={props.appImage} />
									) : (
										<Header size='small' text={props.appName} />
									)}
									<Header size='huge' text={<FormattedMessage id='general.select_organisation' />} />
									<div>
										<FormattedMessage id='general.select_organisation_desc' />
									</div>
								</Text>
								{organizations && organizations.length > 0
									? generateOrganizationList(organizations)
									: null}
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Segment>
			}
		/>
	);
};

Component.displayName = 'OrganizationSelect';

export default Component;
