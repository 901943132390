/**
 * @prettier
 * @flow
 */

import { useNavigate } from 'react-router-dom';
import { ListHeader } from 'semantic-ui-react';
import { Popup } from 'liana-ui/components/';
import { Validate } from 'liana-ui/definitions';

component Component(
	/** Detail as children. */
	children: React.Node,
	/** Item can be a link. Opens absolute links in new browser tab and internal links via router. */
	link: string = '',
	/** Props object for Popup */
	popup?: React.Node | React.PropsOf<Popup>,
	/** List header can be clicked. */
	onClick?: () => void
) {
	const navigate = useNavigate();
	let linkType = Validate.linkType(link); // Get link type

	const handleClick = () => {
		// Trigger onClick callback funtion
		if (typeof onClick === 'function') {
			onClick();
		}
		// Trigger internal link
		if (linkType && (linkType === 'internal' || linkType === 'anchor')) {
			if (linkType === 'internal') {
				navigate(link);
			}
			if (linkType === 'anchor') {
				Safely.scroll(link, () => {
					navigate(`${window.location.pathname}${link}`);
				});
			}
		}
	};

	let header = (
		<ListHeader
			as={link || onClick ? 'a' : null}
			href={link ? link : null}
			target={linkType === 'external' ? '_blank' : null}
			rel={linkType === 'external' ? 'noopener noreferrer' : null}
			onClick={handleClick}
		>
			{children}
		</ListHeader>
	);

	// $FlowIssue - React statics; Attach popup
	return Popup.attach(popup, header);
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
