/**
 * @prettier
 * @flow
 */

import FormActionsPrompt from './FormActionsPrompt';
import type { Translations } from './FormActionsPrompt';
import { Browser } from 'liana-ui/definitions';

// prettier-ignore
type Props = {
	/** Conntent for form placed inside .form-actions element */
	children: React.Node,
	/** Set in modified state as default. Set submit already active if form requires no fields to be modified. */
	defaultModified?: boolean,
	/** Set modified mode. Set submit active when form is changed etc. */
	modified?: boolean,
	/** Set loading mode. Set submit loading when form is submitted etc. */
	loading?: boolean,
	/** Static mode. Butt ons will not follow scroll if modified. */
	static?: boolean,
	/** Translation for safe mode propt (header, description, leaveButton, stayButton) */
	translations?: Translations,
	/** Make primary button blinking if needs more attestion. */
	useBlinking?: boolean,
	/** Use promps to stop user from leaving a page with modified and unsave form */
	usePrompt?: () => void,
	/** Disable automatic form submit on prompt stay button click */
	disablePromptSave?: () => boolean,
	/** Callback for prompt leave button */
	onPromptCancel?: (location: Location) => mixed
};

const SUBMIT_INPUTS = '[type="submit"],.primary'; // Seletor for determining auto-disable inputs
const BOTTOM_OFFSET = 44; // Offset from bottom if Auth bottom bar is visible

/** COMPONENT BASED ON: https://fomantic-ui.com/collections/form.html */
export default class FormActions extends React.PureComponent<Props> {
	$wrapper: JQuery;
	$fixed: JQuery;
	isModified: boolean;
	isLoading: boolean;
	resizeTimeout: ?TimeoutID;
	formActionsPrompt: any; // FormActionsPrompt instance

	constructor(props: Props) {
		super(props);

		this.isModified = false;
		this.isLoading = false;
		this.$wrapper = false;
		this.$fixed = false;
		this.resizeTimeout = null;
		this.formActionsPrompt = null;
	}

	componentDidMount() {
		this.isModified = Boolean(this.props.modified);
		this.isLoading = Boolean(this.props.loading);
		this._handleModified();
		if (!this.props.static) {
			window.addEventListener('scroll', this._handleFixed);
			window.addEventListener('resize', this._handleResizeWindow);
		}
	}

	componentWillUnmount() {
		if (!this.props.static) {
			window.removeEventListener('scroll', this._handleFixed);
			window.removeEventListener('resize', this._handleResizeWindow);
		}
		if (this._isModal()) {
			this.$wrapper.closest('.ui.modals').off('scroll', this._setModalFixed);
		}
	}

	componentDidUpdate() {
		if (typeof this.props.loading === 'boolean' && this.props.loading !== this.isLoading) {
			this.isLoading = this.props.loading;
		}
		if (typeof this.props.modified === 'boolean' && this.props.modified !== this.isModified) {
			this.isModified = this.props.modified;
		}
		this._handleModified();
	}

	_handleModified = () => {
		if (this.isModified) {
			this.$fixed.addClass('modified');
			if (this._isModal()) {
				this._setModalFixed();
				this.$wrapper.closest('.ui.modals').on('scroll', this._setModalFixed);
			}
		} else {
			this.$fixed.removeClass('modified');
		}
		this._handleActions();
		this._handleFixed();
		this._handleAnimation();
	};

	_handleFixed = () => {
		if (this.$fixed.hasClass('modified')) {
			let wrapperBottom = this.$wrapper.offset().top + this.$wrapper.height();
			let windowBottom = $(window).scrollTop() + $(window).height();
			// Adjust for bottom bar
			if (this._hasVisibleBottomBar()) {
				windowBottom = windowBottom - BOTTOM_OFFSET;
			}

			if (wrapperBottom <= windowBottom) {
				this._setUnFixed();
			} else {
				this._setFixed();
			}
		} else {
			this._setUnFixed(150);
		}
	};

	_setFixed = () => {
		this.$wrapper.height(this.$fixed.outerHeight());
		this.$fixed.innerWidth(this.$wrapper.width());
		this.$fixed.addClass('fixed');
	};

	_setUnFixed = (timeout: number = 0) => {
		setTimeout(() => {
			if (!timeout || !this.$fixed.hasClass('modified')) {
				this.$fixed.removeClass('fixed hide show');
				this.$wrapper.height('auto');
				this.$fixed.innerWidth('auto');
			}
		}, timeout);
	};

	_handleAnimation = () => {
		if (this.$fixed.hasClass('modified') && !this.$fixed.hasClass('animated')) {
			this.$fixed.removeClass('hide').addClass('show animated');
		} else if (!this.$fixed.hasClass('modified') && this.$fixed.hasClass('animated')) {
			this.$fixed.removeClass('show animated').addClass('hide');
		}
	};

	_handleActions = () => {
		let $inputs = this.$wrapper.find(SUBMIT_INPUTS);
		if (this.isLoading) {
			this.setLoading();
		} else if ($inputs.hasClass('loading')) {
			this.removeLoading();
		}
		if (!this.props.defaultModified) {
			if (this.isModified === true) {
				$inputs.removeClass('off').addClass(this.props.useBlinking ? 'blinking' : '');
			} else {
				$inputs.addClass('off').removeClass(this.props.useBlinking ? 'blinking' : '');
			}
		}
	};

	_handleResizeWindow = () => {
		this._handleFixed();
		clearTimeout(this.resizeTimeout);
		if (this.$fixed.hasClass('fixed')) {
			this.resizeTimeout = setTimeout(() => this._setFixed(), 100);
		}
	};

	_setModalFixed = () => {
		// Other browsers than IE and Safari.
		if (
			this.$wrapper.length > 0 &&
			this.$wrapper.parents('.ui.dimmer.modals').length > 0 &&
			!Browser.is.IE() &&
			!Browser.is.Safari()
		) {
			let top =
				this.$wrapper.parents('.ui.dimmer.modals').offset().top +
				this.$wrapper.parents('.ui.dimmer.modals').scrollTop() +
				this.$wrapper.parents('.ui.dimmer.modals').height() -
				($(window).scrollTop() + this.$wrapper.outerHeight()) +
				2;

			// Adjust for bottom bar
			if (this._hasVisibleBottomBar()) {
				top = top - BOTTOM_OFFSET;
			}

			this.$fixed.css({ top: top, bottom: 'auto' });
		}
		this._handleFixed();
	};

	_isModal = () => {
		return this.$wrapper.closest('.ui.modals').length > 0 ? true : false;
	};

	_hasVisibleBottomBar = () => {
		return $('body[data-bar="true"][data-bar-open="true"]').length > 0 ? true : false;
	};

	/**
	 * This needs to be called when an user change a page or a tab.
	 * If a form is modified then show a modal and save nextLocation into variable
	 */
	routerWillLeave = (nextLocation: Location) => {
		if (this.props.usePrompt && this.isModified) {
			this.formActionsPrompt.show();
			return false;
		}
		return true;
	};

	// Click 'Save' in prompt
	_handlePromptSave = () => {
		if (!this.props.disablePromptSave && this.$wrapper.find('button[type=submit]').length > 0) {
			this.$wrapper.find('button[type=submit]').click();
		}
	};

	_handlePromptCancel = () => {
		if (typeof this.props.onPromptCancel === 'function') {
			this.props.onPromptCancel();
		}
	};

	// Set form actions as modified.
	setModified = (modified: boolean) => {
		this.isModified = Boolean(modified);
		if (!this.isModified) {
			this.isLoading = false;
		}
		this._handleModified();
	};

	// Set form actions as loding. Loader in buttons.
	setLoading = () => {
		this.isLoading = true;
		let $inputs = this.$fixed.find(SUBMIT_INPUTS);
		$inputs.prop('disabled', true);
		$inputs.addClass('loading off').removeClass(this.props.useBlinking ? 'blinking' : '');
	};

	// Remove loading from form actions. Loader in buttons.
	removeLoading = () => {
		this.isLoading = false;
		let $inputs = this.$fixed.find(SUBMIT_INPUTS);
		$inputs.prop('disabled', false);
		$inputs.removeClass('loading off');
	};

	render() {
		return (
			<div
				className='form-actions-wrapper'
				ref={(ref) => (this.$wrapper = $(ref))}
				data-testid={this.props.testID || FormActions.name}
			>
				<div className='actions form-actions' ref={(ref) => (this.$fixed = $(ref))}>
					{this.props.children}
				</div>
				{this.props.usePrompt ? (
					<FormActionsPrompt
						ref={(ref) => (this.formActionsPrompt = Safely.ref(ref))}
						translations={this.props.translations}
						onSave={this._handlePromptSave}
						onLeave={this._handlePromptCancel}
					/>
				) : null}
			</div>
		);
	}
}
